:root {
    --blue: #0061f2;
    --indigo: #5800e8;
    --purple: #6900c7;
    --pink: #e30059;
    --red: #e81500;
    --orange: #f76400;
    --yellow: #f4a100;
    --green: #00ac69;
    --teal: #00ba94;
    --cyan: #00cfd5;
    --white: #fff;
    --gray: #687281;
    --gray-dark: #323f52;
    --primary: #17449e;
    --secondary: #5e5e5e;
    --success: #00ac69;
    --info: #00cfd5;
    --warning: #f4a100;
    --danger: #e81500;
    --light: #eff3f9;
    --dark: #1f2d41;
    --black: #000;
    --white: #fff;
    --red: #e81500;
    --orange: #f76400;
    --yellow: #f4a100;
    --green: #00ac69;
    --teal: #00ba94;
    --cyan: #00cfd5;
    --blue: #0061f2;
    --indigo: #5800e8;
    --purple: #6900c7;
    --pink: #e30059;
    --red-soft: #eec7c7;
    --orange-soft: #f1d6c7;
    --yellow-soft: #f0e3c7;
    --green-soft: #bfe5dc;
    --teal-soft: #bfe8e5;
    --cyan-soft: #bfecf2;
    --blue-soft: #4d93e9;
    --indigo-soft: #d1c2f6;
    --purple-soft: #d4c2ef;
    --pink-soft: #edc2d9;
    --primary-soft: #4d93e9;
    --secondary-soft: #d4c2ef;
    --success-soft: #bfe5dc;
    --info-soft: #bfecf2;
    --warning-soft: #f0e3c7;
    --danger-soft: #eec7c7;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Poppins", sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #687281;
    text-align: left;
    background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.6;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 600;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

.sidenav-light .sidenav-menu .nav-link:hover {
    color: #17449e;
}

.sidenav-light .sidenav-menu .nav-link.active {
    color: #17449e;
}

.sidenav-light .sidenav-menu .nav-link.active .nav-link-icon {
    color: #17449e;
}


a {
    color: #17449e;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #141e2c;
    text-decoration: underline;
}

a:not([href]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #a2acba;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #323f52;
}

h1,
.h1 {
    font-size: 1.5rem;
}

h2,
.h2 {
    font-size: 1.4rem;
}

h3,
.h3 {
    font-size: 1.3rem;
}

h4,
.h4 {
    font-size: 1.2rem;
}

h5,
.h5 {
    font-size: 1.1rem;
}

h6,
.h6 {
    font-size: 1rem;
}

.lead {
    font-size: 1.1rem;
    font-weight: 400;
}

.display-1 {
    font-size: 5.5rem;
    font-weight: 400;
    line-height: 1.6;
}

.display-2 {
    font-size: 4.5rem;
    font-weight: 400;
    line-height: 1.6;
}

.display-3 {
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 1.6;
}

.display-4 {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.6;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 0.875em;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote-footer {
    display: block;
    font-size: 0.875em;
    color: #687281;
}

.blockquote-footer::before {
    content: "\2014\00A0";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #d7dce3;
    border-radius: 3px;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #687281;
}

code {
    font-size: 87.5%;
    color: #e30059;
    word-wrap: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #1f2d41;
    border-radius: 0.25rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #687281;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {

    .container-sm,
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {

    .container-md,
    .container-sm,
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {

    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1140px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
    position: relative;
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.row-cols-1>* {
    flex: 0 0 100%;
    max-width: 100%;
}

.row-cols-2>* {
    flex: 0 0 50%;
    max-width: 50%;
}

.row-cols-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
}

.row-cols-4>* {
    flex: 0 0 25%;
    max-width: 25%;
}

.row-cols-5>* {
    flex: 0 0 20%;
    max-width: 20%;
}

.row-cols-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
}

.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
}

.col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
}

.col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
}

.col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.3333333333%;
}

.offset-2 {
    margin-left: 16.6666666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.3333333333%;
}

.offset-5 {
    margin-left: 41.6666666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.3333333333%;
}

.offset-8 {
    margin-left: 66.6666666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.3333333333%;
}

.offset-11 {
    margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-sm-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-sm-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-sm-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-sm-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-sm-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-sm-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-sm-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-sm-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-sm-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-sm-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    .order-sm-6 {
        order: 6;
    }

    .order-sm-7 {
        order: 7;
    }

    .order-sm-8 {
        order: 8;
    }

    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.3333333333%;
    }

    .offset-sm-2 {
        margin-left: 16.6666666667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.3333333333%;
    }

    .offset-sm-5 {
        margin-left: 41.6666666667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.3333333333%;
    }

    .offset-sm-8 {
        margin-left: 66.6666666667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.3333333333%;
    }

    .offset-sm-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-md-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-md-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-md-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-md-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-md-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.3333333333%;
    }

    .offset-md-2 {
        margin-left: 16.6666666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.3333333333%;
    }

    .offset-md-5 {
        margin-left: 41.6666666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.3333333333%;
    }

    .offset-md-8 {
        margin-left: 66.6666666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.3333333333%;
    }

    .offset-md-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-lg-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-lg-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-lg-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-lg-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-lg-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-lg-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-lg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-lg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-lg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    .order-lg-6 {
        order: 6;
    }

    .order-lg-7 {
        order: 7;
    }

    .order-lg-8 {
        order: 8;
    }

    .order-lg-9 {
        order: 9;
    }

    .order-lg-10 {
        order: 10;
    }

    .order-lg-11 {
        order: 11;
    }

    .order-lg-12 {
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.3333333333%;
    }

    .offset-lg-2 {
        margin-left: 16.6666666667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.3333333333%;
    }

    .offset-lg-5 {
        margin-left: 41.6666666667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.3333333333%;
    }

    .offset-lg-8 {
        margin-left: 66.6666666667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.3333333333%;
    }

    .offset-lg-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-xl-1>* {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xl-2>* {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xl-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-xl-4>* {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xl-5>* {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xl-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-xl-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-xl-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-xl-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-xl-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }

    .order-xl-3 {
        order: 3;
    }

    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-xl-12 {
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.3333333333%;
    }

    .offset-xl-2 {
        margin-left: 16.6666666667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.3333333333%;
    }

    .offset-xl-5 {
        margin-left: 41.6666666667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.3333333333%;
    }

    .offset-xl-8 {
        margin-left: 66.6666666667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.3333333333%;
    }

    .offset-xl-11 {
        margin-left: 91.6666666667%;
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #687281;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e3e6ec;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e3e6ec;
}

.table tbody+tbody {
    border-top: 2px solid #e3e6ec;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #e3e6ec;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #e3e6ec;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    color: #687281;
    background-color: rgba(227, 230, 236, 0.25);
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #c4cad2;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #919dac;
}

.table-hover .table-primary:hover {
    background-color: #b6bdc7;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #b6bdc7;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #c4cad3;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #919dad;
}

.table-hover .table-secondary:hover {
    background-color: #b5bdc8;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #b5bdc8;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #c4cad3;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #919dad;
}

.table-hover .table-success:hover {
    background-color: #b5bdc8;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b5bdc8;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #c4cad4;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #919daf;
}

.table-hover .table-info:hover {
    background-color: #b5bdc9;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #b5bdc9;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #c4cad4;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #919dae;
}

.table-hover .table-warning:hover {
    background-color: #b5bdc9;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #b5bdc9;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #c4cad3;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #919dae;
}

.table-hover .table-danger:hover {
    background-color: #b5bdc8;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #b5bdc8;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fbfcfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #f7f9fc;
}

.table-hover .table-light:hover {
    background-color: #eaeff5;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #eaeff5;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #c0c4ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #8b929c;
}

.table-hover .table-dark:hover {
    background-color: #b2b7be;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b2b7be;
}

.table-black,
.table-black>th,
.table-black>td {
    background-color: #b8b8b8;
}

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody+tbody {
    border-color: #7a7a7a;
}

.table-hover .table-black:hover {
    background-color: #ababab;
}

.table-hover .table-black:hover>td,
.table-hover .table-black:hover>th {
    background-color: #ababab;
}

.table-white,
.table-white>th,
.table-white>td {
    background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody+tbody {
    border-color: white;
}

.table-hover .table-white:hover {
    background-color: #f2f2f2;
}

.table-hover .table-white:hover>td,
.table-hover .table-white:hover>th {
    background-color: #f2f2f2;
}

.table-red,
.table-red>th,
.table-red>td {
    background-color: #f9bdb8;
}

.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody+tbody {
    border-color: #f3857a;
}

.table-hover .table-red:hover {
    background-color: #f7a7a0;
}

.table-hover .table-red:hover>td,
.table-hover .table-red:hover>th {
    background-color: #f7a7a0;
}

.table-orange,
.table-orange>th,
.table-orange>td {
    background-color: #fdd4b8;
}

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody+tbody {
    border-color: #fbae7a;
}

.table-hover .table-orange:hover {
    background-color: #fcc59f;
}

.table-hover .table-orange:hover>td,
.table-hover .table-orange:hover>th {
    background-color: #fcc59f;
}

.table-yellow,
.table-yellow>th,
.table-yellow>td {
    background-color: #fce5b8;
}

.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody+tbody {
    border-color: #f9ce7a;
}

.table-hover .table-yellow:hover {
    background-color: #fbdca0;
}

.table-hover .table-yellow:hover>td,
.table-hover .table-yellow:hover>th {
    background-color: #fbdca0;
}

.table-green,
.table-green>th,
.table-green>td {
    background-color: #b8e8d5;
}

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody+tbody {
    border-color: #7ad4b1;
}

.table-hover .table-green:hover {
    background-color: #a5e2ca;
}

.table-hover .table-green:hover>td,
.table-hover .table-green:hover>th {
    background-color: #a5e2ca;
}

.table-teal,
.table-teal>th,
.table-teal>td {
    background-color: #b8ece1;
}

.table-teal th,
.table-teal td,
.table-teal thead th,
.table-teal tbody+tbody {
    border-color: #7adbc7;
}

.table-hover .table-teal:hover {
    background-color: #a4e7d9;
}

.table-hover .table-teal:hover>td,
.table-hover .table-teal:hover>th {
    background-color: #a4e7d9;
}

.table-cyan,
.table-cyan>th,
.table-cyan>td {
    background-color: #b8f2f3;
}

.table-cyan th,
.table-cyan td,
.table-cyan thead th,
.table-cyan tbody+tbody {
    border-color: #7ae6e9;
}

.table-hover .table-cyan:hover {
    background-color: #a2eeef;
}

.table-hover .table-cyan:hover>td,
.table-hover .table-cyan:hover>th {
    background-color: #a2eeef;
}

.table-blue,
.table-blue>th,
.table-blue>td {
    background-color: #b8d3fb;
}

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody+tbody {
    border-color: #7aadf8;
}

.table-hover .table-blue:hover {
    background-color: #a0c4fa;
}

.table-hover .table-blue:hover>td,
.table-hover .table-blue:hover>th {
    background-color: #a0c4fa;
}

.table-indigo,
.table-indigo>th,
.table-indigo>td {
    background-color: #d0b8f9;
}

.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody+tbody {
    border-color: #a87af3;
}

.table-hover .table-indigo:hover {
    background-color: #c0a0f7;
}

.table-hover .table-indigo:hover>td,
.table-hover .table-indigo:hover>th {
    background-color: #c0a0f7;
}

.table-purple,
.table-purple>th,
.table-purple>td {
    background-color: #d5b8ef;
}

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody+tbody {
    border-color: #b17ae2;
}

.table-hover .table-purple:hover {
    background-color: #c9a3ea;
}

.table-hover .table-purple:hover>td,
.table-hover .table-purple:hover>th {
    background-color: #c9a3ea;
}

.table-pink,
.table-pink>th,
.table-pink>td {
    background-color: #f7b8d1;
}

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody+tbody {
    border-color: #f07aa9;
}

.table-hover .table-pink:hover {
    background-color: #f4a1c2;
}

.table-hover .table-pink:hover>td,
.table-hover .table-pink:hover>th {
    background-color: #f4a1c2;
}

.table-red-soft,
.table-red-soft>th,
.table-red-soft>td {
    background-color: #faefef;
}

.table-red-soft th,
.table-red-soft td,
.table-red-soft thead th,
.table-red-soft tbody+tbody {
    border-color: #f6e2e2;
}

.table-hover .table-red-soft:hover {
    background-color: #f4dcdc;
}

.table-hover .table-red-soft:hover>td,
.table-hover .table-red-soft:hover>th {
    background-color: #f4dcdc;
}

.table-orange-soft,
.table-orange-soft>th,
.table-orange-soft>td {
    background-color: #fbf4ef;
}

.table-orange-soft th,
.table-orange-soft td,
.table-orange-soft thead th,
.table-orange-soft tbody+tbody {
    border-color: #f8eae2;
}

.table-hover .table-orange-soft:hover {
    background-color: #f6e6db;
}

.table-hover .table-orange-soft:hover>td,
.table-hover .table-orange-soft:hover>th {
    background-color: #f6e6db;
}

.table-yellow-soft,
.table-yellow-soft>th,
.table-yellow-soft>td {
    background-color: #fbf7ef;
}

.table-yellow-soft th,
.table-yellow-soft td,
.table-yellow-soft thead th,
.table-yellow-soft tbody+tbody {
    border-color: #f7f0e2;
}

.table-hover .table-yellow-soft:hover {
    background-color: #f6eddb;
}

.table-hover .table-yellow-soft:hover>td,
.table-hover .table-yellow-soft:hover>th {
    background-color: #f6eddb;
}

.table-green-soft,
.table-green-soft>th,
.table-green-soft>td {
    background-color: #edf8f5;
}

.table-green-soft th,
.table-green-soft td,
.table-green-soft thead th,
.table-green-soft tbody+tbody {
    border-color: #def1ed;
}

.table-hover .table-green-soft:hover {
    background-color: #dbf1eb;
}

.table-hover .table-green-soft:hover>td,
.table-hover .table-green-soft:hover>th {
    background-color: #dbf1eb;
}

.table-teal-soft,
.table-teal-soft>th,
.table-teal-soft>td {
    background-color: #edf9f8;
}

.table-teal-soft th,
.table-teal-soft td,
.table-teal-soft thead th,
.table-teal-soft tbody+tbody {
    border-color: #def3f1;
}

.table-hover .table-teal-soft:hover {
    background-color: #daf3f1;
}

.table-hover .table-teal-soft:hover>td,
.table-hover .table-teal-soft:hover>th {
    background-color: #daf3f1;
}

.table-cyan-soft,
.table-cyan-soft>th,
.table-cyan-soft>td {
    background-color: #edfafb;
}

.table-cyan-soft th,
.table-cyan-soft td,
.table-cyan-soft thead th,
.table-cyan-soft tbody+tbody {
    border-color: #def5f8;
}

.table-hover .table-cyan-soft:hover {
    background-color: #d8f4f6;
}

.table-hover .table-cyan-soft:hover>td,
.table-hover .table-cyan-soft:hover>th {
    background-color: #d8f4f6;
}

.table-blue-soft,
.table-blue-soft>th,
.table-blue-soft>td {
    background-color: #edf4fd;
}

.table-blue-soft th,
.table-blue-soft td,
.table-blue-soft thead th,
.table-blue-soft tbody+tbody {
    border-color: #deeafb;
}

.table-hover .table-blue-soft:hover {
    background-color: #d6e6fa;
}

.table-hover .table-blue-soft:hover>td,
.table-hover .table-blue-soft:hover>th {
    background-color: #d6e6fa;
}

.table-indigo-soft,
.table-indigo-soft>th,
.table-indigo-soft>td {
    background-color: #f2eefc;
}

.table-indigo-soft th,
.table-indigo-soft td,
.table-indigo-soft thead th,
.table-indigo-soft tbody+tbody {
    border-color: #e7dffa;
}

.table-hover .table-indigo-soft:hover {
    background-color: #e1d8f8;
}

.table-hover .table-indigo-soft:hover>td,
.table-hover .table-indigo-soft:hover>th {
    background-color: #e1d8f8;
}

.table-purple-soft,
.table-purple-soft>th,
.table-purple-soft>td {
    background-color: #f3eefb;
}

.table-purple-soft th,
.table-purple-soft td,
.table-purple-soft thead th,
.table-purple-soft tbody+tbody {
    border-color: #e9dff7;
}

.table-hover .table-purple-soft:hover {
    background-color: #e4d9f6;
}

.table-hover .table-purple-soft:hover>td,
.table-hover .table-purple-soft:hover>th {
    background-color: #e4d9f6;
}

.table-pink-soft,
.table-pink-soft>th,
.table-pink-soft>td {
    background-color: #faeef4;
}

.table-pink-soft th,
.table-pink-soft td,
.table-pink-soft thead th,
.table-pink-soft tbody+tbody {
    border-color: #f6dfeb;
}

.table-hover .table-pink-soft:hover {
    background-color: #f4dae7;
}

.table-hover .table-pink-soft:hover>td,
.table-hover .table-pink-soft:hover>th {
    background-color: #f4dae7;
}

.table-primary-soft,
.table-primary-soft>th,
.table-primary-soft>td {
    background-color: #edf4fd;
}

.table-primary-soft th,
.table-primary-soft td,
.table-primary-soft thead th,
.table-primary-soft tbody+tbody {
    border-color: #deeafb;
}

.table-hover .table-primary-soft:hover {
    background-color: #d6e6fa;
}

.table-hover .table-primary-soft:hover>td,
.table-hover .table-primary-soft:hover>th {
    background-color: #d6e6fa;
}

.table-secondary-soft,
.table-secondary-soft>th,
.table-secondary-soft>td {
    background-color: #f3eefb;
}

.table-secondary-soft th,
.table-secondary-soft td,
.table-secondary-soft thead th,
.table-secondary-soft tbody+tbody {
    border-color: #e9dff7;
}

.table-hover .table-secondary-soft:hover {
    background-color: #e4d9f6;
}

.table-hover .table-secondary-soft:hover>td,
.table-hover .table-secondary-soft:hover>th {
    background-color: #e4d9f6;
}

.table-success-soft,
.table-success-soft>th,
.table-success-soft>td {
    background-color: #edf8f5;
}

.table-success-soft th,
.table-success-soft td,
.table-success-soft thead th,
.table-success-soft tbody+tbody {
    border-color: #def1ed;
}

.table-hover .table-success-soft:hover {
    background-color: #dbf1eb;
}

.table-hover .table-success-soft:hover>td,
.table-hover .table-success-soft:hover>th {
    background-color: #dbf1eb;
}

.table-info-soft,
.table-info-soft>th,
.table-info-soft>td {
    background-color: #edfafb;
}

.table-info-soft th,
.table-info-soft td,
.table-info-soft thead th,
.table-info-soft tbody+tbody {
    border-color: #def5f8;
}

.table-hover .table-info-soft:hover {
    background-color: #d8f4f6;
}

.table-hover .table-info-soft:hover>td,
.table-hover .table-info-soft:hover>th {
    background-color: #d8f4f6;
}

.table-warning-soft,
.table-warning-soft>th,
.table-warning-soft>td {
    background-color: #fbf7ef;
}

.table-warning-soft th,
.table-warning-soft td,
.table-warning-soft thead th,
.table-warning-soft tbody+tbody {
    border-color: #f7f0e2;
}

.table-hover .table-warning-soft:hover {
    background-color: #f6eddb;
}

.table-hover .table-warning-soft:hover>td,
.table-hover .table-warning-soft:hover>th {
    background-color: #f6eddb;
}

.table-danger-soft,
.table-danger-soft>th,
.table-danger-soft>td {
    background-color: #faefef;
}

.table-danger-soft th,
.table-danger-soft td,
.table-danger-soft thead th,
.table-danger-soft tbody+tbody {
    border-color: #f6e2e2;
}

.table-hover .table-danger-soft:hover {
    background-color: #f4dcdc;
}

.table-hover .table-danger-soft:hover>td,
.table-hover .table-danger-soft:hover>th {
    background-color: #f4dcdc;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(227, 230, 236, 0.25);
}

.table-hover .table-active:hover {
    background-color: rgba(212, 216, 226, 0.25);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(212, 216, 226, 0.25);
}

.table .thead-dark th {
    color: rgba(255, 255, 255, 0.65);
    background-color: #323f52;
    border-color: #40516a;
}

.table .thead-light th {
    color: #485260;
    background-color: #e3e6ec;
    border-color: #e3e6ec;
}

.table-dark {
    color: rgba(255, 255, 255, 0.65);
    background-color: #323f52;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #40516a;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: rgba(255, 255, 255, 0.65);
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #687281;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e3e6ec;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #687281;
}

.form-control:focus {
    color: #687281;
    background-color: #fff;
    border-color: #17449e;
    outline: 0;
    box-shadow: 0 0 0 0.0625rem #17449e;
}

.form-control::placeholder {
    color: #687281;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e3e6ec;
    opacity: 1;
}

select.form-control:focus::-ms-value {
    color: #687281;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #687281;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.5rem;
}

select.form-control[size],
select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    color: #a2acba;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #00ac69;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: #00ac69;
    ;
    border-radius: 3px;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #00ac69;
    padding-right: calc(1.5em + 1rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232c4262' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.25rem) center;
    background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #00ac69;
    box-shadow: 0 0 0 0.0625rem rgba(44, 66, 98, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 1rem);
    background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #00ac69;
    padding-right: calc(0.75em + 2.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23323f52' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232c4262' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #00ac69;
    box-shadow: 0 0 0 0.0625rem rgba(44, 66, 98, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #00ac69;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
    color: #00ac69;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
    border-color: #00ac69;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
    border-color: #707172;
    background-color: #707172;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.0625rem rgba(44, 66, 98, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #00ac69;
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
    border-color: #00ac69;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
    border-color: #00ac69;
    box-shadow: 0 0 0 0.0625rem rgba(44, 66, 98, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #e81500;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(44, 66, 99, 0.9);
    border-radius: 3px;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #e81500;
    padding-right: calc(1.5em + 1rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%232c4263' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%232c4263' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.25rem) center;
    background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #e81500;
    box-shadow: 0 0 0 0.0625rem rgba(44, 66, 99, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 1rem);
    background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #e81500;
    padding-right: calc(0.75em + 2.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23323f52' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%232c4263' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%232c4263' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #e81500;
    box-shadow: 0 0 0 0.0625rem rgba(44, 66, 99, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #e81500;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
    color: #e81500;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
    border-color: #e81500;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
    border-color: #707172;
    background-color: #707172;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.0625rem rgba(44, 66, 99, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #e81500;
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
    border-color: #e81500;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
    border-color: #e81500;
    box-shadow: 0 0 0 0.0625rem rgba(44, 66, 99, 0.25);
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }

    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #687281;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 25px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #687281;
    text-decoration: none;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 96, 0.25);
}

.btn.disabled,
.btn:disabled {
    opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #17449e;
    border-color: #17449e;
}

.btn-primary:hover {
    color: #fff;
    background-color: #565656;
    border-color: #3b3b3b;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #565656;
    border-color: #3b3b3b;
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 120, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #17449e;
    border-color: #17449e;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f3f3f;
    border-color: #3f3f3f;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 120, 0.5);
}

.btn-secondary {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #5e5e5e;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #565656;
    border-color: #3b3b3b;
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: #fff;
    background-color: #203047;
    border-color: #1c2a3e;
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 121, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #5e5e5e;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1c2a3e;
    border-color: #182435;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 121, 0.5);
}

.btn-success {
    color: #fff;
    background-color: #00ac69;
    border-color: #00ac69;
}

.btn-success:hover {
    color: #fff;
    background-color: #203048;
    border-color: #1c2a3f;
}

.btn-success:focus,
.btn-success.focus {
    color: #fff;
    background-color: #203048;
    border-color: #1c2a3f;
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 122, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #00ac69;
    border-color: #00ac69;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1c2a3f;
    border-color: #182436;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 122, 0.5);
}

.btn-info {
    color: #fff;
    background-color: #00cfd5;
    border-color: #00cfd5;
}

.btn-info:hover {
    color: #fff;
    background-color: #20314a;
    border-color: #1d2b41;
}

.btn-info:focus,
.btn-info.focus {
    color: #fff;
    background-color: #20314a;
    border-color: #1d2b41;
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 124, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #00cfd5;
    border-color: #00cfd5;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1d2b41;
    border-color: #192539;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 124, 0.5);
}

.btn-warning {
    color: #fff;
    background-color: #f4a100;
    border-color: #f4a100;
}

.btn-warning:hover {
    color: #fff;
    background-color: #203049;
    border-color: #1c2b41;
}

.btn-warning:focus,
.btn-warning.focus {
    color: #fff;
    background-color: #203049;
    border-color: #1c2b41;
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 123, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #fff;
    background-color: #f4a100;
    border-color: #f4a100;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #1c2b41;
    border-color: #192538;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 123, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #e81500;
    border-color: #e81500;
}

.btn-danger:hover {
    color: #fff;
    background-color: #203049;
    border-color: #1c2a40;
}

.btn-danger:focus,
.btn-danger.focus {
    color: #fff;
    background-color: #203049;
    border-color: #1c2a40;
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 122, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #e81500;
    border-color: #e81500;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #1c2a40;
    border-color: #182537;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 94, 122, 0.5);
}

.btn-light {
    color: #1f2d41;
    background-color: #eff3f9;
    border-color: #eff3f9;
}

.btn-light:hover {
    color: #1f2d41;
    background-color: #d3deef;
    border-color: #cad7eb;
}

.btn-light:focus,
.btn-light.focus {
    color: #1f2d41;
    background-color: #d3deef;
    border-color: #cad7eb;
    box-shadow: 0 0 0 0.2rem rgba(208, 213, 221, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #1f2d41;
    background-color: #eff3f9;
    border-color: #eff3f9;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #1f2d41;
    background-color: #cad7eb;
    border-color: #c1d0e8;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(208, 213, 221, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #1f2d41;
    border-color: #1f2d41;
}

.btn-dark:hover {
    color: #fff;
    background-color: #131b27;
    border-color: #0f151e;
}

.btn-dark:focus,
.btn-dark.focus {
    color: #fff;
    background-color: #131b27;
    border-color: #0f151e;
    box-shadow: 0 0 0 0.2rem rgba(65, 77, 94, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #1f2d41;
    border-color: #1f2d41;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0f151e;
    border-color: #0a0f16;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 77, 94, 0.5);
}

.btn-black {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

.btn-black:hover {
    color: #fff;
    background-color: black;
    border-color: black;
}

.btn-black:focus,
.btn-black.focus {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-black.disabled,
.btn-black:disabled {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.show>.btn-black.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black;
}

.btn-black:not(:disabled):not(.disabled):active:focus,
.btn-black:not(:disabled):not(.disabled).active:focus,
.show>.btn-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-white {
    color: #1f2d41;
    background-color: #fff;
    border-color: #fff;
}

.btn-white:hover {
    color: #1f2d41;
    background-color: #ececec;
    border-color: #e6e6e6;
}

.btn-white:focus,
.btn-white.focus {
    color: #1f2d41;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(221, 224, 227, 0.5);
}

.btn-white.disabled,
.btn-white:disabled {
    color: #1f2d41;
    background-color: #fff;
    border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show>.btn-white.dropdown-toggle {
    color: #1f2d41;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 224, 227, 0.5);
}

.btn-red {
    color: #fff;
    background-color: #e81500;
    border-color: #e81500;
}

.btn-red:hover {
    color: #fff;
    background-color: #c21200;
    border-color: #b51000;
}

.btn-red:focus,
.btn-red.focus {
    color: #fff;
    background-color: #c21200;
    border-color: #b51000;
    box-shadow: 0 0 0 0.2rem rgba(235, 56, 38, 0.5);
}

.btn-red.disabled,
.btn-red:disabled {
    color: #fff;
    background-color: #e81500;
    border-color: #e81500;
}

.btn-red:not(:disabled):not(.disabled):active,
.btn-red:not(:disabled):not(.disabled).active,
.show>.btn-red.dropdown-toggle {
    color: #fff;
    background-color: #b51000;
    border-color: #a80f00;
}

.btn-red:not(:disabled):not(.disabled):active:focus,
.btn-red:not(:disabled):not(.disabled).active:focus,
.show>.btn-red.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 56, 38, 0.5);
}

.btn-orange {
    color: #fff;
    background-color: #f76400;
    border-color: #f76400;
}

.btn-orange:hover {
    color: #fff;
    background-color: #d15500;
    border-color: #c44f00;
}

.btn-orange:focus,
.btn-orange.focus {
    color: #fff;
    background-color: #d15500;
    border-color: #c44f00;
    box-shadow: 0 0 0 0.2rem rgba(248, 123, 38, 0.5);
}

.btn-orange.disabled,
.btn-orange:disabled {
    color: #fff;
    background-color: #f76400;
    border-color: #f76400;
}

.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show>.btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #c44f00;
    border-color: #b74a00;
}

.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show>.btn-orange.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 123, 38, 0.5);
}

.btn-yellow {
    color: #fff;
    background-color: #f4a100;
    border-color: #f4a100;
}

.btn-yellow:hover {
    color: #fff;
    background-color: #ce8800;
    border-color: #c17f00;
}

.btn-yellow:focus,
.btn-yellow.focus {
    color: #fff;
    background-color: #ce8800;
    border-color: #c17f00;
    box-shadow: 0 0 0 0.2rem rgba(246, 175, 38, 0.5);
}

.btn-yellow.disabled,
.btn-yellow:disabled {
    color: #fff;
    background-color: #f4a100;
    border-color: #f4a100;
}

.btn-yellow:not(:disabled):not(.disabled):active,
.btn-yellow:not(:disabled):not(.disabled).active,
.show>.btn-yellow.dropdown-toggle {
    color: #fff;
    background-color: #c17f00;
    border-color: #b47700;
}

.btn-yellow:not(:disabled):not(.disabled):active:focus,
.btn-yellow:not(:disabled):not(.disabled).active:focus,
.show>.btn-yellow.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 175, 38, 0.5);
}

.btn-green {
    color: #fff;
    background-color: #00ac69;
    border-color: #00ac69;
}

.btn-green:hover {
    color: #fff;
    background-color: #008652;
    border-color: #00794a;
}

.btn-green:focus,
.btn-green.focus {
    color: #fff;
    background-color: #008652;
    border-color: #00794a;
    box-shadow: 0 0 0 0.2rem rgba(38, 184, 128, 0.5);
}

.btn-green.disabled,
.btn-green:disabled {
    color: #fff;
    background-color: #00ac69;
    border-color: #00ac69;
}

.btn-green:not(:disabled):not(.disabled):active,
.btn-green:not(:disabled):not(.disabled).active,
.show>.btn-green.dropdown-toggle {
    color: #fff;
    background-color: #00794a;
    border-color: #006c42;
}

.btn-green:not(:disabled):not(.disabled):active:focus,
.btn-green:not(:disabled):not(.disabled).active:focus,
.show>.btn-green.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 184, 128, 0.5);
}

.btn-teal {
    color: #fff;
    background-color: #00ba94;
    border-color: #00ba94;
}

.btn-teal:hover {
    color: #fff;
    background-color: #009476;
    border-color: #00876b;
}

.btn-teal:focus,
.btn-teal.focus {
    color: #fff;
    background-color: #009476;
    border-color: #00876b;
    box-shadow: 0 0 0 0.2rem rgba(38, 196, 164, 0.5);
}

.btn-teal.disabled,
.btn-teal:disabled {
    color: #fff;
    background-color: #00ba94;
    border-color: #00ba94;
}

.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active,
.show>.btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #00876b;
    border-color: #007a61;
}

.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus,
.show>.btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 196, 164, 0.5);
}

.btn-cyan {
    color: #fff;
    background-color: #00cfd5;
    border-color: #00cfd5;
}

.btn-cyan:hover {
    color: #fff;
    background-color: #00aaaf;
    border-color: #009da2;
}

.btn-cyan:focus,
.btn-cyan.focus {
    color: #fff;
    background-color: #00aaaf;
    border-color: #009da2;
    box-shadow: 0 0 0 0.2rem rgba(38, 214, 219, 0.5);
}

.btn-cyan.disabled,
.btn-cyan:disabled {
    color: #fff;
    background-color: #00cfd5;
    border-color: #00cfd5;
}

.btn-cyan:not(:disabled):not(.disabled):active,
.btn-cyan:not(:disabled):not(.disabled).active,
.show>.btn-cyan.dropdown-toggle {
    color: #fff;
    background-color: #009da2;
    border-color: #009195;
}

.btn-cyan:not(:disabled):not(.disabled):active:focus,
.btn-cyan:not(:disabled):not(.disabled).active:focus,
.show>.btn-cyan.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 214, 219, 0.5);
}

.btn-blue {
    color: #fff;
    background-color: #0061f2;
    border-color: #0061f2;
}

.btn-blue:hover {
    color: #fff;
    background-color: #0052cc;
    border-color: #004dbf;
}

.btn-blue:focus,
.btn-blue.focus {
    color: #fff;
    background-color: #0052cc;
    border-color: #004dbf;
    box-shadow: 0 0 0 0.2rem rgba(38, 121, 244, 0.5);
}

.btn-blue.disabled,
.btn-blue:disabled {
    color: #fff;
    background-color: #0061f2;
    border-color: #0061f2;
}

.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show>.btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #004dbf;
    border-color: #0047b2;
}

.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show>.btn-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 121, 244, 0.5);
}

.btn-indigo {
    color: #fff;
    background-color: #5800e8;
    border-color: #5800e8;
}

.btn-indigo:hover {
    color: #fff;
    background-color: #4900c2;
    border-color: #4500b5;
}

.btn-indigo:focus,
.btn-indigo.focus {
    color: #fff;
    background-color: #4900c2;
    border-color: #4500b5;
    box-shadow: 0 0 0 0.2rem rgba(113, 38, 235, 0.5);
}

.btn-indigo.disabled,
.btn-indigo:disabled {
    color: #fff;
    background-color: #5800e8;
    border-color: #5800e8;
}

.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active,
.show>.btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #4500b5;
    border-color: #4000a8;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus,
.show>.btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(113, 38, 235, 0.5);
}

.btn-purple {
    color: #fff;
    background-color: #6900c7;
    border-color: #6900c7;
}

.btn-purple:hover {
    color: #fff;
    background-color: #5500a1;
    border-color: #4e0094;
}

.btn-purple:focus,
.btn-purple.focus {
    color: #fff;
    background-color: #5500a1;
    border-color: #4e0094;
    box-shadow: 0 0 0 0.2rem rgba(128, 38, 207, 0.5);
}

.btn-purple.disabled,
.btn-purple:disabled {
    color: #fff;
    background-color: #6900c7;
    border-color: #6900c7;
}

.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active,
.show>.btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #4e0094;
    border-color: #470087;
}

.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus,
.show>.btn-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(128, 38, 207, 0.5);
}

.btn-pink {
    color: #fff;
    background-color: #e30059;
    border-color: #e30059;
}

.btn-pink:hover {
    color: #fff;
    background-color: #bd004a;
    border-color: #b00045;
}

.btn-pink:focus,
.btn-pink.focus {
    color: #fff;
    background-color: #bd004a;
    border-color: #b00045;
    box-shadow: 0 0 0 0.2rem rgba(231, 38, 114, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
    color: #fff;
    background-color: #e30059;
    border-color: #e30059;
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show>.btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #b00045;
    border-color: #a30040;
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show>.btn-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 38, 114, 0.5);
}

.btn-red-soft {
    color: #1f2d41;
    background-color: #eec7c7;
    border-color: #eec7c7;
}

.btn-red-soft:hover {
    color: #fff;
    background-color: #e5aaaa;
    border-color: #e2a0a0;
}

.btn-red-soft:focus,
.btn-red-soft.focus {
    color: #fff;
    background-color: #e5aaaa;
    border-color: #e2a0a0;
    box-shadow: 0 0 0 0.2rem rgba(207, 176, 179, 0.5);
}

.btn-red-soft.disabled,
.btn-red-soft:disabled {
    color: #1f2d41;
    background-color: #eec7c7;
    border-color: #eec7c7;
}

.btn-red-soft:not(:disabled):not(.disabled):active,
.btn-red-soft:not(:disabled):not(.disabled).active,
.show>.btn-red-soft.dropdown-toggle {
    color: #fff;
    background-color: #e2a0a0;
    border-color: #df9696;
}

.btn-red-soft:not(:disabled):not(.disabled):active:focus,
.btn-red-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-red-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(207, 176, 179, 0.5);
}

.btn-orange-soft {
    color: #1f2d41;
    background-color: #f1d6c7;
    border-color: #f1d6c7;
}

.btn-orange-soft:hover {
    color: #1f2d41;
    background-color: #e9c0a8;
    border-color: #e7b89e;
}

.btn-orange-soft:focus,
.btn-orange-soft.focus {
    color: #1f2d41;
    background-color: #e9c0a8;
    border-color: #e7b89e;
    box-shadow: 0 0 0 0.2rem rgba(210, 189, 179, 0.5);
}

.btn-orange-soft.disabled,
.btn-orange-soft:disabled {
    color: #1f2d41;
    background-color: #f1d6c7;
    border-color: #f1d6c7;
}

.btn-orange-soft:not(:disabled):not(.disabled):active,
.btn-orange-soft:not(:disabled):not(.disabled).active,
.show>.btn-orange-soft.dropdown-toggle {
    color: #fff;
    background-color: #e7b89e;
    border-color: #e4b194;
}

.btn-orange-soft:not(:disabled):not(.disabled):active:focus,
.btn-orange-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-orange-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(210, 189, 179, 0.5);
}

.btn-yellow-soft {
    color: #1f2d41;
    background-color: #f0e3c7;
    border-color: #f0e3c7;
}

.btn-yellow-soft:hover {
    color: #1f2d41;
    background-color: #e8d4a9;
    border-color: #e5cf9f;
}

.btn-yellow-soft:focus,
.btn-yellow-soft.focus {
    color: #1f2d41;
    background-color: #e8d4a9;
    border-color: #e5cf9f;
    box-shadow: 0 0 0 0.2rem rgba(209, 200, 179, 0.5);
}

.btn-yellow-soft.disabled,
.btn-yellow-soft:disabled {
    color: #1f2d41;
    background-color: #f0e3c7;
    border-color: #f0e3c7;
}

.btn-yellow-soft:not(:disabled):not(.disabled):active,
.btn-yellow-soft:not(:disabled):not(.disabled).active,
.show>.btn-yellow-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #e5cf9f;
    border-color: #e3ca95;
}

.btn-yellow-soft:not(:disabled):not(.disabled):active:focus,
.btn-yellow-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-yellow-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 200, 179, 0.5);
}

.btn-green-soft {
    color: #1f2d41;
    background-color: #bfe5dc;
    border-color: #bfe5dc;
}

.btn-green-soft:hover {
    color: #1f2d41;
    background-color: #a4dacd;
    border-color: #9bd6c8;
}

.btn-green-soft:focus,
.btn-green-soft.focus {
    color: #1f2d41;
    background-color: #a4dacd;
    border-color: #9bd6c8;
    box-shadow: 0 0 0 0.2rem rgba(167, 201, 197, 0.5);
}

.btn-green-soft.disabled,
.btn-green-soft:disabled {
    color: #1f2d41;
    background-color: #bfe5dc;
    border-color: #bfe5dc;
}

.btn-green-soft:not(:disabled):not(.disabled):active,
.btn-green-soft:not(:disabled):not(.disabled).active,
.show>.btn-green-soft.dropdown-toggle {
    color: #fff;
    background-color: #9bd6c8;
    border-color: #92d3c3;
}

.btn-green-soft:not(:disabled):not(.disabled):active:focus,
.btn-green-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-green-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(167, 201, 197, 0.5);
}

.btn-teal-soft {
    color: #1f2d41;
    background-color: #bfe8e5;
    border-color: #bfe8e5;
}

.btn-teal-soft:hover {
    color: #1f2d41;
    background-color: #a3deda;
    border-color: #99dbd6;
}

.btn-teal-soft:focus,
.btn-teal-soft.focus {
    color: #1f2d41;
    background-color: #a3deda;
    border-color: #99dbd6;
    box-shadow: 0 0 0 0.2rem rgba(167, 204, 204, 0.5);
}

.btn-teal-soft.disabled,
.btn-teal-soft:disabled {
    color: #1f2d41;
    background-color: #bfe8e5;
    border-color: #bfe8e5;
}

.btn-teal-soft:not(:disabled):not(.disabled):active,
.btn-teal-soft:not(:disabled):not(.disabled).active,
.show>.btn-teal-soft.dropdown-toggle {
    color: #fff;
    background-color: #99dbd6;
    border-color: #90d7d2;
}

.btn-teal-soft:not(:disabled):not(.disabled):active:focus,
.btn-teal-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-teal-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(167, 204, 204, 0.5);
}

.btn-cyan-soft {
    color: #1f2d41;
    background-color: #bfecf2;
    border-color: #bfecf2;
}

.btn-cyan-soft:hover {
    color: #1f2d41;
    background-color: #9fe3ec;
    border-color: #95dfe9;
}

.btn-cyan-soft:focus,
.btn-cyan-soft.focus {
    color: #1f2d41;
    background-color: #9fe3ec;
    border-color: #95dfe9;
    box-shadow: 0 0 0 0.2rem rgba(167, 207, 215, 0.5);
}

.btn-cyan-soft.disabled,
.btn-cyan-soft:disabled {
    color: #1f2d41;
    background-color: #bfecf2;
    border-color: #bfecf2;
}

.btn-cyan-soft:not(:disabled):not(.disabled):active,
.btn-cyan-soft:not(:disabled):not(.disabled).active,
.show>.btn-cyan-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #95dfe9;
    border-color: #8adce7;
}

.btn-cyan-soft:not(:disabled):not(.disabled):active:focus,
.btn-cyan-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-cyan-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(167, 207, 215, 0.5);
}

.btn-blue-soft {
    color: #1f2d41;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.btn-blue-soft:hover {
    color: #fff;
    background-color: #9dc0f4;
    border-color: #91b9f3;
}

.btn-blue-soft:focus,
.btn-blue-soft.focus {
    color: #fff;
    background-color: #9dc0f4;
    border-color: #91b9f3;
    box-shadow: 0 0 0 0.2rem rgba(167, 189, 221, 0.5);
}

.btn-blue-soft.disabled,
.btn-blue-soft:disabled {
    color: #1f2d41;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.btn-blue-soft:not(:disabled):not(.disabled):active,
.btn-blue-soft:not(:disabled):not(.disabled).active,
.show>.btn-blue-soft.dropdown-toggle {
    color: #fff;
    background-color: #91b9f3;
    border-color: #86b1f2;
}

.btn-blue-soft:not(:disabled):not(.disabled):active:focus,
.btn-blue-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-blue-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(167, 189, 221, 0.5);
}

.btn-indigo-soft {
    color: #1f2d41;
    background-color: #d1c2f6;
    border-color: #d1c2f6;
}

.btn-indigo-soft:hover {
    color: #fff;
    background-color: #b8a1f1;
    border-color: #af96ef;
}

.btn-indigo-soft:focus,
.btn-indigo-soft.focus {
    color: #fff;
    background-color: #b8a1f1;
    border-color: #af96ef;
    box-shadow: 0 0 0 0.2rem rgba(182, 172, 219, 0.5);
}

.btn-indigo-soft.disabled,
.btn-indigo-soft:disabled {
    color: #1f2d41;
    background-color: #d1c2f6;
    border-color: #d1c2f6;
}

.btn-indigo-soft:not(:disabled):not(.disabled):active,
.btn-indigo-soft:not(:disabled):not(.disabled).active,
.show>.btn-indigo-soft.dropdown-toggle {
    color: #fff;
    background-color: #af96ef;
    border-color: #a78aee;
}

.btn-indigo-soft:not(:disabled):not(.disabled):active:focus,
.btn-indigo-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-indigo-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(182, 172, 219, 0.5);
}

.btn-purple-soft {
    color: #1f2d41;
    background-color: #d4c2ef;
    border-color: #d4c2ef;
}

.btn-purple-soft:hover {
    color: #fff;
    background-color: #bfa4e7;
    border-color: #b89ae4;
}

.btn-purple-soft:focus,
.btn-purple-soft.focus {
    color: #fff;
    background-color: #bfa4e7;
    border-color: #b89ae4;
    box-shadow: 0 0 0 0.2rem rgba(185, 172, 213, 0.5);
}

.btn-purple-soft.disabled,
.btn-purple-soft:disabled {
    color: #1f2d41;
    background-color: #d4c2ef;
    border-color: #d4c2ef;
}

.btn-purple-soft:not(:disabled):not(.disabled):active,
.btn-purple-soft:not(:disabled):not(.disabled).active,
.show>.btn-purple-soft.dropdown-toggle {
    color: #fff;
    background-color: #b89ae4;
    border-color: #b08fe2;
}

.btn-purple-soft:not(:disabled):not(.disabled):active:focus,
.btn-purple-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-purple-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(185, 172, 213, 0.5);
}

.btn-pink-soft {
    color: #1f2d41;
    background-color: #edc2d9;
    border-color: #edc2d9;
}

.btn-pink-soft:hover {
    color: #fff;
    background-color: #e4a4c7;
    border-color: #e19bc0;
}

.btn-pink-soft:focus,
.btn-pink-soft.focus {
    color: #fff;
    background-color: #e4a4c7;
    border-color: #e19bc0;
    box-shadow: 0 0 0 0.2rem rgba(206, 172, 194, 0.5);
}

.btn-pink-soft.disabled,
.btn-pink-soft:disabled {
    color: #1f2d41;
    background-color: #edc2d9;
    border-color: #edc2d9;
}

.btn-pink-soft:not(:disabled):not(.disabled):active,
.btn-pink-soft:not(:disabled):not(.disabled).active,
.show>.btn-pink-soft.dropdown-toggle {
    color: #fff;
    background-color: #e19bc0;
    border-color: #de91ba;
}

.btn-pink-soft:not(:disabled):not(.disabled):active:focus,
.btn-pink-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-pink-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(206, 172, 194, 0.5);
}

.btn-primary-soft {
    color: #fff;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.btn-primary-soft:hover {
    color: #fff;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.btn-primary-soft:focus,
.btn-primary-soft.focus {
    color: #fff;
    background-color: #9dc0f4;
    border-color: #91b9f3;
    box-shadow: 0 0 0 0.2rem rgba(167, 189, 221, 0.5);
}

.btn-primary-soft.disabled,
.btn-primary-soft:disabled {
    color: #1f2d41;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.btn-primary-soft:not(:disabled):not(.disabled):active,
.btn-primary-soft:not(:disabled):not(.disabled).active,
.show>.btn-primary-soft.dropdown-toggle {
    color: #fff;
    background-color: #91b9f3;
    border-color: #86b1f2;
}

.btn-primary-soft:not(:disabled):not(.disabled):active:focus,
.btn-primary-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(167, 189, 221, 0.5);
}

.btn-secondary-soft {
    color: #1f2d41;
    background-color: #d4c2ef;
    border-color: #d4c2ef;
}

.btn-secondary-soft:hover {
    color: #fff;
    background-color: #bfa4e7;
    border-color: #b89ae4;
}

.btn-secondary-soft:focus,
.btn-secondary-soft.focus {
    color: #fff;
    background-color: #bfa4e7;
    border-color: #b89ae4;
    box-shadow: 0 0 0 0.2rem rgba(185, 172, 213, 0.5);
}

.btn-secondary-soft.disabled,
.btn-secondary-soft:disabled {
    color: #1f2d41;
    background-color: #d4c2ef;
    border-color: #d4c2ef;
}

.btn-secondary-soft:not(:disabled):not(.disabled):active,
.btn-secondary-soft:not(:disabled):not(.disabled).active,
.show>.btn-secondary-soft.dropdown-toggle {
    color: #fff;
    background-color: #b89ae4;
    border-color: #b08fe2;
}

.btn-secondary-soft:not(:disabled):not(.disabled):active:focus,
.btn-secondary-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(185, 172, 213, 0.5);
}

.btn-success-soft {
    color: #1f2d41;
    background-color: #bfe5dc;
    border-color: #bfe5dc;
}

.btn-success-soft:hover {
    color: #1f2d41;
    background-color: #a4dacd;
    border-color: #9bd6c8;
}

.btn-success-soft:focus,
.btn-success-soft.focus {
    color: #1f2d41;
    background-color: #a4dacd;
    border-color: #9bd6c8;
    box-shadow: 0 0 0 0.2rem rgba(167, 201, 197, 0.5);
}

.btn-success-soft.disabled,
.btn-success-soft:disabled {
    color: #1f2d41;
    background-color: #bfe5dc;
    border-color: #bfe5dc;
}

.btn-success-soft:not(:disabled):not(.disabled):active,
.btn-success-soft:not(:disabled):not(.disabled).active,
.show>.btn-success-soft.dropdown-toggle {
    color: #fff;
    background-color: #9bd6c8;
    border-color: #92d3c3;
}

.btn-success-soft:not(:disabled):not(.disabled):active:focus,
.btn-success-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-success-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(167, 201, 197, 0.5);
}

.btn-info-soft {
    color: #1f2d41;
    background-color: #bfecf2;
    border-color: #bfecf2;
}

.btn-info-soft:hover {
    color: #1f2d41;
    background-color: #9fe3ec;
    border-color: #95dfe9;
}

.btn-info-soft:focus,
.btn-info-soft.focus {
    color: #1f2d41;
    background-color: #9fe3ec;
    border-color: #95dfe9;
    box-shadow: 0 0 0 0.2rem rgba(167, 207, 215, 0.5);
}

.btn-info-soft.disabled,
.btn-info-soft:disabled {
    color: #1f2d41;
    background-color: #bfecf2;
    border-color: #bfecf2;
}

.btn-info-soft:not(:disabled):not(.disabled):active,
.btn-info-soft:not(:disabled):not(.disabled).active,
.show>.btn-info-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #95dfe9;
    border-color: #8adce7;
}

.btn-info-soft:not(:disabled):not(.disabled):active:focus,
.btn-info-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-info-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(167, 207, 215, 0.5);
}

.btn-warning-soft {
    color: #1f2d41;
    background-color: #f0e3c7;
    border-color: #f0e3c7;
}

.btn-warning-soft:hover {
    color: #1f2d41;
    background-color: #e8d4a9;
    border-color: #e5cf9f;
}

.btn-warning-soft:focus,
.btn-warning-soft.focus {
    color: #1f2d41;
    background-color: #e8d4a9;
    border-color: #e5cf9f;
    box-shadow: 0 0 0 0.2rem rgba(209, 200, 179, 0.5);
}

.btn-warning-soft.disabled,
.btn-warning-soft:disabled {
    color: #1f2d41;
    background-color: #f0e3c7;
    border-color: #f0e3c7;
}

.btn-warning-soft:not(:disabled):not(.disabled):active,
.btn-warning-soft:not(:disabled):not(.disabled).active,
.show>.btn-warning-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #e5cf9f;
    border-color: #e3ca95;
}

.btn-warning-soft:not(:disabled):not(.disabled):active:focus,
.btn-warning-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 200, 179, 0.5);
}

.btn-danger-soft {
    color: #1f2d41;
    background-color: #eec7c7;
    border-color: #eec7c7;
}

.btn-danger-soft:hover {
    color: #fff;
    background-color: #e5aaaa;
    border-color: #e2a0a0;
}

.btn-danger-soft:focus,
.btn-danger-soft.focus {
    color: #fff;
    background-color: #e5aaaa;
    border-color: #e2a0a0;
    box-shadow: 0 0 0 0.2rem rgba(207, 176, 179, 0.5);
}

.btn-danger-soft.disabled,
.btn-danger-soft:disabled {
    color: #1f2d41;
    background-color: #eec7c7;
    border-color: #eec7c7;
}

.btn-danger-soft:not(:disabled):not(.disabled):active,
.btn-danger-soft:not(:disabled):not(.disabled).active,
.show>.btn-danger-soft.dropdown-toggle {
    color: #fff;
    background-color: #e2a0a0;
    border-color: #df9696;
}

.btn-danger-soft:not(:disabled):not(.disabled):active:focus,
.btn-danger-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(207, 176, 179, 0.5);
}

.btn-outline-primary {
    color: #17449e;
    border-color: #17449e;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #17449e;
    border-color: #17449e;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 96, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #17449e;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #17449e;
    border-color: #17449e;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 96, 0.5);
}

.btn-outline-secondary {
    color: #5e5e5e;
    border-color: #5e5e5e;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #5e5e5e;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 97, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #5e5e5e;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #5e5e5e;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 97, 0.5);
}

.btn-outline-success {
    color: #00ac69;
    border-color: #00ac69;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #00ac69;
    border-color: #00ac69;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 98, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #00ac69;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00ac69;
    border-color: #00ac69;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 98, 0.5);
}

.btn-outline-info {
    color: #00cfd5;
    border-color: #00cfd5;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #00cfd5;
    border-color: #00cfd5;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 101, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #00cfd5;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00cfd5;
    border-color: #00cfd5;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 101, 0.5);
}

.btn-outline-warning {
    color: #f4a100;
    border-color: #f4a100;
}

.btn-outline-warning:hover {
    color: #fff;
    background-color: #f4a100;
    border-color: #f4a100;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 100, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #f4a100;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f4a100;
    border-color: #f4a100;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 100, 0.5);
}

.btn-outline-danger {
    color: #e81500;
    border-color: #e81500;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #e81500;
    border-color: #e81500;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 99, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #e81500;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e81500;
    border-color: #e81500;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 99, 0.5);
}

.btn-outline-light {
    color: #eff3f9;
    border-color: #eff3f9;
}

.btn-outline-light:hover {
    color: #1f2d41;
    background-color: #eff3f9;
    border-color: #eff3f9;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 243, 249, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #eff3f9;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #1f2d41;
    background-color: #eff3f9;
    border-color: #eff3f9;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 243, 249, 0.5);
}

.btn-outline-dark {
    color: #1f2d41;
    border-color: #1f2d41;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #1f2d41;
    border-color: #1f2d41;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(31, 45, 65, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #1f2d41;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #1f2d41;
    border-color: #1f2d41;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(31, 45, 65, 0.5);
}

.btn-outline-black {
    color: #000;
    border-color: #000;
}

.btn-outline-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

.btn-outline-black:focus,
.btn-outline-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black.disabled,
.btn-outline-black:disabled {
    color: #000;
    background-color: transparent;
}

.btn-outline-black:not(:disabled):not(.disabled):active,
.btn-outline-black:not(:disabled):not(.disabled).active,
.show>.btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

.btn-outline-black:not(:disabled):not(.disabled):active:focus,
.btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-white {
    color: #fff;
    border-color: #fff;
}

.btn-outline-white:hover {
    color: #1f2d41;
    background-color: #fff;
    border-color: #fff;
}

.btn-outline-white:focus,
.btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
    color: #fff;
    background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show>.btn-outline-white.dropdown-toggle {
    color: #1f2d41;
    background-color: #fff;
    border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-red {
    color: #e81500;
    border-color: #e81500;
}

.btn-outline-red:hover {
    color: #fff;
    background-color: #e81500;
    border-color: #e81500;
}

.btn-outline-red:focus,
.btn-outline-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 21, 0, 0.5);
}

.btn-outline-red.disabled,
.btn-outline-red:disabled {
    color: #e81500;
    background-color: transparent;
}

.btn-outline-red:not(:disabled):not(.disabled):active,
.btn-outline-red:not(:disabled):not(.disabled).active,
.show>.btn-outline-red.dropdown-toggle {
    color: #fff;
    background-color: #e81500;
    border-color: #e81500;
}

.btn-outline-red:not(:disabled):not(.disabled):active:focus,
.btn-outline-red:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-red.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 21, 0, 0.5);
}

.btn-outline-orange {
    color: #f76400;
    border-color: #f76400;
}

.btn-outline-orange:hover {
    color: #fff;
    background-color: #f76400;
    border-color: #f76400;
}

.btn-outline-orange:focus,
.btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 100, 0, 0.5);
}

.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
    color: #f76400;
    background-color: transparent;
}

.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled).active,
.show>.btn-outline-orange.dropdown-toggle {
    color: #fff;
    background-color: #f76400;
    border-color: #f76400;
}

.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-orange.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 100, 0, 0.5);
}

.btn-outline-yellow {
    color: #f4a100;
    border-color: #f4a100;
}

.btn-outline-yellow:hover {
    color: #fff;
    background-color: #f4a100;
    border-color: #f4a100;
}

.btn-outline-yellow:focus,
.btn-outline-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 161, 0, 0.5);
}

.btn-outline-yellow.disabled,
.btn-outline-yellow:disabled {
    color: #f4a100;
    background-color: transparent;
}

.btn-outline-yellow:not(:disabled):not(.disabled):active,
.btn-outline-yellow:not(:disabled):not(.disabled).active,
.show>.btn-outline-yellow.dropdown-toggle {
    color: #fff;
    background-color: #f4a100;
    border-color: #f4a100;
}

.btn-outline-yellow:not(:disabled):not(.disabled):active:focus,
.btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-yellow.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 161, 0, 0.5);
}

.btn-outline-green {
    color: #00ac69;
    border-color: #00ac69;
}

.btn-outline-green:hover {
    color: #fff;
    background-color: #00ac69;
    border-color: #00ac69;
}

.btn-outline-green:focus,
.btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.5);
}

.btn-outline-green.disabled,
.btn-outline-green:disabled {
    color: #00ac69;
    background-color: transparent;
}

.btn-outline-green:not(:disabled):not(.disabled):active,
.btn-outline-green:not(:disabled):not(.disabled).active,
.show>.btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #00ac69;
    border-color: #00ac69;
}

.btn-outline-green:not(:disabled):not(.disabled):active:focus,
.btn-outline-green:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-green.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.5);
}

.btn-outline-teal {
    color: #00ba94;
    border-color: #00ba94;
}

.btn-outline-teal:hover {
    color: #fff;
    background-color: #00ba94;
    border-color: #00ba94;
}

.btn-outline-teal:focus,
.btn-outline-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 186, 148, 0.5);
}

.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
    color: #00ba94;
    background-color: transparent;
}

.btn-outline-teal:not(:disabled):not(.disabled):active,
.btn-outline-teal:not(:disabled):not(.disabled).active,
.show>.btn-outline-teal.dropdown-toggle {
    color: #fff;
    background-color: #00ba94;
    border-color: #00ba94;
}

.btn-outline-teal:not(:disabled):not(.disabled):active:focus,
.btn-outline-teal:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 186, 148, 0.5);
}

.btn-outline-cyan {
    color: #00cfd5;
    border-color: #00cfd5;
}

.btn-outline-cyan:hover {
    color: #fff;
    background-color: #00cfd5;
    border-color: #00cfd5;
}

.btn-outline-cyan:focus,
.btn-outline-cyan.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 207, 213, 0.5);
}

.btn-outline-cyan.disabled,
.btn-outline-cyan:disabled {
    color: #00cfd5;
    background-color: transparent;
}

.btn-outline-cyan:not(:disabled):not(.disabled):active,
.btn-outline-cyan:not(:disabled):not(.disabled).active,
.show>.btn-outline-cyan.dropdown-toggle {
    color: #fff;
    background-color: #00cfd5;
    border-color: #00cfd5;
}

.btn-outline-cyan:not(:disabled):not(.disabled):active:focus,
.btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-cyan.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 207, 213, 0.5);
}

.btn-outline-blue {
    color: #0061f2;
    border-color: #0061f2;
}

.btn-outline-blue:hover {
    color: #fff;
    background-color: #0061f2;
    border-color: #0061f2;
}

.btn-outline-blue:focus,
.btn-outline-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}

.btn-outline-blue.disabled,
.btn-outline-blue:disabled {
    color: #0061f2;
    background-color: transparent;
}

.btn-outline-blue:not(:disabled):not(.disabled):active,
.btn-outline-blue:not(:disabled):not(.disabled).active,
.show>.btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #0061f2;
    border-color: #0061f2;
}

.btn-outline-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}

.btn-outline-indigo {
    color: #5800e8;
    border-color: #5800e8;
}

.btn-outline-indigo:hover {
    color: #fff;
    background-color: #5800e8;
    border-color: #5800e8;
}

.btn-outline-indigo:focus,
.btn-outline-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 0, 232, 0.5);
}

.btn-outline-indigo.disabled,
.btn-outline-indigo:disabled {
    color: #5800e8;
    background-color: transparent;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active,
.btn-outline-indigo:not(:disabled):not(.disabled).active,
.show>.btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #5800e8;
    border-color: #5800e8;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active:focus,
.btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 0, 232, 0.5);
}

.btn-outline-purple {
    color: #6900c7;
    border-color: #6900c7;
}

.btn-outline-purple:hover {
    color: #fff;
    background-color: #6900c7;
    border-color: #6900c7;
}

.btn-outline-purple:focus,
.btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 0, 199, 0.5);
}

.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
    color: #6900c7;
    background-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled):active,
.btn-outline-purple:not(:disabled):not(.disabled).active,
.show>.btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #6900c7;
    border-color: #6900c7;
}

.btn-outline-purple:not(:disabled):not(.disabled):active:focus,
.btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 0, 199, 0.5);
}

.btn-outline-pink {
    color: #e30059;
    border-color: #e30059;
}

.btn-outline-pink:hover {
    color: #fff;
    background-color: #e30059;
    border-color: #e30059;
}

.btn-outline-pink:focus,
.btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(227, 0, 89, 0.5);
}

.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
    color: #e30059;
    background-color: transparent;
}

.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled).active,
.show>.btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #e30059;
    border-color: #e30059;
}

.btn-outline-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(227, 0, 89, 0.5);
}

.btn-outline-red-soft {
    color: #eec7c7;
    border-color: #eec7c7;
}

.btn-outline-red-soft:hover {
    color: #1f2d41;
    background-color: #eec7c7;
    border-color: #eec7c7;
}

.btn-outline-red-soft:focus,
.btn-outline-red-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(238, 199, 199, 0.5);
}

.btn-outline-red-soft.disabled,
.btn-outline-red-soft:disabled {
    color: #eec7c7;
    background-color: transparent;
}

.btn-outline-red-soft:not(:disabled):not(.disabled):active,
.btn-outline-red-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-red-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #eec7c7;
    border-color: #eec7c7;
}

.btn-outline-red-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-red-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-red-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(238, 199, 199, 0.5);
}

.btn-outline-orange-soft {
    color: #f1d6c7;
    border-color: #f1d6c7;
}

.btn-outline-orange-soft:hover {
    color: #1f2d41;
    background-color: #f1d6c7;
    border-color: #f1d6c7;
}

.btn-outline-orange-soft:focus,
.btn-outline-orange-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 214, 199, 0.5);
}

.btn-outline-orange-soft.disabled,
.btn-outline-orange-soft:disabled {
    color: #f1d6c7;
    background-color: transparent;
}

.btn-outline-orange-soft:not(:disabled):not(.disabled):active,
.btn-outline-orange-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-orange-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #f1d6c7;
    border-color: #f1d6c7;
}

.btn-outline-orange-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-orange-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 214, 199, 0.5);
}

.btn-outline-yellow-soft {
    color: #f0e3c7;
    border-color: #f0e3c7;
}

.btn-outline-yellow-soft:hover {
    color: #1f2d41;
    background-color: #f0e3c7;
    border-color: #f0e3c7;
}

.btn-outline-yellow-soft:focus,
.btn-outline-yellow-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 227, 199, 0.5);
}

.btn-outline-yellow-soft.disabled,
.btn-outline-yellow-soft:disabled {
    color: #f0e3c7;
    background-color: transparent;
}

.btn-outline-yellow-soft:not(:disabled):not(.disabled):active,
.btn-outline-yellow-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-yellow-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #f0e3c7;
    border-color: #f0e3c7;
}

.btn-outline-yellow-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-yellow-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-yellow-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 227, 199, 0.5);
}

.btn-outline-green-soft {
    color: #bfe5dc;
    border-color: #bfe5dc;
}

.btn-outline-green-soft:hover {
    color: #1f2d41;
    background-color: #bfe5dc;
    border-color: #bfe5dc;
}

.btn-outline-green-soft:focus,
.btn-outline-green-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 229, 220, 0.5);
}

.btn-outline-green-soft.disabled,
.btn-outline-green-soft:disabled {
    color: #bfe5dc;
    background-color: transparent;
}

.btn-outline-green-soft:not(:disabled):not(.disabled):active,
.btn-outline-green-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-green-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #bfe5dc;
    border-color: #bfe5dc;
}

.btn-outline-green-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-green-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-green-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 229, 220, 0.5);
}

.btn-outline-teal-soft {
    color: #bfe8e5;
    border-color: #bfe8e5;
}

.btn-outline-teal-soft:hover {
    color: #1f2d41;
    background-color: #bfe8e5;
    border-color: #bfe8e5;
}

.btn-outline-teal-soft:focus,
.btn-outline-teal-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 232, 229, 0.5);
}

.btn-outline-teal-soft.disabled,
.btn-outline-teal-soft:disabled {
    color: #bfe8e5;
    background-color: transparent;
}

.btn-outline-teal-soft:not(:disabled):not(.disabled):active,
.btn-outline-teal-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-teal-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #bfe8e5;
    border-color: #bfe8e5;
}

.btn-outline-teal-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-teal-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-teal-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 232, 229, 0.5);
}

.btn-outline-cyan-soft {
    color: #bfecf2;
    border-color: #bfecf2;
}

.btn-outline-cyan-soft:hover {
    color: #1f2d41;
    background-color: #bfecf2;
    border-color: #bfecf2;
}

.btn-outline-cyan-soft:focus,
.btn-outline-cyan-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 236, 242, 0.5);
}

.btn-outline-cyan-soft.disabled,
.btn-outline-cyan-soft:disabled {
    color: #bfecf2;
    background-color: transparent;
}

.btn-outline-cyan-soft:not(:disabled):not(.disabled):active,
.btn-outline-cyan-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-cyan-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #bfecf2;
    border-color: #bfecf2;
}

.btn-outline-cyan-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-cyan-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-cyan-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 236, 242, 0.5);
}

.btn-outline-blue-soft {
    color: #4d93e9;
    border-color: #4d93e9;
}

.btn-outline-blue-soft:hover {
    color: #1f2d41;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.btn-outline-blue-soft:focus,
.btn-outline-blue-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 214, 248, 0.5);
}

.btn-outline-blue-soft.disabled,
.btn-outline-blue-soft:disabled {
    color: #4d93e9;
    background-color: transparent;
}

.btn-outline-blue-soft:not(:disabled):not(.disabled):active,
.btn-outline-blue-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-blue-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.btn-outline-blue-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-blue-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 214, 248, 0.5);
}

.btn-outline-indigo-soft {
    color: #d1c2f6;
    border-color: #d1c2f6;
}

.btn-outline-indigo-soft:hover {
    color: #1f2d41;
    background-color: #d1c2f6;
    border-color: #d1c2f6;
}

.btn-outline-indigo-soft:focus,
.btn-outline-indigo-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 194, 246, 0.5);
}

.btn-outline-indigo-soft.disabled,
.btn-outline-indigo-soft:disabled {
    color: #d1c2f6;
    background-color: transparent;
}

.btn-outline-indigo-soft:not(:disabled):not(.disabled):active,
.btn-outline-indigo-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-indigo-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #d1c2f6;
    border-color: #d1c2f6;
}

.btn-outline-indigo-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-indigo-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-indigo-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 194, 246, 0.5);
}

.btn-outline-purple-soft {
    color: #d4c2ef;
    border-color: #d4c2ef;
}

.btn-outline-purple-soft:hover {
    color: #1f2d41;
    background-color: #d4c2ef;
    border-color: #d4c2ef;
}

.btn-outline-purple-soft:focus,
.btn-outline-purple-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 194, 239, 0.5);
}

.btn-outline-purple-soft.disabled,
.btn-outline-purple-soft:disabled {
    color: #d4c2ef;
    background-color: transparent;
}

.btn-outline-purple-soft:not(:disabled):not(.disabled):active,
.btn-outline-purple-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-purple-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #d4c2ef;
    border-color: #d4c2ef;
}

.btn-outline-purple-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-purple-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-purple-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 194, 239, 0.5);
}

.btn-outline-pink-soft {
    color: #edc2d9;
    border-color: #edc2d9;
}

.btn-outline-pink-soft:hover {
    color: #1f2d41;
    background-color: #edc2d9;
    border-color: #edc2d9;
}

.btn-outline-pink-soft:focus,
.btn-outline-pink-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 194, 217, 0.5);
}

.btn-outline-pink-soft.disabled,
.btn-outline-pink-soft:disabled {
    color: #edc2d9;
    background-color: transparent;
}

.btn-outline-pink-soft:not(:disabled):not(.disabled):active,
.btn-outline-pink-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-pink-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #edc2d9;
    border-color: #edc2d9;
}

.btn-outline-pink-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-pink-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 194, 217, 0.5);
}

.btn-outline-primary-soft {
    color: #4d93e9;
    border-color: #4d93e9;
}

.btn-outline-primary-soft:hover {
    color: #1f2d41;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.btn-outline-primary-soft:focus,
.btn-outline-primary-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 214, 248, 0.5);
}

.btn-outline-primary-soft.disabled,
.btn-outline-primary-soft:disabled {
    color: #4d93e9;
    background-color: transparent;
}

.btn-outline-primary-soft:not(:disabled):not(.disabled):active,
.btn-outline-primary-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.btn-outline-primary-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 214, 248, 0.5);
}

.btn-outline-secondary-soft {
    color: #d4c2ef;
    border-color: #d4c2ef;
}

.btn-outline-secondary-soft:hover {
    color: #1f2d41;
    background-color: #d4c2ef;
    border-color: #d4c2ef;
}

.btn-outline-secondary-soft:focus,
.btn-outline-secondary-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 194, 239, 0.5);
}

.btn-outline-secondary-soft.disabled,
.btn-outline-secondary-soft:disabled {
    color: #d4c2ef;
    background-color: transparent;
}

.btn-outline-secondary-soft:not(:disabled):not(.disabled):active,
.btn-outline-secondary-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #d4c2ef;
    border-color: #d4c2ef;
}

.btn-outline-secondary-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 194, 239, 0.5);
}

.btn-outline-success-soft {
    color: #bfe5dc;
    border-color: #bfe5dc;
}

.btn-outline-success-soft:hover {
    color: #1f2d41;
    background-color: #bfe5dc;
    border-color: #bfe5dc;
}

.btn-outline-success-soft:focus,
.btn-outline-success-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 229, 220, 0.5);
}

.btn-outline-success-soft.disabled,
.btn-outline-success-soft:disabled {
    color: #bfe5dc;
    background-color: transparent;
}

.btn-outline-success-soft:not(:disabled):not(.disabled):active,
.btn-outline-success-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-success-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #bfe5dc;
    border-color: #bfe5dc;
}

.btn-outline-success-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-success-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 229, 220, 0.5);
}

.btn-outline-info-soft {
    color: #bfecf2;
    border-color: #bfecf2;
}

.btn-outline-info-soft:hover {
    color: #1f2d41;
    background-color: #bfecf2;
    border-color: #bfecf2;
}

.btn-outline-info-soft:focus,
.btn-outline-info-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 236, 242, 0.5);
}

.btn-outline-info-soft.disabled,
.btn-outline-info-soft:disabled {
    color: #bfecf2;
    background-color: transparent;
}

.btn-outline-info-soft:not(:disabled):not(.disabled):active,
.btn-outline-info-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-info-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #bfecf2;
    border-color: #bfecf2;
}

.btn-outline-info-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-info-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 236, 242, 0.5);
}

.btn-outline-warning-soft {
    color: #f0e3c7;
    border-color: #f0e3c7;
}

.btn-outline-warning-soft:hover {
    color: #1f2d41;
    background-color: #f0e3c7;
    border-color: #f0e3c7;
}

.btn-outline-warning-soft:focus,
.btn-outline-warning-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 227, 199, 0.5);
}

.btn-outline-warning-soft.disabled,
.btn-outline-warning-soft:disabled {
    color: #f0e3c7;
    background-color: transparent;
}

.btn-outline-warning-soft:not(:disabled):not(.disabled):active,
.btn-outline-warning-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #f0e3c7;
    border-color: #f0e3c7;
}

.btn-outline-warning-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 227, 199, 0.5);
}

.btn-outline-danger-soft {
    color: #eec7c7;
    border-color: #eec7c7;
}

.btn-outline-danger-soft:hover {
    color: #1f2d41;
    background-color: #eec7c7;
    border-color: #eec7c7;
}

.btn-outline-danger-soft:focus,
.btn-outline-danger-soft.focus {
    box-shadow: 0 0 0 0.2rem rgba(238, 199, 199, 0.5);
}

.btn-outline-danger-soft.disabled,
.btn-outline-danger-soft:disabled {
    color: #eec7c7;
    background-color: transparent;
}

.btn-outline-danger-soft:not(:disabled):not(.disabled):active,
.btn-outline-danger-soft:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger-soft.dropdown-toggle {
    color: #1f2d41;
    background-color: #eec7c7;
    border-color: #eec7c7;
}

.btn-outline-danger-soft:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger-soft:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger-soft.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(238, 199, 199, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #17449e;
    text-decoration: none;
}

.btn-link:hover {
    color: #141e2c;
    text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
    box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #687281;
    pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.5rem;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

.fade {
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #687281;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e3e6ec;
    border-radius: 3px;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}

.dropleft .dropdown-toggle::after {
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e3e6ec;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #7a7a7a;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #17449e;
    text-decoration: none;
    background-color: #eff3f9;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #17449e;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #687281;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #687281;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #1f2d41;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    flex: 1 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
    z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 1;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.custom-file {
    display: flex;
    align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
    z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #687281;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #e3e6ec;
    border-radius: 3px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
    margin-top: 0;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.5rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 2rem;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.9rem;
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.15rem;
    height: 1.325rem;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #17449e;
    background-color: #17449e;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.0625rem #17449e;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #17449e;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #7c9ac3;
    border-color: #7c9ac3;
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
    color: #687281;
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e3e6ec;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label::before {
    position: absolute;
    top: 0.175rem;
    left: -1.9rem;
    display: block;
    width: 1.15rem;
    height: 1.15rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #e3e6ec solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.175rem;
    left: -1.9rem;
    display: block;
    width: 1.15rem;
    height: 1.15rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #17449e;
    background-color: #17449e;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(44, 66, 96, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(44, 66, 96, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(44, 66, 96, 0.5);
}

.custom-switch {
    padding-left: 2.7625rem;
}

.custom-switch .custom-control-label::before {
    left: -2.7625rem;
    width: 2.0125rem;
    pointer-events: all;
    border-radius: 0.575rem;
}

.custom-switch .custom-control-label::after {
    top: calc(0.175rem + 2px);
    left: calc(-2.7625rem + 2px);
    width: calc(1.15rem - 4px);
    height: calc(1.15rem - 4px);
    background-color: #e3e6ec;
    border-radius: 0.575rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none;
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.8625rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(44, 66, 96, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 2rem 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #687281;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23323f52' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
    border: 1px solid #e3e6ec;
    border-radius: 3px;
    appearance: none;
}

.custom-select:focus {
    border-color: #17449e;
    outline: 0;
    box-shadow: 0 0 0 0.0625rem rgba(44, 66, 96, 0.25);
}

.custom-select:focus::-ms-value {
    color: #687281;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none;
}

.custom-select:disabled {
    color: #687281;
    background-color: #e3e6ec;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #687281;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #17449e;
    box-shadow: 0 0 0 0.0625rem #17449e;
}

.custom-file-input[disabled]~.custom-file-label,
.custom-file-input:disabled~.custom-file-label {
    background-color: #e3e6ec;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #687281;
    background-color: #fff;
    border: 1px solid #e3e6ec;
    border-radius: 3px;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1rem);
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #687281;
    content: "Browse";
    background-color: #fff;
    border-left: inherit;
    border-radius: 0 3px 3px 0;
}

.custom-range {
    width: 100%;
    height: 1.125rem;
    padding: 0;
    background-color: transparent;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.0625rem #17449e;
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.0625rem #17449e;
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.0625rem #17449e;
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #17449e;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none;
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #7c9ac3;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d7dce3;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #17449e;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none;
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #7c9ac3;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d7dce3;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.0625rem;
    margin-left: 0.0625rem;
    background-color: #17449e;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none;
    }
}

.custom-range::-ms-thumb:active {
    background-color: #7c9ac3;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #d7dce3;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #d7dce3;
    border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #a2acba;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #a2acba;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #a2acba;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #687281;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #d7dce3;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e3e6ec #e3e6ec #d7dce3;
}

.nav-tabs .nav-link.disabled {
    color: #687281;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #485260;
    background-color: #fff;
    border-color: #d7dce3 #d7dce3 #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 3px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #17449e;
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    transition: 0.25s ease-in-out;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-xl {
        flex-wrap: nowrap;
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-xl {
        flex-wrap: nowrap;
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-xl {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-xl {
        flex-wrap: nowrap;
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: #17449e
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 3px;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.35rem;
}

.card-title {
    margin-bottom: 1rem;
}

.card-subtitle {
    margin-top: -0.5rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.35rem;
}

.card-header {
    padding: 1rem 1.35rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: 3px 3px 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 1rem 1.35rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 0 solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 3px 3px;
}

.card-header-tabs {
    margin-right: -0.675rem;
    margin-bottom: -1rem;
    margin-left: -0.675rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.675rem;
    margin-left: -0.675rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0;
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.card-deck .card {
    margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
    }

    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 1.5rem;
        margin-bottom: 0;
        margin-left: 1.5rem;
    }
}

.card-group>.card {
    margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 1rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion>.card {
    overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: 0;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e3e6ec;
    border-radius: 3px;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #687281;
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #687281;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 3px;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #17449e;
    background-color: #fff;
    border: 1px solid #d7dce3;
}

.page-link:hover {
    z-index: 2;
    color: #141e2c;
    text-decoration: none;
    background-color: #e3e6ec;
    border-color: #d7dce3;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 96, 0.25);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #17449e;
    border-color: #17449e;
}

.page-item.disabled .page-link {
    color: #687281;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #d7dce3;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #17449e;
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #fff;
    background-color: #1c2a3d;
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 96, 0.5);
}

.badge-secondary {
    color: #fff;
    background-color: #5e5e5e;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #fff;
    background-color: #1c2a3e;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 97, 0.5);
}

.badge-success {
    color: #fff;
    background-color: #00ac69;
}

a.badge-success:hover,
a.badge-success:focus {
    color: #fff;
    background-color: #1c2a3f;
}

a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 98, 0.5);
}

.badge-info {
    color: #fff;
    background-color: #00cfd5;
}

a.badge-info:hover,
a.badge-info:focus {
    color: #fff;
    background-color: #1d2b41;
}

a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 101, 0.5);
}

.badge-warning {
    color: #fff;
    background-color: #f4a100;
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #fff;
    background-color: #1c2b41;
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 100, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #e81500;
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #fff;
    background-color: #1c2a40;
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 66, 99, 0.5);
}

.badge-light {
    color: #1f2d41;
    background-color: #eff3f9;
}

a.badge-light:hover,
a.badge-light:focus {
    color: #1f2d41;
    background-color: #cad7eb;
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 243, 249, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #1f2d41;
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #fff;
    background-color: #0f151e;
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(31, 45, 65, 0.5);
}

.badge-black {
    color: #fff;
    background-color: #000;
}

a.badge-black:hover,
a.badge-black:focus {
    color: #fff;
    background-color: black;
}

a.badge-black:focus,
a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-white {
    color: #1f2d41;
    background-color: #fff;
}

a.badge-white:hover,
a.badge-white:focus {
    color: #1f2d41;
    background-color: #e6e6e6;
}

a.badge-white:focus,
a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-red {
    color: #fff;
    background-color: #e81500;
}

a.badge-red:hover,
a.badge-red:focus {
    color: #fff;
    background-color: #b51000;
}

a.badge-red:focus,
a.badge-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(232, 21, 0, 0.5);
}

.badge-orange {
    color: #fff;
    background-color: #f76400;
}

a.badge-orange:hover,
a.badge-orange:focus {
    color: #fff;
    background-color: #c44f00;
}

a.badge-orange:focus,
a.badge-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 100, 0, 0.5);
}

.badge-yellow {
    color: #fff;
    background-color: #f4a100;
}

a.badge-yellow:hover,
a.badge-yellow:focus {
    color: #fff;
    background-color: #c17f00;
}

a.badge-yellow:focus,
a.badge-yellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 161, 0, 0.5);
}

.badge-green {
    color: #fff;
    background-color: #00ac69;
}

a.badge-green:hover,
a.badge-green:focus {
    color: #fff;
    background-color: #00794a;
}

a.badge-green:focus,
a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 105, 0.5);
}

.badge-teal {
    color: #fff;
    background-color: #00ba94;
}

a.badge-teal:hover,
a.badge-teal:focus {
    color: #fff;
    background-color: #00876b;
}

a.badge-teal:focus,
a.badge-teal.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 186, 148, 0.5);
}

.badge-cyan {
    color: #fff;
    background-color: #00cfd5;
}

a.badge-cyan:hover,
a.badge-cyan:focus {
    color: #fff;
    background-color: #009da2;
}

a.badge-cyan:focus,
a.badge-cyan.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 207, 213, 0.5);
}

.badge-blue {
    color: #fff;
    background-color: #0061f2;
}

a.badge-blue:hover,
a.badge-blue:focus {
    color: #fff;
    background-color: #004dbf;
}

a.badge-blue:focus,
a.badge-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 97, 242, 0.5);
}

.badge-indigo {
    color: #fff;
    background-color: #5800e8;
}

a.badge-indigo:hover,
a.badge-indigo:focus {
    color: #fff;
    background-color: #4500b5;
}

a.badge-indigo:focus,
a.badge-indigo.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(88, 0, 232, 0.5);
}

.badge-purple {
    color: #fff;
    background-color: #6900c7;
}

a.badge-purple:hover,
a.badge-purple:focus {
    color: #fff;
    background-color: #4e0094;
}

a.badge-purple:focus,
a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 0, 199, 0.5);
}

.badge-pink {
    color: #fff;
    background-color: #e30059;
}

a.badge-pink:hover,
a.badge-pink:focus {
    color: #fff;
    background-color: #b00045;
}

a.badge-pink:focus,
a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(227, 0, 89, 0.5);
}

.badge-red-soft {
    color: #1f2d41;
    background-color: #eec7c7;
}

a.badge-red-soft:hover,
a.badge-red-soft:focus {
    color: #1f2d41;
    background-color: #e2a0a0;
}

a.badge-red-soft:focus,
a.badge-red-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(238, 199, 199, 0.5);
}

.badge-orange-soft {
    color: #1f2d41;
    background-color: #f1d6c7;
}

a.badge-orange-soft:hover,
a.badge-orange-soft:focus {
    color: #1f2d41;
    background-color: #e7b89e;
}

a.badge-orange-soft:focus,
a.badge-orange-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(241, 214, 199, 0.5);
}

.badge-yellow-soft {
    color: #1f2d41;
    background-color: #f0e3c7;
}

a.badge-yellow-soft:hover,
a.badge-yellow-soft:focus {
    color: #1f2d41;
    background-color: #e5cf9f;
}

a.badge-yellow-soft:focus,
a.badge-yellow-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(240, 227, 199, 0.5);
}

.badge-green-soft {
    color: #1f2d41;
    background-color: #bfe5dc;
}

a.badge-green-soft:hover,
a.badge-green-soft:focus {
    color: #1f2d41;
    background-color: #9bd6c8;
}

a.badge-green-soft:focus,
a.badge-green-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 229, 220, 0.5);
}

.badge-teal-soft {
    color: #1f2d41;
    background-color: #bfe8e5;
}

a.badge-teal-soft:hover,
a.badge-teal-soft:focus {
    color: #1f2d41;
    background-color: #99dbd6;
}

a.badge-teal-soft:focus,
a.badge-teal-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 232, 229, 0.5);
}

.badge-cyan-soft {
    color: #1f2d41;
    background-color: #bfecf2;
}

a.badge-cyan-soft:hover,
a.badge-cyan-soft:focus {
    color: #1f2d41;
    background-color: #95dfe9;
}

a.badge-cyan-soft:focus,
a.badge-cyan-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 236, 242, 0.5);
}

.badge-blue-soft {
    color: #1f2d41;
    background-color: #4d93e9;
}

a.badge-blue-soft:hover,
a.badge-blue-soft:focus {
    color: #1f2d41;
    background-color: #91b9f3;
}

a.badge-blue-soft:focus,
a.badge-blue-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 214, 248, 0.5);
}

.badge-indigo-soft {
    color: #1f2d41;
    background-color: #d1c2f6;
}

a.badge-indigo-soft:hover,
a.badge-indigo-soft:focus {
    color: #1f2d41;
    background-color: #af96ef;
}

a.badge-indigo-soft:focus,
a.badge-indigo-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(209, 194, 246, 0.5);
}

.badge-purple-soft {
    color: #1f2d41;
    background-color: #d4c2ef;
}

a.badge-purple-soft:hover,
a.badge-purple-soft:focus {
    color: #1f2d41;
    background-color: #b89ae4;
}

a.badge-purple-soft:focus,
a.badge-purple-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(212, 194, 239, 0.5);
}

.badge-pink-soft {
    color: #1f2d41;
    background-color: #edc2d9;
}

a.badge-pink-soft:hover,
a.badge-pink-soft:focus {
    color: #1f2d41;
    background-color: #e19bc0;
}

a.badge-pink-soft:focus,
a.badge-pink-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(237, 194, 217, 0.5);
}

.badge-primary-soft {
    color: #1f2d41;
    background-color: #4d93e9;
}

a.badge-primary-soft:hover,
a.badge-primary-soft:focus {
    color: #1f2d41;
    background-color: #91b9f3;
}

a.badge-primary-soft:focus,
a.badge-primary-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 214, 248, 0.5);
}

.badge-secondary-soft {
    color: #1f2d41;
    background-color: #d4c2ef;
}

a.badge-secondary-soft:hover,
a.badge-secondary-soft:focus {
    color: #1f2d41;
    background-color: #b89ae4;
}

a.badge-secondary-soft:focus,
a.badge-secondary-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(212, 194, 239, 0.5);
}

.badge-success-soft {
    color: #1f2d41;
    background-color: #bfe5dc;
}

a.badge-success-soft:hover,
a.badge-success-soft:focus {
    color: #1f2d41;
    background-color: #9bd6c8;
}

a.badge-success-soft:focus,
a.badge-success-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 229, 220, 0.5);
}

.badge-info-soft {
    color: #1f2d41;
    background-color: #bfecf2;
}

a.badge-info-soft:hover,
a.badge-info-soft:focus {
    color: #1f2d41;
    background-color: #95dfe9;
}

a.badge-info-soft:focus,
a.badge-info-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 236, 242, 0.5);
}

.badge-warning-soft {
    color: #1f2d41;
    background-color: #f0e3c7;
}

a.badge-warning-soft:hover,
a.badge-warning-soft:focus {
    color: #1f2d41;
    background-color: #e5cf9f;
}

a.badge-warning-soft:focus,
a.badge-warning-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(240, 227, 199, 0.5);
}

.badge-danger-soft {
    color: #1f2d41;
    background-color: #eec7c7;
}

a.badge-danger-soft:hover,
a.badge-danger-soft:focus {
    color: #1f2d41;
    background-color: #e2a0a0;
}

a.badge-danger-soft:focus,
a.badge-danger-soft.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(238, 199, 199, 0.5);
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e3e6ec;
    border-radius: 0.5rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 1.25rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 3px;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 600;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.25rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #172232;
    background-color: #d5d9df;
    border-color: #c4cad2;
}

.alert-primary hr {
    border-top-color: #b6bdc7;
}

.alert-primary .alert-link {
    color: #070a0f;
}

.alert-secondary {
    color: #172232;
    background-color: #d5d9df;
    border-color: #c4cad3;
}

.alert-secondary hr {
    border-top-color: #b5bdc8;
}

.alert-secondary .alert-link {
    color: #070a0f;
}

.alert-success {
    color: #172233;
    background-color: #d5d9e0;
    border-color: #c4cad3;
}

.alert-success hr {
    border-top-color: #b5bdc8;
}

.alert-success .alert-link {
    color: #070b10;
}

.alert-info {
    color: #172235;
    background-color: #d5d9e0;
    border-color: #c4cad4;
}

.alert-info hr {
    border-top-color: #b5bdc9;
}

.alert-info .alert-link {
    color: #080b11;
}

.alert-warning {
    color: #172234;
    background-color: #d5d9e0;
    border-color: #c4cad4;
}

.alert-warning hr {
    border-top-color: #b5bdc9;
}

.alert-warning .alert-link {
    color: #070b11;
}

.alert-danger {
    color: #172233;
    background-color: #d5d9e0;
    border-color: #c4cad3;
}

.alert-danger hr {
    border-top-color: #b5bdc8;
}

.alert-danger .alert-link {
    color: #070b10;
}

.alert-light {
    color: #7c7e81;
    background-color: #fcfdfe;
    border-color: #fbfcfd;
}

.alert-light hr {
    border-top-color: #eaeff5;
}

.alert-light .alert-link {
    color: #636567;
}

.alert-dark {
    color: #101722;
    background-color: #d2d5d9;
    border-color: #c0c4ca;
}

.alert-dark hr {
    border-top-color: #b2b7be;
}

.alert-dark .alert-link {
    color: black;
}

.alert-black {
    color: black;
    background-color: #cccccc;
    border-color: #b8b8b8;
}

.alert-black hr {
    border-top-color: #ababab;
}

.alert-black .alert-link {
    color: black;
}

.alert-white {
    color: #858585;
    background-color: white;
    border-color: white;
}

.alert-white hr {
    border-top-color: #f2f2f2;
}

.alert-white .alert-link {
    color: #6c6c6c;
}

.alert-red {
    color: #790b00;
    background-color: #fad0cc;
    border-color: #f9bdb8;
}

.alert-red hr {
    border-top-color: #f7a7a0;
}

.alert-red .alert-link {
    color: #460600;
}

.alert-orange {
    color: #803400;
    background-color: #fde0cc;
    border-color: #fdd4b8;
}

.alert-orange hr {
    border-top-color: #fcc59f;
}

.alert-orange .alert-link {
    color: #4d1f00;
}

.alert-yellow {
    color: #7f5400;
    background-color: #fdeccc;
    border-color: #fce5b8;
}

.alert-yellow hr {
    border-top-color: #fbdca0;
}

.alert-yellow .alert-link {
    color: #4c3200;
}

.alert-green {
    color: #005937;
    background-color: #cceee1;
    border-color: #b8e8d5;
}

.alert-green hr {
    border-top-color: #a5e2ca;
}

.alert-green .alert-link {
    color: #002617;
}

.alert-teal {
    color: #00614d;
    background-color: #ccf1ea;
    border-color: #b8ece1;
}

.alert-teal hr {
    border-top-color: #a4e7d9;
}

.alert-teal .alert-link {
    color: #002e25;
}

.alert-cyan {
    color: #006c6f;
    background-color: #ccf5f7;
    border-color: #b8f2f3;
}

.alert-cyan hr {
    border-top-color: #a2eeef;
}

.alert-cyan .alert-link {
    color: #003a3c;
}

.alert-blue {
    color: #00327e;
    background-color: #ccdffc;
    border-color: #b8d3fb;
}

.alert-blue hr {
    border-top-color: #a0c4fa;
}

.alert-blue .alert-link {
    color: #001e4b;
}

.alert-indigo {
    color: #2e0079;
    background-color: #deccfa;
    border-color: #d0b8f9;
}

.alert-indigo hr {
    border-top-color: #c0a0f7;
}

.alert-indigo .alert-link {
    color: #1b0046;
}

.alert-purple {
    color: #370067;
    background-color: #e1ccf4;
    border-color: #d5b8ef;
}

.alert-purple hr {
    border-top-color: #c9a3ea;
}

.alert-purple .alert-link {
    color: #1c0034;
}

.alert-pink {
    color: #76002e;
    background-color: #f9ccde;
    border-color: #f7b8d1;
}

.alert-pink hr {
    border-top-color: #f4a1c2;
}

.alert-pink .alert-link {
    color: #43001a;
}

.alert-red-soft {
    color: #7c6767;
    background-color: #fcf4f4;
    border-color: #faefef;
}

.alert-red-soft hr {
    border-top-color: #f4dcdc;
}

.alert-red-soft .alert-link {
    color: #605050;
}

.alert-orange-soft {
    color: #7d6f67;
    background-color: #fcf7f4;
    border-color: #fbf4ef;
}

.alert-orange-soft hr {
    border-top-color: #f6e6db;
}

.alert-orange-soft .alert-link {
    color: #615650;
}

.alert-yellow-soft {
    color: #7d7667;
    background-color: #fcf9f4;
    border-color: #fbf7ef;
}

.alert-yellow-soft hr {
    border-top-color: #f6eddb;
}

.alert-yellow-soft .alert-link {
    color: #615c50;
}

.alert-green-soft {
    color: #637772;
    background-color: #f2faf8;
    border-color: #edf8f5;
}

.alert-green-soft hr {
    border-top-color: #dbf1eb;
}

.alert-green-soft .alert-link {
    color: #4c5b57;
}

.alert-teal-soft {
    color: #637977;
    background-color: #f2fafa;
    border-color: #edf9f8;
}

.alert-teal-soft hr {
    border-top-color: #daf3f1;
}

.alert-teal-soft .alert-link {
    color: #4c5d5b;
}

.alert-cyan-soft {
    color: #637b7e;
    background-color: #f2fbfc;
    border-color: #edfafb;
}

.alert-cyan-soft hr {
    border-top-color: #d8f4f6;
}

.alert-cyan-soft .alert-link {
    color: #4d5f61;
}

.alert-blue-soft {
    color: #636f81;
    background-color: #f2f7fe;
    border-color: #edf4fd;
}

.alert-blue-soft hr {
    border-top-color: #d6e6fa;
}

.alert-blue-soft .alert-link {
    color: #4d5664;
}

.alert-indigo-soft {
    color: #6d6580;
    background-color: #f6f3fd;
    border-color: #f2eefc;
}

.alert-indigo-soft hr {
    border-top-color: #e1d8f8;
}

.alert-indigo-soft .alert-link {
    color: #554f63;
}

.alert-purple-soft {
    color: #6e657c;
    background-color: #f6f3fc;
    border-color: #f3eefb;
}

.alert-purple-soft hr {
    border-top-color: #e4d9f6;
}

.alert-purple-soft .alert-link {
    color: #554e60;
}

.alert-pink-soft {
    color: #7b6571;
    background-color: #fbf3f7;
    border-color: #faeef4;
}

.alert-pink-soft hr {
    border-top-color: #f4dae7;
}

.alert-pink-soft .alert-link {
    color: #5f4e57;
}

.alert-primary-soft {
    color: #636f81;
    background-color: #f2f7fe;
    border-color: #edf4fd;
}

.alert-primary-soft hr {
    border-top-color: #d6e6fa;
}

.alert-primary-soft .alert-link {
    color: #4d5664;
}

.alert-secondary-soft {
    color: #6e657c;
    background-color: #f6f3fc;
    border-color: #f3eefb;
}

.alert-secondary-soft hr {
    border-top-color: #e4d9f6;
}

.alert-secondary-soft .alert-link {
    color: #554e60;
}

.alert-success-soft {
    color: #637772;
    background-color: #f2faf8;
    border-color: #edf8f5;
}

.alert-success-soft hr {
    border-top-color: #dbf1eb;
}

.alert-success-soft .alert-link {
    color: #4c5b57;
}

.alert-info-soft {
    color: #637b7e;
    background-color: #f2fbfc;
    border-color: #edfafb;
}

.alert-info-soft hr {
    border-top-color: #d8f4f6;
}

.alert-info-soft .alert-link {
    color: #4d5f61;
}

.alert-warning-soft {
    color: #7d7667;
    background-color: #fcf9f4;
    border-color: #fbf7ef;
}

.alert-warning-soft hr {
    border-top-color: #f6eddb;
}

.alert-warning-soft .alert-link {
    color: #615c50;
}

.alert-danger-soft {
    color: #7c6767;
    background-color: #fcf4f4;
    border-color: #faefef;
}

.alert-danger-soft hr {
    border-top-color: #f4dcdc;
}

.alert-danger-soft .alert-link {
    color: #605050;
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e3e6ec;
    border-radius: 3px;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #17449e;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #485260;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #485260;
    text-decoration: none;
    background-color: #eff3f9;
}

.list-group-item-action:active {
    color: #687281;
    background-color: #e3e6ec;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #687281;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #17449e;
    border-color: #17449e;
}

.list-group-item+.list-group-item {
    border-top-width: 0;
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.list-group-horizontal .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }

    .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-sm .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-sm .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }

    .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-md .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-md .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-md .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }

    .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-lg .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-lg .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }

    .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xl .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-xl .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #172232;
    background-color: #c4cad2;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #172232;
    background-color: #b6bdc7;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #172232;
    border-color: #172232;
}

.list-group-item-secondary {
    color: #172232;
    background-color: #c4cad3;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #172232;
    background-color: #b5bdc8;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #172232;
    border-color: #172232;
}

.list-group-item-success {
    color: #172233;
    background-color: #c4cad3;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #172233;
    background-color: #b5bdc8;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #172233;
    border-color: #172233;
}

.list-group-item-info {
    color: #172235;
    background-color: #c4cad4;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #172235;
    background-color: #b5bdc9;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #172235;
    border-color: #172235;
}

.list-group-item-warning {
    color: #172234;
    background-color: #c4cad4;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #172234;
    background-color: #b5bdc9;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #172234;
    border-color: #172234;
}

.list-group-item-danger {
    color: #172233;
    background-color: #c4cad3;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #172233;
    background-color: #b5bdc8;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #172233;
    border-color: #172233;
}

.list-group-item-light {
    color: #7c7e81;
    background-color: #fbfcfd;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #7c7e81;
    background-color: #eaeff5;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7c7e81;
    border-color: #7c7e81;
}

.list-group-item-dark {
    color: #101722;
    background-color: #c0c4ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #101722;
    background-color: #b2b7be;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #101722;
    border-color: #101722;
}

.list-group-item-black {
    color: black;
    background-color: #b8b8b8;
}

.list-group-item-black.list-group-item-action:hover,
.list-group-item-black.list-group-item-action:focus {
    color: black;
    background-color: #ababab;
}

.list-group-item-black.list-group-item-action.active {
    color: #fff;
    background-color: black;
    border-color: black;
}

.list-group-item-white {
    color: #858585;
    background-color: white;
}

.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585;
}

.list-group-item-red {
    color: #790b00;
    background-color: #f9bdb8;
}

.list-group-item-red.list-group-item-action:hover,
.list-group-item-red.list-group-item-action:focus {
    color: #790b00;
    background-color: #f7a7a0;
}

.list-group-item-red.list-group-item-action.active {
    color: #fff;
    background-color: #790b00;
    border-color: #790b00;
}

.list-group-item-orange {
    color: #803400;
    background-color: #fdd4b8;
}

.list-group-item-orange.list-group-item-action:hover,
.list-group-item-orange.list-group-item-action:focus {
    color: #803400;
    background-color: #fcc59f;
}

.list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #803400;
    border-color: #803400;
}

.list-group-item-yellow {
    color: #7f5400;
    background-color: #fce5b8;
}

.list-group-item-yellow.list-group-item-action:hover,
.list-group-item-yellow.list-group-item-action:focus {
    color: #7f5400;
    background-color: #fbdca0;
}

.list-group-item-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #7f5400;
    border-color: #7f5400;
}

.list-group-item-green {
    color: #005937;
    background-color: #b8e8d5;
}

.list-group-item-green.list-group-item-action:hover,
.list-group-item-green.list-group-item-action:focus {
    color: #005937;
    background-color: #a5e2ca;
}

.list-group-item-green.list-group-item-action.active {
    color: #fff;
    background-color: #005937;
    border-color: #005937;
}

.list-group-item-teal {
    color: #00614d;
    background-color: #b8ece1;
}

.list-group-item-teal.list-group-item-action:hover,
.list-group-item-teal.list-group-item-action:focus {
    color: #00614d;
    background-color: #a4e7d9;
}

.list-group-item-teal.list-group-item-action.active {
    color: #fff;
    background-color: #00614d;
    border-color: #00614d;
}

.list-group-item-cyan {
    color: #006c6f;
    background-color: #b8f2f3;
}

.list-group-item-cyan.list-group-item-action:hover,
.list-group-item-cyan.list-group-item-action:focus {
    color: #006c6f;
    background-color: #a2eeef;
}

.list-group-item-cyan.list-group-item-action.active {
    color: #fff;
    background-color: #006c6f;
    border-color: #006c6f;
}

.list-group-item-blue {
    color: #00327e;
    background-color: #b8d3fb;
}

.list-group-item-blue.list-group-item-action:hover,
.list-group-item-blue.list-group-item-action:focus {
    color: #00327e;
    background-color: #a0c4fa;
}

.list-group-item-blue.list-group-item-action.active {
    color: #fff;
    background-color: #00327e;
    border-color: #00327e;
}

.list-group-item-indigo {
    color: #2e0079;
    background-color: #d0b8f9;
}

.list-group-item-indigo.list-group-item-action:hover,
.list-group-item-indigo.list-group-item-action:focus {
    color: #2e0079;
    background-color: #c0a0f7;
}

.list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #2e0079;
    border-color: #2e0079;
}

.list-group-item-purple {
    color: #370067;
    background-color: #d5b8ef;
}

.list-group-item-purple.list-group-item-action:hover,
.list-group-item-purple.list-group-item-action:focus {
    color: #370067;
    background-color: #c9a3ea;
}

.list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #370067;
    border-color: #370067;
}

.list-group-item-pink {
    color: #76002e;
    background-color: #f7b8d1;
}

.list-group-item-pink.list-group-item-action:hover,
.list-group-item-pink.list-group-item-action:focus {
    color: #76002e;
    background-color: #f4a1c2;
}

.list-group-item-pink.list-group-item-action.active {
    color: #fff;
    background-color: #76002e;
    border-color: #76002e;
}

.list-group-item-red-soft {
    color: #7c6767;
    background-color: #faefef;
}

.list-group-item-red-soft.list-group-item-action:hover,
.list-group-item-red-soft.list-group-item-action:focus {
    color: #7c6767;
    background-color: #f4dcdc;
}

.list-group-item-red-soft.list-group-item-action.active {
    color: #fff;
    background-color: #7c6767;
    border-color: #7c6767;
}

.list-group-item-orange-soft {
    color: #7d6f67;
    background-color: #fbf4ef;
}

.list-group-item-orange-soft.list-group-item-action:hover,
.list-group-item-orange-soft.list-group-item-action:focus {
    color: #7d6f67;
    background-color: #f6e6db;
}

.list-group-item-orange-soft.list-group-item-action.active {
    color: #fff;
    background-color: #7d6f67;
    border-color: #7d6f67;
}

.list-group-item-yellow-soft {
    color: #7d7667;
    background-color: #fbf7ef;
}

.list-group-item-yellow-soft.list-group-item-action:hover,
.list-group-item-yellow-soft.list-group-item-action:focus {
    color: #7d7667;
    background-color: #f6eddb;
}

.list-group-item-yellow-soft.list-group-item-action.active {
    color: #fff;
    background-color: #7d7667;
    border-color: #7d7667;
}

.list-group-item-green-soft {
    color: #637772;
    background-color: #edf8f5;
}

.list-group-item-green-soft.list-group-item-action:hover,
.list-group-item-green-soft.list-group-item-action:focus {
    color: #637772;
    background-color: #dbf1eb;
}

.list-group-item-green-soft.list-group-item-action.active {
    color: #fff;
    background-color: #637772;
    border-color: #637772;
}

.list-group-item-teal-soft {
    color: #637977;
    background-color: #edf9f8;
}

.list-group-item-teal-soft.list-group-item-action:hover,
.list-group-item-teal-soft.list-group-item-action:focus {
    color: #637977;
    background-color: #daf3f1;
}

.list-group-item-teal-soft.list-group-item-action.active {
    color: #fff;
    background-color: #637977;
    border-color: #637977;
}

.list-group-item-cyan-soft {
    color: #637b7e;
    background-color: #edfafb;
}

.list-group-item-cyan-soft.list-group-item-action:hover,
.list-group-item-cyan-soft.list-group-item-action:focus {
    color: #637b7e;
    background-color: #d8f4f6;
}

.list-group-item-cyan-soft.list-group-item-action.active {
    color: #fff;
    background-color: #637b7e;
    border-color: #637b7e;
}

.list-group-item-blue-soft {
    color: #636f81;
    background-color: #edf4fd;
}

.list-group-item-blue-soft.list-group-item-action:hover,
.list-group-item-blue-soft.list-group-item-action:focus {
    color: #636f81;
    background-color: #d6e6fa;
}

.list-group-item-blue-soft.list-group-item-action.active {
    color: #fff;
    background-color: #636f81;
    border-color: #636f81;
}

.list-group-item-indigo-soft {
    color: #6d6580;
    background-color: #f2eefc;
}

.list-group-item-indigo-soft.list-group-item-action:hover,
.list-group-item-indigo-soft.list-group-item-action:focus {
    color: #6d6580;
    background-color: #e1d8f8;
}

.list-group-item-indigo-soft.list-group-item-action.active {
    color: #fff;
    background-color: #6d6580;
    border-color: #6d6580;
}

.list-group-item-purple-soft {
    color: #6e657c;
    background-color: #f3eefb;
}

.list-group-item-purple-soft.list-group-item-action:hover,
.list-group-item-purple-soft.list-group-item-action:focus {
    color: #6e657c;
    background-color: #e4d9f6;
}

.list-group-item-purple-soft.list-group-item-action.active {
    color: #fff;
    background-color: #6e657c;
    border-color: #6e657c;
}

.list-group-item-pink-soft {
    color: #7b6571;
    background-color: #faeef4;
}

.list-group-item-pink-soft.list-group-item-action:hover,
.list-group-item-pink-soft.list-group-item-action:focus {
    color: #7b6571;
    background-color: #f4dae7;
}

.list-group-item-pink-soft.list-group-item-action.active {
    color: #fff;
    background-color: #7b6571;
    border-color: #7b6571;
}

.list-group-item-primary-soft {
    color: #636f81;
    background-color: #edf4fd;
}

.list-group-item-primary-soft.list-group-item-action:hover,
.list-group-item-primary-soft.list-group-item-action:focus {
    color: #636f81;
    background-color: #d6e6fa;
}

.list-group-item-primary-soft.list-group-item-action.active {
    color: #fff;
    background-color: #636f81;
    border-color: #636f81;
}

.list-group-item-secondary-soft {
    color: #6e657c;
    background-color: #f3eefb;
}

.list-group-item-secondary-soft.list-group-item-action:hover,
.list-group-item-secondary-soft.list-group-item-action:focus {
    color: #6e657c;
    background-color: #e4d9f6;
}

.list-group-item-secondary-soft.list-group-item-action.active {
    color: #fff;
    background-color: #6e657c;
    border-color: #6e657c;
}

.list-group-item-success-soft {
    color: #637772;
    background-color: #edf8f5;
}

.list-group-item-success-soft.list-group-item-action:hover,
.list-group-item-success-soft.list-group-item-action:focus {
    color: #637772;
    background-color: #dbf1eb;
}

.list-group-item-success-soft.list-group-item-action.active {
    color: #fff;
    background-color: #637772;
    border-color: #637772;
}

.list-group-item-info-soft {
    color: #637b7e;
    background-color: #edfafb;
}

.list-group-item-info-soft.list-group-item-action:hover,
.list-group-item-info-soft.list-group-item-action:focus {
    color: #637b7e;
    background-color: #d8f4f6;
}

.list-group-item-info-soft.list-group-item-action.active {
    color: #fff;
    background-color: #637b7e;
    border-color: #637b7e;
}

.list-group-item-warning-soft {
    color: #7d7667;
    background-color: #fbf7ef;
}

.list-group-item-warning-soft.list-group-item-action:hover,
.list-group-item-warning-soft.list-group-item-action:focus {
    color: #7d7667;
    background-color: #f6eddb;
}

.list-group-item-warning-soft.list-group-item-action.active {
    color: #fff;
    background-color: #7d7667;
    border-color: #7d7667;
}

.list-group-item-danger-soft {
    color: #7c6767;
    background-color: #faefef;
}

.list-group-item-danger-soft.list-group-item-action:hover,
.list-group-item-danger-soft.list-group-item-action:focus {
    color: #7c6767;
    background-color: #f4dcdc;
}

.list-group-item-danger-soft.list-group-item-action.active {
    color: #fff;
    background-color: #7c6767;
    border-color: #7c6767;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: .75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
}

a.close.disabled {
    pointer-events: none;
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #687281;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
    padding: 0.75rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #e3e6ec;
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #e3e6ec;
    border-bottom-right-radius: calc(0.5rem - 1px);
    border-bottom-left-radius: calc(0.5rem - 1px);
}

.modal-footer>* {
    margin: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 3px;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.5rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^="top"]>.arrow {
    bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^="top"]>.arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.5rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
    top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^="left"]>.arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.5rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^="left"]>.arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #323f52;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #687281;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-control-prev,
    .carousel-control-next {
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #17449e !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #1c2a3d !important;
}

.bg-secondary {
    background-color: #5e5e5e !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #1c2a3e !important;
}

.bg-success {
    background-color: #00ac69 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #1c2a3f !important;
}

.bg-info {
    background-color: #00cfd5 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #1d2b41 !important;
}

.bg-warning {
    background-color: #f4a100 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #1c2b41 !important;
}

.bg-danger {
    background-color: #e81500 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #1c2a40 !important;
}

.bg-light {
    background-color: #eff3f9 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #cad7eb !important;
}

.bg-dark {
    background-color: #1f2d41 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #0f151e !important;
}

.bg-black {
    background-color: #f5f5f5 !important;
}

a.bg-black:hover,
a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
    background-color: black !important;
}

.bg-white {
    background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
    background-color: #e6e6e6 !important;
}

.bg-red {
    background-color: #e81500 !important;
}

a.bg-red:hover,
a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
    background-color: #b51000 !important;
}

.bg-orange {
    background-color: #f76400 !important;
}

a.bg-orange:hover,
a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
    background-color: #c44f00 !important;
}

.bg-yellow {
    background-color: #f4a100 !important;
}

a.bg-yellow:hover,
a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
    background-color: #c17f00 !important;
}

.bg-green {
    background-color: #00ac69 !important;
}

a.bg-green:hover,
a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
    background-color: #00794a !important;
}

.bg-teal {
    background-color: #00ba94 !important;
}

a.bg-teal:hover,
a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
    background-color: #00876b !important;
}

.bg-cyan {
    background-color: #00cfd5 !important;
}

a.bg-cyan:hover,
a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
    background-color: #009da2 !important;
}

.bg-blue {
    background-color: #0061f2 !important;
}

a.bg-blue:hover,
a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
    background-color: #004dbf !important;
}

.bg-indigo {
    background-color: #5800e8 !important;
}

a.bg-indigo:hover,
a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
    background-color: #4500b5 !important;
}

.bg-purple {
    background-color: #6900c7 !important;
}

a.bg-purple:hover,
a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
    background-color: #4e0094 !important;
}

.bg-pink {
    background-color: #e30059 !important;
}

a.bg-pink:hover,
a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
    background-color: #b00045 !important;
}

.bg-red-soft {
    background-color: #eec7c7 !important;
}

a.bg-red-soft:hover,
a.bg-red-soft:focus,
button.bg-red-soft:hover,
button.bg-red-soft:focus {
    background-color: #e2a0a0 !important;
}

.bg-orange-soft {
    background-color: #f1d6c7 !important;
}

a.bg-orange-soft:hover,
a.bg-orange-soft:focus,
button.bg-orange-soft:hover,
button.bg-orange-soft:focus {
    background-color: #e7b89e !important;
}

.bg-yellow-soft {
    background-color: #f0e3c7 !important;
}

a.bg-yellow-soft:hover,
a.bg-yellow-soft:focus,
button.bg-yellow-soft:hover,
button.bg-yellow-soft:focus {
    background-color: #e5cf9f !important;
}

.bg-green-soft {
    background-color: #bfe5dc !important;
}

a.bg-green-soft:hover,
a.bg-green-soft:focus,
button.bg-green-soft:hover,
button.bg-green-soft:focus {
    background-color: #9bd6c8 !important;
}

.bg-teal-soft {
    background-color: #bfe8e5 !important;
}

a.bg-teal-soft:hover,
a.bg-teal-soft:focus,
button.bg-teal-soft:hover,
button.bg-teal-soft:focus {
    background-color: #99dbd6 !important;
}

.bg-cyan-soft {
    background-color: #bfecf2 !important;
}

a.bg-cyan-soft:hover,
a.bg-cyan-soft:focus,
button.bg-cyan-soft:hover,
button.bg-cyan-soft:focus {
    background-color: #95dfe9 !important;
}

.bg-blue-soft {
    background-color: #4d93e9 !important;
}

a.bg-blue-soft:hover,
a.bg-blue-soft:focus,
button.bg-blue-soft:hover,
button.bg-blue-soft:focus {
    background-color: #91b9f3 !important;
}

.bg-indigo-soft {
    background-color: #d1c2f6 !important;
}

a.bg-indigo-soft:hover,
a.bg-indigo-soft:focus,
button.bg-indigo-soft:hover,
button.bg-indigo-soft:focus {
    background-color: #af96ef !important;
}

.bg-purple-soft {
    background-color: #d4c2ef !important;
}

a.bg-purple-soft:hover,
a.bg-purple-soft:focus,
button.bg-purple-soft:hover,
button.bg-purple-soft:focus {
    background-color: #b89ae4 !important;
}

.bg-pink-soft {
    background-color: #edc2d9 !important;
}

a.bg-pink-soft:hover,
a.bg-pink-soft:focus,
button.bg-pink-soft:hover,
button.bg-pink-soft:focus {
    background-color: #e19bc0 !important;
}

.bg-primary-soft {
    background-color: #4d93e9 !important;
}

a.bg-primary-soft:hover,
a.bg-primary-soft:focus,
button.bg-primary-soft:hover,
button.bg-primary-soft:focus {
    background-color: #91b9f3 !important;
}

.bg-secondary-soft {
    background-color: #d4c2ef !important;
}

a.bg-secondary-soft:hover,
a.bg-secondary-soft:focus,
button.bg-secondary-soft:hover,
button.bg-secondary-soft:focus {
    background-color: #b89ae4 !important;
}

.bg-success-soft {
    background-color: #bfe5dc !important;
}

a.bg-success-soft:hover,
a.bg-success-soft:focus,
button.bg-success-soft:hover,
button.bg-success-soft:focus {
    background-color: #9bd6c8 !important;
}

.bg-info-soft {
    background-color: #bfecf2 !important;
}

a.bg-info-soft:hover,
a.bg-info-soft:focus,
button.bg-info-soft:hover,
button.bg-info-soft:focus {
    background-color: #95dfe9 !important;
}

.bg-warning-soft {
    background-color: #f0e3c7 !important;
}

a.bg-warning-soft:hover,
a.bg-warning-soft:focus,
button.bg-warning-soft:hover,
button.bg-warning-soft:focus {
    background-color: #e5cf9f !important;
}

.bg-danger-soft {
    background-color: #eec7c7 !important;
}

a.bg-danger-soft:hover,
a.bg-danger-soft:focus,
button.bg-danger-soft:hover,
button.bg-danger-soft:focus {
    background-color: #e2a0a0 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #e3e6ec !important;
}

.border-top {
    border-top: 1px solid #e3e6ec !important;
}

.border-right {
    border-right: 1px solid #e3e6ec !important;
}

.border-bottom {
    border-bottom: 1px solid #e3e6ec !important;
}

.border-left {
    border-left: 1px solid #e3e6ec !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #17449e !important;
}

.border-secondary {
    border-color: #5e5e5e !important;
}

.border-success {
    border-color: #00ac69 !important;
}

.border-info {
    border-color: #00cfd5 !important;
}

.border-warning {
    border-color: #f4a100 !important;
}

.border-danger {
    border-color: #e81500 !important;
}

.border-light {
    border-color: #eff3f9 !important;
}

.border-dark {
    border-color: #1f2d41 !important;
}

.border-black {
    border-color: #000 !important;
}

.border-white {
    border-color: #fff !important;
}

.border-red {
    border-color: #e81500 !important;
}

.border-orange {
    border-color: #f76400 !important;
}

.border-yellow {
    border-color: #f4a100 !important;
}

.border-green {
    border-color: #00ac69 !important;
}

.border-teal {
    border-color: #00ba94 !important;
}

.border-cyan {
    border-color: #00cfd5 !important;
}

.border-blue {
    border-color: #0061f2 !important;
}

.border-indigo {
    border-color: #5800e8 !important;
}

.border-purple {
    border-color: #6900c7 !important;
}

.border-pink {
    border-color: #e30059 !important;
}

.border-red-soft {
    border-color: #eec7c7 !important;
}

.border-orange-soft {
    border-color: #f1d6c7 !important;
}

.border-yellow-soft {
    border-color: #f0e3c7 !important;
}

.border-green-soft {
    border-color: #bfe5dc !important;
}

.border-teal-soft {
    border-color: #bfe8e5 !important;
}

.border-cyan-soft {
    border-color: #bfecf2 !important;
}

.border-blue-soft {
    border-color: #4d93e9 !important;
}

.border-indigo-soft {
    border-color: #d1c2f6 !important;
}

.border-purple-soft {
    border-color: #d4c2ef !important;
}

.border-pink-soft {
    border-color: #edc2d9 !important;
}

.border-primary-soft {
    border-color: #4d93e9 !important;
}

.border-secondary-soft {
    border-color: #d4c2ef !important;
}

.border-success-soft {
    border-color: #bfe5dc !important;
}

.border-info-soft {
    border-color: #bfecf2 !important;
}

.border-warning-soft {
    border-color: #f0e3c7 !important;
}

.border-danger-soft {
    border-color: #eec7c7 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 2px !important;
}

.rounded {
    border-radius: 3px !important;
}

.rounded-top {
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.rounded-right {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.rounded-left {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.rounded-lg {
    border-radius: 0.5rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: flex !important;
    }

    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .justify-content-sm-between {
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        align-items: center !important;
    }

    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    .align-content-sm-start {
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        align-content: center !important;
    }

    .align-content-sm-between {
        align-content: space-between !important;
    }

    .align-content-sm-around {
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        align-self: auto !important;
    }

    .align-self-sm-start {
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        align-self: center !important;
    }

    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    .justify-content-md-between {
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        justify-content: space-around !important;
    }

    .align-items-md-start {
        align-items: flex-start !important;
    }

    .align-items-md-end {
        align-items: flex-end !important;
    }

    .align-items-md-center {
        align-items: center !important;
    }

    .align-items-md-baseline {
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        align-items: stretch !important;
    }

    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    .align-content-md-between {
        align-content: space-between !important;
    }

    .align-content-md-around {
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        align-content: stretch !important;
    }

    .align-self-md-auto {
        align-self: auto !important;
    }

    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    .align-self-md-center {
        align-self: center !important;
    }

    .align-self-md-baseline {
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }

    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        justify-content: center !important;
    }

    .justify-content-lg-between {
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        align-items: center !important;
    }

    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    .align-content-lg-start {
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    .align-content-lg-between {
        align-content: space-between !important;
    }

    .align-content-lg-around {
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        align-self: auto !important;
    }

    .align-self-lg-start {
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        align-self: center !important;
    }

    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }

    .flex-xl-column {
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        justify-content: center !important;
    }

    .justify-content-xl-between {
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        align-items: center !important;
    }

    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    .align-content-xl-start {
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        align-content: center !important;
    }

    .align-content-xl-between {
        align-content: space-between !important;
    }

    .align-content-xl-around {
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        align-self: auto !important;
    }

    .align-self-xl-start {
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        align-self: center !important;
    }

    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.fixed-top,
.page-header.navbar-fixed .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: sticky) {

    .sticky-top,
    .nav-sticky {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(31, 45, 65, 0.2) !important;
}

.shadow {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.m-10 {
    margin: 6rem !important;
}

.mt-10,
.my-10 {
    margin-top: 6rem !important;
}

.mr-10,
.mx-10 {
    margin-right: 6rem !important;
}

.mb-10,
.my-10 {
    margin-bottom: 6rem !important;
}

.ml-10,
.mx-10 {
    margin-left: 6rem !important;
}

.m-15 {
    margin: 9rem !important;
}

.mt-15,
.my-15 {
    margin-top: 9rem !important;
}

.mr-15,
.mx-15 {
    margin-right: 9rem !important;
}

.mb-15,
.my-15 {
    margin-bottom: 9rem !important;
}

.ml-15,
.mx-15 {
    margin-left: 9rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-n10 {
    margin: -6rem !important;
}

.mt-n10,
.my-n10 {
    margin-top: -6rem !important;
}

.mr-n10,
.mx-n10 {
    margin-right: -6rem !important;
}

.mb-n10,
.my-n10 {
    margin-bottom: -6rem !important;
}

.ml-n10,
.mx-n10 {
    margin-left: -6rem !important;
}

.m-n15 {
    margin: -9rem !important;
}

.mt-n15,
.my-n15 {
    margin-top: -9rem !important;
}

.mr-n15,
.mx-n15 {
    margin-right: -9rem !important;
}

.mb-n15,
.my-n15 {
    margin-bottom: -9rem !important;
}

.ml-n15,
.mx-n15 {
    margin-left: -9rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.p-10 {
    padding: 6rem !important;
}

.pt-10,
.py-10 {
    padding-top: 6rem !important;
}

.pr-10,
.px-10 {
    padding-right: 6rem !important;
}

.pb-10,
.py-10 {
    padding-bottom: 6rem !important;
}

.pl-10,
.px-10 {
    padding-left: 6rem !important;
}

.p-15 {
    padding: 9rem !important;
}

.pt-15,
.py-15 {
    padding-top: 9rem !important;
}

.pr-15,
.px-15 {
    padding-right: 9rem !important;
}

.pb-15,
.py-15 {
    padding-bottom: 9rem !important;
}

.pl-15,
.px-15 {
    padding-left: 9rem !important;
}

.p-n1 {
    padding: -0.25rem !important;
}

.pt-n1,
.py-n1 {
    padding-top: -0.25rem !important;
}

.pr-n1,
.px-n1 {
    padding-right: -0.25rem !important;
}

.pb-n1,
.py-n1 {
    padding-bottom: -0.25rem !important;
}

.pl-n1,
.px-n1 {
    padding-left: -0.25rem !important;
}

.p-n2 {
    padding: -0.5rem !important;
}

.pt-n2,
.py-n2 {
    padding-top: -0.5rem !important;
}

.pr-n2,
.px-n2 {
    padding-right: -0.5rem !important;
}

.pb-n2,
.py-n2 {
    padding-bottom: -0.5rem !important;
}

.pl-n2,
.px-n2 {
    padding-left: -0.5rem !important;
}

.p-n3 {
    padding: -1rem !important;
}

.pt-n3,
.py-n3 {
    padding-top: -1rem !important;
}

.pr-n3,
.px-n3 {
    padding-right: -1rem !important;
}

.pb-n3,
.py-n3 {
    padding-bottom: -1rem !important;
}

.pl-n3,
.px-n3 {
    padding-left: -1rem !important;
}

.p-n4 {
    padding: -1.5rem !important;
}

.pt-n4,
.py-n4 {
    padding-top: -1.5rem !important;
}

.pr-n4,
.px-n4 {
    padding-right: -1.5rem !important;
}

.pb-n4,
.py-n4 {
    padding-bottom: -1.5rem !important;
}

.pl-n4,
.px-n4 {
    padding-left: -1.5rem !important;
}

.p-n5 {
    padding: -3rem !important;
}

.pt-n5,
.py-n5 {
    padding-top: -3rem !important;
}

.pr-n5,
.px-n5 {
    padding-right: -3rem !important;
}

.pb-n5,
.py-n5 {
    padding-bottom: -3rem !important;
}

.pl-n5,
.px-n5 {
    padding-left: -3rem !important;
}

.p-n10 {
    padding: -6rem !important;
}

.pt-n10,
.py-n10 {
    padding-top: -6rem !important;
}

.pr-n10,
.px-n10 {
    padding-right: -6rem !important;
}

.pb-n10,
.py-n10 {
    padding-bottom: -6rem !important;
}

.pl-n10,
.px-n10 {
    padding-left: -6rem !important;
}

.p-n15 {
    padding: -9rem !important;
}

.pt-n15,
.py-n15 {
    padding-top: -9rem !important;
}

.pr-n15,
.px-n15 {
    padding-right: -9rem !important;
}

.pb-n15,
.py-n15 {
    padding-bottom: -9rem !important;
}

.pl-n15,
.px-n15 {
    padding-left: -9rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-n10 {
    margin: -6rem !important;
}

.mt-n10,
.my-n10 {
    margin-top: -6rem !important;
}

.mr-n10,
.mx-n10 {
    margin-right: -6rem !important;
}

.mb-n10,
.my-n10 {
    margin-bottom: -6rem !important;
}

.ml-n10,
.mx-n10 {
    margin-left: -6rem !important;
}

.m-n15 {
    margin: -9rem !important;
}

.mt-n15,
.my-n15 {
    margin-top: -9rem !important;
}

.mr-n15,
.mx-n15 {
    margin-right: -9rem !important;
}

.mb-n15,
.my-n15 {
    margin-bottom: -9rem !important;
}

.ml-n15,
.mx-n15 {
    margin-left: -9rem !important;
}

.m-nn1 {
    margin: --0.25rem !important;
}

.mt-nn1,
.my-nn1 {
    margin-top: --0.25rem !important;
}

.mr-nn1,
.mx-nn1 {
    margin-right: --0.25rem !important;
}

.mb-nn1,
.my-nn1 {
    margin-bottom: --0.25rem !important;
}

.ml-nn1,
.mx-nn1 {
    margin-left: --0.25rem !important;
}

.m-nn2 {
    margin: --0.5rem !important;
}

.mt-nn2,
.my-nn2 {
    margin-top: --0.5rem !important;
}

.mr-nn2,
.mx-nn2 {
    margin-right: --0.5rem !important;
}

.mb-nn2,
.my-nn2 {
    margin-bottom: --0.5rem !important;
}

.ml-nn2,
.mx-nn2 {
    margin-left: --0.5rem !important;
}

.m-nn3 {
    margin: --1rem !important;
}

.mt-nn3,
.my-nn3 {
    margin-top: --1rem !important;
}

.mr-nn3,
.mx-nn3 {
    margin-right: --1rem !important;
}

.mb-nn3,
.my-nn3 {
    margin-bottom: --1rem !important;
}

.ml-nn3,
.mx-nn3 {
    margin-left: --1rem !important;
}

.m-nn4 {
    margin: --1.5rem !important;
}

.mt-nn4,
.my-nn4 {
    margin-top: --1.5rem !important;
}

.mr-nn4,
.mx-nn4 {
    margin-right: --1.5rem !important;
}

.mb-nn4,
.my-nn4 {
    margin-bottom: --1.5rem !important;
}

.ml-nn4,
.mx-nn4 {
    margin-left: --1.5rem !important;
}

.m-nn5 {
    margin: --3rem !important;
}

.mt-nn5,
.my-nn5 {
    margin-top: --3rem !important;
}

.mr-nn5,
.mx-nn5 {
    margin-right: --3rem !important;
}

.mb-nn5,
.my-nn5 {
    margin-bottom: --3rem !important;
}

.ml-nn5,
.mx-nn5 {
    margin-left: --3rem !important;
}

.m-nn10 {
    margin: --6rem !important;
}

.mt-nn10,
.my-nn10 {
    margin-top: --6rem !important;
}

.mr-nn10,
.mx-nn10 {
    margin-right: --6rem !important;
}

.mb-nn10,
.my-nn10 {
    margin-bottom: --6rem !important;
}

.ml-nn10,
.mx-nn10 {
    margin-left: --6rem !important;
}

.m-nn15 {
    margin: 9rem !important;
}

.mt-nn15,
.my-nn15 {
    margin-top: 9rem !important;
}

.mr-nn15,
.mx-nn15 {
    margin-right: 9rem !important;
}

.mb-nn15,
.my-nn15 {
    margin-bottom: 9rem !important;
}

.ml-nn15,
.mx-nn15 {
    margin-left: 9rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .m-sm-10 {
        margin: 6rem !important;
    }

    .mt-sm-10,
    .my-sm-10 {
        margin-top: 6rem !important;
    }

    .mr-sm-10,
    .mx-sm-10 {
        margin-right: 6rem !important;
    }

    .mb-sm-10,
    .my-sm-10 {
        margin-bottom: 6rem !important;
    }

    .ml-sm-10,
    .mx-sm-10 {
        margin-left: 6rem !important;
    }

    .m-sm-15 {
        margin: 9rem !important;
    }

    .mt-sm-15,
    .my-sm-15 {
        margin-top: 9rem !important;
    }

    .mr-sm-15,
    .mx-sm-15 {
        margin-right: 9rem !important;
    }

    .mb-sm-15,
    .my-sm-15 {
        margin-bottom: 9rem !important;
    }

    .ml-sm-15,
    .mx-sm-15 {
        margin-left: 9rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-n10 {
        margin: -6rem !important;
    }

    .mt-sm-n10,
    .my-sm-n10 {
        margin-top: -6rem !important;
    }

    .mr-sm-n10,
    .mx-sm-n10 {
        margin-right: -6rem !important;
    }

    .mb-sm-n10,
    .my-sm-n10 {
        margin-bottom: -6rem !important;
    }

    .ml-sm-n10,
    .mx-sm-n10 {
        margin-left: -6rem !important;
    }

    .m-sm-n15 {
        margin: -9rem !important;
    }

    .mt-sm-n15,
    .my-sm-n15 {
        margin-top: -9rem !important;
    }

    .mr-sm-n15,
    .mx-sm-n15 {
        margin-right: -9rem !important;
    }

    .mb-sm-n15,
    .my-sm-n15 {
        margin-bottom: -9rem !important;
    }

    .ml-sm-n15,
    .mx-sm-n15 {
        margin-left: -9rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    .p-sm-10 {
        padding: 6rem !important;
    }

    .pt-sm-10,
    .py-sm-10 {
        padding-top: 6rem !important;
    }

    .pr-sm-10,
    .px-sm-10 {
        padding-right: 6rem !important;
    }

    .pb-sm-10,
    .py-sm-10 {
        padding-bottom: 6rem !important;
    }

    .pl-sm-10,
    .px-sm-10 {
        padding-left: 6rem !important;
    }

    .p-sm-15 {
        padding: 9rem !important;
    }

    .pt-sm-15,
    .py-sm-15 {
        padding-top: 9rem !important;
    }

    .pr-sm-15,
    .px-sm-15 {
        padding-right: 9rem !important;
    }

    .pb-sm-15,
    .py-sm-15 {
        padding-bottom: 9rem !important;
    }

    .pl-sm-15,
    .px-sm-15 {
        padding-left: 9rem !important;
    }

    .p-sm-n1 {
        padding: -0.25rem !important;
    }

    .pt-sm-n1,
    .py-sm-n1 {
        padding-top: -0.25rem !important;
    }

    .pr-sm-n1,
    .px-sm-n1 {
        padding-right: -0.25rem !important;
    }

    .pb-sm-n1,
    .py-sm-n1 {
        padding-bottom: -0.25rem !important;
    }

    .pl-sm-n1,
    .px-sm-n1 {
        padding-left: -0.25rem !important;
    }

    .p-sm-n2 {
        padding: -0.5rem !important;
    }

    .pt-sm-n2,
    .py-sm-n2 {
        padding-top: -0.5rem !important;
    }

    .pr-sm-n2,
    .px-sm-n2 {
        padding-right: -0.5rem !important;
    }

    .pb-sm-n2,
    .py-sm-n2 {
        padding-bottom: -0.5rem !important;
    }

    .pl-sm-n2,
    .px-sm-n2 {
        padding-left: -0.5rem !important;
    }

    .p-sm-n3 {
        padding: -1rem !important;
    }

    .pt-sm-n3,
    .py-sm-n3 {
        padding-top: -1rem !important;
    }

    .pr-sm-n3,
    .px-sm-n3 {
        padding-right: -1rem !important;
    }

    .pb-sm-n3,
    .py-sm-n3 {
        padding-bottom: -1rem !important;
    }

    .pl-sm-n3,
    .px-sm-n3 {
        padding-left: -1rem !important;
    }

    .p-sm-n4 {
        padding: -1.5rem !important;
    }

    .pt-sm-n4,
    .py-sm-n4 {
        padding-top: -1.5rem !important;
    }

    .pr-sm-n4,
    .px-sm-n4 {
        padding-right: -1.5rem !important;
    }

    .pb-sm-n4,
    .py-sm-n4 {
        padding-bottom: -1.5rem !important;
    }

    .pl-sm-n4,
    .px-sm-n4 {
        padding-left: -1.5rem !important;
    }

    .p-sm-n5 {
        padding: -3rem !important;
    }

    .pt-sm-n5,
    .py-sm-n5 {
        padding-top: -3rem !important;
    }

    .pr-sm-n5,
    .px-sm-n5 {
        padding-right: -3rem !important;
    }

    .pb-sm-n5,
    .py-sm-n5 {
        padding-bottom: -3rem !important;
    }

    .pl-sm-n5,
    .px-sm-n5 {
        padding-left: -3rem !important;
    }

    .p-sm-n10 {
        padding: -6rem !important;
    }

    .pt-sm-n10,
    .py-sm-n10 {
        padding-top: -6rem !important;
    }

    .pr-sm-n10,
    .px-sm-n10 {
        padding-right: -6rem !important;
    }

    .pb-sm-n10,
    .py-sm-n10 {
        padding-bottom: -6rem !important;
    }

    .pl-sm-n10,
    .px-sm-n10 {
        padding-left: -6rem !important;
    }

    .p-sm-n15 {
        padding: -9rem !important;
    }

    .pt-sm-n15,
    .py-sm-n15 {
        padding-top: -9rem !important;
    }

    .pr-sm-n15,
    .px-sm-n15 {
        padding-right: -9rem !important;
    }

    .pb-sm-n15,
    .py-sm-n15 {
        padding-bottom: -9rem !important;
    }

    .pl-sm-n15,
    .px-sm-n15 {
        padding-left: -9rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-n10 {
        margin: -6rem !important;
    }

    .mt-sm-n10,
    .my-sm-n10 {
        margin-top: -6rem !important;
    }

    .mr-sm-n10,
    .mx-sm-n10 {
        margin-right: -6rem !important;
    }

    .mb-sm-n10,
    .my-sm-n10 {
        margin-bottom: -6rem !important;
    }

    .ml-sm-n10,
    .mx-sm-n10 {
        margin-left: -6rem !important;
    }

    .m-sm-n15 {
        margin: -9rem !important;
    }

    .mt-sm-n15,
    .my-sm-n15 {
        margin-top: -9rem !important;
    }

    .mr-sm-n15,
    .mx-sm-n15 {
        margin-right: -9rem !important;
    }

    .mb-sm-n15,
    .my-sm-n15 {
        margin-bottom: -9rem !important;
    }

    .ml-sm-n15,
    .mx-sm-n15 {
        margin-left: -9rem !important;
    }

    .m-sm-nn1 {
        margin: --0.25rem !important;
    }

    .mt-sm-nn1,
    .my-sm-nn1 {
        margin-top: --0.25rem !important;
    }

    .mr-sm-nn1,
    .mx-sm-nn1 {
        margin-right: --0.25rem !important;
    }

    .mb-sm-nn1,
    .my-sm-nn1 {
        margin-bottom: --0.25rem !important;
    }

    .ml-sm-nn1,
    .mx-sm-nn1 {
        margin-left: --0.25rem !important;
    }

    .m-sm-nn2 {
        margin: --0.5rem !important;
    }

    .mt-sm-nn2,
    .my-sm-nn2 {
        margin-top: --0.5rem !important;
    }

    .mr-sm-nn2,
    .mx-sm-nn2 {
        margin-right: --0.5rem !important;
    }

    .mb-sm-nn2,
    .my-sm-nn2 {
        margin-bottom: --0.5rem !important;
    }

    .ml-sm-nn2,
    .mx-sm-nn2 {
        margin-left: --0.5rem !important;
    }

    .m-sm-nn3 {
        margin: --1rem !important;
    }

    .mt-sm-nn3,
    .my-sm-nn3 {
        margin-top: --1rem !important;
    }

    .mr-sm-nn3,
    .mx-sm-nn3 {
        margin-right: --1rem !important;
    }

    .mb-sm-nn3,
    .my-sm-nn3 {
        margin-bottom: --1rem !important;
    }

    .ml-sm-nn3,
    .mx-sm-nn3 {
        margin-left: --1rem !important;
    }

    .m-sm-nn4 {
        margin: --1.5rem !important;
    }

    .mt-sm-nn4,
    .my-sm-nn4 {
        margin-top: --1.5rem !important;
    }

    .mr-sm-nn4,
    .mx-sm-nn4 {
        margin-right: --1.5rem !important;
    }

    .mb-sm-nn4,
    .my-sm-nn4 {
        margin-bottom: --1.5rem !important;
    }

    .ml-sm-nn4,
    .mx-sm-nn4 {
        margin-left: --1.5rem !important;
    }

    .m-sm-nn5 {
        margin: --3rem !important;
    }

    .mt-sm-nn5,
    .my-sm-nn5 {
        margin-top: --3rem !important;
    }

    .mr-sm-nn5,
    .mx-sm-nn5 {
        margin-right: --3rem !important;
    }

    .mb-sm-nn5,
    .my-sm-nn5 {
        margin-bottom: --3rem !important;
    }

    .ml-sm-nn5,
    .mx-sm-nn5 {
        margin-left: --3rem !important;
    }

    .m-sm-nn10 {
        margin: --6rem !important;
    }

    .mt-sm-nn10,
    .my-sm-nn10 {
        margin-top: --6rem !important;
    }

    .mr-sm-nn10,
    .mx-sm-nn10 {
        margin-right: --6rem !important;
    }

    .mb-sm-nn10,
    .my-sm-nn10 {
        margin-bottom: --6rem !important;
    }

    .ml-sm-nn10,
    .mx-sm-nn10 {
        margin-left: --6rem !important;
    }

    .m-sm-nn15 {
        margin: 9rem !important;
    }

    .mt-sm-nn15,
    .my-sm-nn15 {
        margin-top: 9rem !important;
    }

    .mr-sm-nn15,
    .mx-sm-nn15 {
        margin-right: 9rem !important;
    }

    .mb-sm-nn15,
    .my-sm-nn15 {
        margin-bottom: 9rem !important;
    }

    .ml-sm-nn15,
    .mx-sm-nn15 {
        margin-left: 9rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .m-md-10 {
        margin: 6rem !important;
    }

    .mt-md-10,
    .my-md-10 {
        margin-top: 6rem !important;
    }

    .mr-md-10,
    .mx-md-10 {
        margin-right: 6rem !important;
    }

    .mb-md-10,
    .my-md-10 {
        margin-bottom: 6rem !important;
    }

    .ml-md-10,
    .mx-md-10 {
        margin-left: 6rem !important;
    }

    .m-md-15 {
        margin: 9rem !important;
    }

    .mt-md-15,
    .my-md-15 {
        margin-top: 9rem !important;
    }

    .mr-md-15,
    .mx-md-15 {
        margin-right: 9rem !important;
    }

    .mb-md-15,
    .my-md-15 {
        margin-bottom: 9rem !important;
    }

    .ml-md-15,
    .mx-md-15 {
        margin-left: 9rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-n10 {
        margin: -6rem !important;
    }

    .mt-md-n10,
    .my-md-n10 {
        margin-top: -6rem !important;
    }

    .mr-md-n10,
    .mx-md-n10 {
        margin-right: -6rem !important;
    }

    .mb-md-n10,
    .my-md-n10 {
        margin-bottom: -6rem !important;
    }

    .ml-md-n10,
    .mx-md-n10 {
        margin-left: -6rem !important;
    }

    .m-md-n15 {
        margin: -9rem !important;
    }

    .mt-md-n15,
    .my-md-n15 {
        margin-top: -9rem !important;
    }

    .mr-md-n15,
    .mx-md-n15 {
        margin-right: -9rem !important;
    }

    .mb-md-n15,
    .my-md-n15 {
        margin-bottom: -9rem !important;
    }

    .ml-md-n15,
    .mx-md-n15 {
        margin-left: -9rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    .p-md-10 {
        padding: 6rem !important;
    }

    .pt-md-10,
    .py-md-10 {
        padding-top: 6rem !important;
    }

    .pr-md-10,
    .px-md-10 {
        padding-right: 6rem !important;
    }

    .pb-md-10,
    .py-md-10 {
        padding-bottom: 6rem !important;
    }

    .pl-md-10,
    .px-md-10 {
        padding-left: 6rem !important;
    }

    .p-md-15 {
        padding: 9rem !important;
    }

    .pt-md-15,
    .py-md-15 {
        padding-top: 9rem !important;
    }

    .pr-md-15,
    .px-md-15 {
        padding-right: 9rem !important;
    }

    .pb-md-15,
    .py-md-15 {
        padding-bottom: 9rem !important;
    }

    .pl-md-15,
    .px-md-15 {
        padding-left: 9rem !important;
    }

    .p-md-n1 {
        padding: -0.25rem !important;
    }

    .pt-md-n1,
    .py-md-n1 {
        padding-top: -0.25rem !important;
    }

    .pr-md-n1,
    .px-md-n1 {
        padding-right: -0.25rem !important;
    }

    .pb-md-n1,
    .py-md-n1 {
        padding-bottom: -0.25rem !important;
    }

    .pl-md-n1,
    .px-md-n1 {
        padding-left: -0.25rem !important;
    }

    .p-md-n2 {
        padding: -0.5rem !important;
    }

    .pt-md-n2,
    .py-md-n2 {
        padding-top: -0.5rem !important;
    }

    .pr-md-n2,
    .px-md-n2 {
        padding-right: -0.5rem !important;
    }

    .pb-md-n2,
    .py-md-n2 {
        padding-bottom: -0.5rem !important;
    }

    .pl-md-n2,
    .px-md-n2 {
        padding-left: -0.5rem !important;
    }

    .p-md-n3 {
        padding: -1rem !important;
    }

    .pt-md-n3,
    .py-md-n3 {
        padding-top: -1rem !important;
    }

    .pr-md-n3,
    .px-md-n3 {
        padding-right: -1rem !important;
    }

    .pb-md-n3,
    .py-md-n3 {
        padding-bottom: -1rem !important;
    }

    .pl-md-n3,
    .px-md-n3 {
        padding-left: -1rem !important;
    }

    .p-md-n4 {
        padding: -1.5rem !important;
    }

    .pt-md-n4,
    .py-md-n4 {
        padding-top: -1.5rem !important;
    }

    .pr-md-n4,
    .px-md-n4 {
        padding-right: -1.5rem !important;
    }

    .pb-md-n4,
    .py-md-n4 {
        padding-bottom: -1.5rem !important;
    }

    .pl-md-n4,
    .px-md-n4 {
        padding-left: -1.5rem !important;
    }

    .p-md-n5 {
        padding: -3rem !important;
    }

    .pt-md-n5,
    .py-md-n5 {
        padding-top: -3rem !important;
    }

    .pr-md-n5,
    .px-md-n5 {
        padding-right: -3rem !important;
    }

    .pb-md-n5,
    .py-md-n5 {
        padding-bottom: -3rem !important;
    }

    .pl-md-n5,
    .px-md-n5 {
        padding-left: -3rem !important;
    }

    .p-md-n10 {
        padding: -6rem !important;
    }

    .pt-md-n10,
    .py-md-n10 {
        padding-top: -6rem !important;
    }

    .pr-md-n10,
    .px-md-n10 {
        padding-right: -6rem !important;
    }

    .pb-md-n10,
    .py-md-n10 {
        padding-bottom: -6rem !important;
    }

    .pl-md-n10,
    .px-md-n10 {
        padding-left: -6rem !important;
    }

    .p-md-n15 {
        padding: -9rem !important;
    }

    .pt-md-n15,
    .py-md-n15 {
        padding-top: -9rem !important;
    }

    .pr-md-n15,
    .px-md-n15 {
        padding-right: -9rem !important;
    }

    .pb-md-n15,
    .py-md-n15 {
        padding-bottom: -9rem !important;
    }

    .pl-md-n15,
    .px-md-n15 {
        padding-left: -9rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-n10 {
        margin: -6rem !important;
    }

    .mt-md-n10,
    .my-md-n10 {
        margin-top: -6rem !important;
    }

    .mr-md-n10,
    .mx-md-n10 {
        margin-right: -6rem !important;
    }

    .mb-md-n10,
    .my-md-n10 {
        margin-bottom: -6rem !important;
    }

    .ml-md-n10,
    .mx-md-n10 {
        margin-left: -6rem !important;
    }

    .m-md-n15 {
        margin: -9rem !important;
    }

    .mt-md-n15,
    .my-md-n15 {
        margin-top: -9rem !important;
    }

    .mr-md-n15,
    .mx-md-n15 {
        margin-right: -9rem !important;
    }

    .mb-md-n15,
    .my-md-n15 {
        margin-bottom: -9rem !important;
    }

    .ml-md-n15,
    .mx-md-n15 {
        margin-left: -9rem !important;
    }

    .m-md-nn1 {
        margin: --0.25rem !important;
    }

    .mt-md-nn1,
    .my-md-nn1 {
        margin-top: --0.25rem !important;
    }

    .mr-md-nn1,
    .mx-md-nn1 {
        margin-right: --0.25rem !important;
    }

    .mb-md-nn1,
    .my-md-nn1 {
        margin-bottom: --0.25rem !important;
    }

    .ml-md-nn1,
    .mx-md-nn1 {
        margin-left: --0.25rem !important;
    }

    .m-md-nn2 {
        margin: --0.5rem !important;
    }

    .mt-md-nn2,
    .my-md-nn2 {
        margin-top: --0.5rem !important;
    }

    .mr-md-nn2,
    .mx-md-nn2 {
        margin-right: --0.5rem !important;
    }

    .mb-md-nn2,
    .my-md-nn2 {
        margin-bottom: --0.5rem !important;
    }

    .ml-md-nn2,
    .mx-md-nn2 {
        margin-left: --0.5rem !important;
    }

    .m-md-nn3 {
        margin: --1rem !important;
    }

    .mt-md-nn3,
    .my-md-nn3 {
        margin-top: --1rem !important;
    }

    .mr-md-nn3,
    .mx-md-nn3 {
        margin-right: --1rem !important;
    }

    .mb-md-nn3,
    .my-md-nn3 {
        margin-bottom: --1rem !important;
    }

    .ml-md-nn3,
    .mx-md-nn3 {
        margin-left: --1rem !important;
    }

    .m-md-nn4 {
        margin: --1.5rem !important;
    }

    .mt-md-nn4,
    .my-md-nn4 {
        margin-top: --1.5rem !important;
    }

    .mr-md-nn4,
    .mx-md-nn4 {
        margin-right: --1.5rem !important;
    }

    .mb-md-nn4,
    .my-md-nn4 {
        margin-bottom: --1.5rem !important;
    }

    .ml-md-nn4,
    .mx-md-nn4 {
        margin-left: --1.5rem !important;
    }

    .m-md-nn5 {
        margin: --3rem !important;
    }

    .mt-md-nn5,
    .my-md-nn5 {
        margin-top: --3rem !important;
    }

    .mr-md-nn5,
    .mx-md-nn5 {
        margin-right: --3rem !important;
    }

    .mb-md-nn5,
    .my-md-nn5 {
        margin-bottom: --3rem !important;
    }

    .ml-md-nn5,
    .mx-md-nn5 {
        margin-left: --3rem !important;
    }

    .m-md-nn10 {
        margin: --6rem !important;
    }

    .mt-md-nn10,
    .my-md-nn10 {
        margin-top: --6rem !important;
    }

    .mr-md-nn10,
    .mx-md-nn10 {
        margin-right: --6rem !important;
    }

    .mb-md-nn10,
    .my-md-nn10 {
        margin-bottom: --6rem !important;
    }

    .ml-md-nn10,
    .mx-md-nn10 {
        margin-left: --6rem !important;
    }

    .m-md-nn15 {
        margin: 9rem !important;
    }

    .mt-md-nn15,
    .my-md-nn15 {
        margin-top: 9rem !important;
    }

    .mr-md-nn15,
    .mx-md-nn15 {
        margin-right: 9rem !important;
    }

    .mb-md-nn15,
    .my-md-nn15 {
        margin-bottom: 9rem !important;
    }

    .ml-md-nn15,
    .mx-md-nn15 {
        margin-left: 9rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .m-lg-10 {
        margin: 6rem !important;
    }

    .mt-lg-10,
    .my-lg-10 {
        margin-top: 6rem !important;
    }

    .mr-lg-10,
    .mx-lg-10 {
        margin-right: 6rem !important;
    }

    .mb-lg-10,
    .my-lg-10 {
        margin-bottom: 6rem !important;
    }

    .ml-lg-10,
    .mx-lg-10 {
        margin-left: 6rem !important;
    }

    .m-lg-15 {
        margin: 9rem !important;
    }

    .mt-lg-15,
    .my-lg-15 {
        margin-top: 9rem !important;
    }

    .mr-lg-15,
    .mx-lg-15 {
        margin-right: 9rem !important;
    }

    .mb-lg-15,
    .my-lg-15 {
        margin-bottom: 9rem !important;
    }

    .ml-lg-15,
    .mx-lg-15 {
        margin-left: 9rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-n10 {
        margin: -6rem !important;
    }

    .mt-lg-n10,
    .my-lg-n10 {
        margin-top: -6rem !important;
    }

    .mr-lg-n10,
    .mx-lg-n10 {
        margin-right: -6rem !important;
    }

    .mb-lg-n10,
    .my-lg-n10 {
        margin-bottom: -6rem !important;
    }

    .ml-lg-n10,
    .mx-lg-n10 {
        margin-left: -6rem !important;
    }

    .m-lg-n15 {
        margin: -9rem !important;
    }

    .mt-lg-n15,
    .my-lg-n15 {
        margin-top: -9rem !important;
    }

    .mr-lg-n15,
    .mx-lg-n15 {
        margin-right: -9rem !important;
    }

    .mb-lg-n15,
    .my-lg-n15 {
        margin-bottom: -9rem !important;
    }

    .ml-lg-n15,
    .mx-lg-n15 {
        margin-left: -9rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    .p-lg-10 {
        padding: 6rem !important;
    }

    .pt-lg-10,
    .py-lg-10 {
        padding-top: 6rem !important;
    }

    .pr-lg-10,
    .px-lg-10 {
        padding-right: 6rem !important;
    }

    .pb-lg-10,
    .py-lg-10 {
        padding-bottom: 6rem !important;
    }

    .pl-lg-10,
    .px-lg-10 {
        padding-left: 6rem !important;
    }

    .p-lg-15 {
        padding: 9rem !important;
    }

    .pt-lg-15,
    .py-lg-15 {
        padding-top: 9rem !important;
    }

    .pr-lg-15,
    .px-lg-15 {
        padding-right: 9rem !important;
    }

    .pb-lg-15,
    .py-lg-15 {
        padding-bottom: 9rem !important;
    }

    .pl-lg-15,
    .px-lg-15 {
        padding-left: 9rem !important;
    }

    .p-lg-n1 {
        padding: -0.25rem !important;
    }

    .pt-lg-n1,
    .py-lg-n1 {
        padding-top: -0.25rem !important;
    }

    .pr-lg-n1,
    .px-lg-n1 {
        padding-right: -0.25rem !important;
    }

    .pb-lg-n1,
    .py-lg-n1 {
        padding-bottom: -0.25rem !important;
    }

    .pl-lg-n1,
    .px-lg-n1 {
        padding-left: -0.25rem !important;
    }

    .p-lg-n2 {
        padding: -0.5rem !important;
    }

    .pt-lg-n2,
    .py-lg-n2 {
        padding-top: -0.5rem !important;
    }

    .pr-lg-n2,
    .px-lg-n2 {
        padding-right: -0.5rem !important;
    }

    .pb-lg-n2,
    .py-lg-n2 {
        padding-bottom: -0.5rem !important;
    }

    .pl-lg-n2,
    .px-lg-n2 {
        padding-left: -0.5rem !important;
    }

    .p-lg-n3 {
        padding: -1rem !important;
    }

    .pt-lg-n3,
    .py-lg-n3 {
        padding-top: -1rem !important;
    }

    .pr-lg-n3,
    .px-lg-n3 {
        padding-right: -1rem !important;
    }

    .pb-lg-n3,
    .py-lg-n3 {
        padding-bottom: -1rem !important;
    }

    .pl-lg-n3,
    .px-lg-n3 {
        padding-left: -1rem !important;
    }

    .p-lg-n4 {
        padding: -1.5rem !important;
    }

    .pt-lg-n4,
    .py-lg-n4 {
        padding-top: -1.5rem !important;
    }

    .pr-lg-n4,
    .px-lg-n4 {
        padding-right: -1.5rem !important;
    }

    .pb-lg-n4,
    .py-lg-n4 {
        padding-bottom: -1.5rem !important;
    }

    .pl-lg-n4,
    .px-lg-n4 {
        padding-left: -1.5rem !important;
    }

    .p-lg-n5 {
        padding: -3rem !important;
    }

    .pt-lg-n5,
    .py-lg-n5 {
        padding-top: -3rem !important;
    }

    .pr-lg-n5,
    .px-lg-n5 {
        padding-right: -3rem !important;
    }

    .pb-lg-n5,
    .py-lg-n5 {
        padding-bottom: -3rem !important;
    }

    .pl-lg-n5,
    .px-lg-n5 {
        padding-left: -3rem !important;
    }

    .p-lg-n10 {
        padding: -6rem !important;
    }

    .pt-lg-n10,
    .py-lg-n10 {
        padding-top: -6rem !important;
    }

    .pr-lg-n10,
    .px-lg-n10 {
        padding-right: -6rem !important;
    }

    .pb-lg-n10,
    .py-lg-n10 {
        padding-bottom: -6rem !important;
    }

    .pl-lg-n10,
    .px-lg-n10 {
        padding-left: -6rem !important;
    }

    .p-lg-n15 {
        padding: -9rem !important;
    }

    .pt-lg-n15,
    .py-lg-n15 {
        padding-top: -9rem !important;
    }

    .pr-lg-n15,
    .px-lg-n15 {
        padding-right: -9rem !important;
    }

    .pb-lg-n15,
    .py-lg-n15 {
        padding-bottom: -9rem !important;
    }

    .pl-lg-n15,
    .px-lg-n15 {
        padding-left: -9rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-n10 {
        margin: -6rem !important;
    }

    .mt-lg-n10,
    .my-lg-n10 {
        margin-top: -6rem !important;
    }

    .mr-lg-n10,
    .mx-lg-n10 {
        margin-right: -6rem !important;
    }

    .mb-lg-n10,
    .my-lg-n10 {
        margin-bottom: -6rem !important;
    }

    .ml-lg-n10,
    .mx-lg-n10 {
        margin-left: -6rem !important;
    }

    .m-lg-n15 {
        margin: -9rem !important;
    }

    .mt-lg-n15,
    .my-lg-n15 {
        margin-top: -9rem !important;
    }

    .mr-lg-n15,
    .mx-lg-n15 {
        margin-right: -9rem !important;
    }

    .mb-lg-n15,
    .my-lg-n15 {
        margin-bottom: -9rem !important;
    }

    .ml-lg-n15,
    .mx-lg-n15 {
        margin-left: -9rem !important;
    }

    .m-lg-nn1 {
        margin: --0.25rem !important;
    }

    .mt-lg-nn1,
    .my-lg-nn1 {
        margin-top: --0.25rem !important;
    }

    .mr-lg-nn1,
    .mx-lg-nn1 {
        margin-right: --0.25rem !important;
    }

    .mb-lg-nn1,
    .my-lg-nn1 {
        margin-bottom: --0.25rem !important;
    }

    .ml-lg-nn1,
    .mx-lg-nn1 {
        margin-left: --0.25rem !important;
    }

    .m-lg-nn2 {
        margin: --0.5rem !important;
    }

    .mt-lg-nn2,
    .my-lg-nn2 {
        margin-top: --0.5rem !important;
    }

    .mr-lg-nn2,
    .mx-lg-nn2 {
        margin-right: --0.5rem !important;
    }

    .mb-lg-nn2,
    .my-lg-nn2 {
        margin-bottom: --0.5rem !important;
    }

    .ml-lg-nn2,
    .mx-lg-nn2 {
        margin-left: --0.5rem !important;
    }

    .m-lg-nn3 {
        margin: --1rem !important;
    }

    .mt-lg-nn3,
    .my-lg-nn3 {
        margin-top: --1rem !important;
    }

    .mr-lg-nn3,
    .mx-lg-nn3 {
        margin-right: --1rem !important;
    }

    .mb-lg-nn3,
    .my-lg-nn3 {
        margin-bottom: --1rem !important;
    }

    .ml-lg-nn3,
    .mx-lg-nn3 {
        margin-left: --1rem !important;
    }

    .m-lg-nn4 {
        margin: --1.5rem !important;
    }

    .mt-lg-nn4,
    .my-lg-nn4 {
        margin-top: --1.5rem !important;
    }

    .mr-lg-nn4,
    .mx-lg-nn4 {
        margin-right: --1.5rem !important;
    }

    .mb-lg-nn4,
    .my-lg-nn4 {
        margin-bottom: --1.5rem !important;
    }

    .ml-lg-nn4,
    .mx-lg-nn4 {
        margin-left: --1.5rem !important;
    }

    .m-lg-nn5 {
        margin: --3rem !important;
    }

    .mt-lg-nn5,
    .my-lg-nn5 {
        margin-top: --3rem !important;
    }

    .mr-lg-nn5,
    .mx-lg-nn5 {
        margin-right: --3rem !important;
    }

    .mb-lg-nn5,
    .my-lg-nn5 {
        margin-bottom: --3rem !important;
    }

    .ml-lg-nn5,
    .mx-lg-nn5 {
        margin-left: --3rem !important;
    }

    .m-lg-nn10 {
        margin: --6rem !important;
    }

    .mt-lg-nn10,
    .my-lg-nn10 {
        margin-top: --6rem !important;
    }

    .mr-lg-nn10,
    .mx-lg-nn10 {
        margin-right: --6rem !important;
    }

    .mb-lg-nn10,
    .my-lg-nn10 {
        margin-bottom: --6rem !important;
    }

    .ml-lg-nn10,
    .mx-lg-nn10 {
        margin-left: --6rem !important;
    }

    .m-lg-nn15 {
        margin: 9rem !important;
    }

    .mt-lg-nn15,
    .my-lg-nn15 {
        margin-top: 9rem !important;
    }

    .mr-lg-nn15,
    .mx-lg-nn15 {
        margin-right: 9rem !important;
    }

    .mb-lg-nn15,
    .my-lg-nn15 {
        margin-bottom: 9rem !important;
    }

    .ml-lg-nn15,
    .mx-lg-nn15 {
        margin-left: 9rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .m-xl-10 {
        margin: 6rem !important;
    }

    .mt-xl-10,
    .my-xl-10 {
        margin-top: 6rem !important;
    }

    .mr-xl-10,
    .mx-xl-10 {
        margin-right: 6rem !important;
    }

    .mb-xl-10,
    .my-xl-10 {
        margin-bottom: 6rem !important;
    }

    .ml-xl-10,
    .mx-xl-10 {
        margin-left: 6rem !important;
    }

    .m-xl-15 {
        margin: 9rem !important;
    }

    .mt-xl-15,
    .my-xl-15 {
        margin-top: 9rem !important;
    }

    .mr-xl-15,
    .mx-xl-15 {
        margin-right: 9rem !important;
    }

    .mb-xl-15,
    .my-xl-15 {
        margin-bottom: 9rem !important;
    }

    .ml-xl-15,
    .mx-xl-15 {
        margin-left: 9rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-n10 {
        margin: -6rem !important;
    }

    .mt-xl-n10,
    .my-xl-n10 {
        margin-top: -6rem !important;
    }

    .mr-xl-n10,
    .mx-xl-n10 {
        margin-right: -6rem !important;
    }

    .mb-xl-n10,
    .my-xl-n10 {
        margin-bottom: -6rem !important;
    }

    .ml-xl-n10,
    .mx-xl-n10 {
        margin-left: -6rem !important;
    }

    .m-xl-n15 {
        margin: -9rem !important;
    }

    .mt-xl-n15,
    .my-xl-n15 {
        margin-top: -9rem !important;
    }

    .mr-xl-n15,
    .mx-xl-n15 {
        margin-right: -9rem !important;
    }

    .mb-xl-n15,
    .my-xl-n15 {
        margin-bottom: -9rem !important;
    }

    .ml-xl-n15,
    .mx-xl-n15 {
        margin-left: -9rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    .p-xl-10 {
        padding: 6rem !important;
    }

    .pt-xl-10,
    .py-xl-10 {
        padding-top: 6rem !important;
    }

    .pr-xl-10,
    .px-xl-10 {
        padding-right: 6rem !important;
    }

    .pb-xl-10,
    .py-xl-10 {
        padding-bottom: 6rem !important;
    }

    .pl-xl-10,
    .px-xl-10 {
        padding-left: 6rem !important;
    }

    .p-xl-15 {
        padding: 9rem !important;
    }

    .pt-xl-15,
    .py-xl-15 {
        padding-top: 9rem !important;
    }

    .pr-xl-15,
    .px-xl-15 {
        padding-right: 9rem !important;
    }

    .pb-xl-15,
    .py-xl-15 {
        padding-bottom: 9rem !important;
    }

    .pl-xl-15,
    .px-xl-15 {
        padding-left: 9rem !important;
    }

    .p-xl-n1 {
        padding: -0.25rem !important;
    }

    .pt-xl-n1,
    .py-xl-n1 {
        padding-top: -0.25rem !important;
    }

    .pr-xl-n1,
    .px-xl-n1 {
        padding-right: -0.25rem !important;
    }

    .pb-xl-n1,
    .py-xl-n1 {
        padding-bottom: -0.25rem !important;
    }

    .pl-xl-n1,
    .px-xl-n1 {
        padding-left: -0.25rem !important;
    }

    .p-xl-n2 {
        padding: -0.5rem !important;
    }

    .pt-xl-n2,
    .py-xl-n2 {
        padding-top: -0.5rem !important;
    }

    .pr-xl-n2,
    .px-xl-n2 {
        padding-right: -0.5rem !important;
    }

    .pb-xl-n2,
    .py-xl-n2 {
        padding-bottom: -0.5rem !important;
    }

    .pl-xl-n2,
    .px-xl-n2 {
        padding-left: -0.5rem !important;
    }

    .p-xl-n3 {
        padding: -1rem !important;
    }

    .pt-xl-n3,
    .py-xl-n3 {
        padding-top: -1rem !important;
    }

    .pr-xl-n3,
    .px-xl-n3 {
        padding-right: -1rem !important;
    }

    .pb-xl-n3,
    .py-xl-n3 {
        padding-bottom: -1rem !important;
    }

    .pl-xl-n3,
    .px-xl-n3 {
        padding-left: -1rem !important;
    }

    .p-xl-n4 {
        padding: -1.5rem !important;
    }

    .pt-xl-n4,
    .py-xl-n4 {
        padding-top: -1.5rem !important;
    }

    .pr-xl-n4,
    .px-xl-n4 {
        padding-right: -1.5rem !important;
    }

    .pb-xl-n4,
    .py-xl-n4 {
        padding-bottom: -1.5rem !important;
    }

    .pl-xl-n4,
    .px-xl-n4 {
        padding-left: -1.5rem !important;
    }

    .p-xl-n5 {
        padding: -3rem !important;
    }

    .pt-xl-n5,
    .py-xl-n5 {
        padding-top: -3rem !important;
    }

    .pr-xl-n5,
    .px-xl-n5 {
        padding-right: -3rem !important;
    }

    .pb-xl-n5,
    .py-xl-n5 {
        padding-bottom: -3rem !important;
    }

    .pl-xl-n5,
    .px-xl-n5 {
        padding-left: -3rem !important;
    }

    .p-xl-n10 {
        padding: -6rem !important;
    }

    .pt-xl-n10,
    .py-xl-n10 {
        padding-top: -6rem !important;
    }

    .pr-xl-n10,
    .px-xl-n10 {
        padding-right: -6rem !important;
    }

    .pb-xl-n10,
    .py-xl-n10 {
        padding-bottom: -6rem !important;
    }

    .pl-xl-n10,
    .px-xl-n10 {
        padding-left: -6rem !important;
    }

    .p-xl-n15 {
        padding: -9rem !important;
    }

    .pt-xl-n15,
    .py-xl-n15 {
        padding-top: -9rem !important;
    }

    .pr-xl-n15,
    .px-xl-n15 {
        padding-right: -9rem !important;
    }

    .pb-xl-n15,
    .py-xl-n15 {
        padding-bottom: -9rem !important;
    }

    .pl-xl-n15,
    .px-xl-n15 {
        padding-left: -9rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-n10 {
        margin: -6rem !important;
    }

    .mt-xl-n10,
    .my-xl-n10 {
        margin-top: -6rem !important;
    }

    .mr-xl-n10,
    .mx-xl-n10 {
        margin-right: -6rem !important;
    }

    .mb-xl-n10,
    .my-xl-n10 {
        margin-bottom: -6rem !important;
    }

    .ml-xl-n10,
    .mx-xl-n10 {
        margin-left: -6rem !important;
    }

    .m-xl-n15 {
        margin: -9rem !important;
    }

    .mt-xl-n15,
    .my-xl-n15 {
        margin-top: -9rem !important;
    }

    .mr-xl-n15,
    .mx-xl-n15 {
        margin-right: -9rem !important;
    }

    .mb-xl-n15,
    .my-xl-n15 {
        margin-bottom: -9rem !important;
    }

    .ml-xl-n15,
    .mx-xl-n15 {
        margin-left: -9rem !important;
    }

    .m-xl-nn1 {
        margin: --0.25rem !important;
    }

    .mt-xl-nn1,
    .my-xl-nn1 {
        margin-top: --0.25rem !important;
    }

    .mr-xl-nn1,
    .mx-xl-nn1 {
        margin-right: --0.25rem !important;
    }

    .mb-xl-nn1,
    .my-xl-nn1 {
        margin-bottom: --0.25rem !important;
    }

    .ml-xl-nn1,
    .mx-xl-nn1 {
        margin-left: --0.25rem !important;
    }

    .m-xl-nn2 {
        margin: --0.5rem !important;
    }

    .mt-xl-nn2,
    .my-xl-nn2 {
        margin-top: --0.5rem !important;
    }

    .mr-xl-nn2,
    .mx-xl-nn2 {
        margin-right: --0.5rem !important;
    }

    .mb-xl-nn2,
    .my-xl-nn2 {
        margin-bottom: --0.5rem !important;
    }

    .ml-xl-nn2,
    .mx-xl-nn2 {
        margin-left: --0.5rem !important;
    }

    .m-xl-nn3 {
        margin: --1rem !important;
    }

    .mt-xl-nn3,
    .my-xl-nn3 {
        margin-top: --1rem !important;
    }

    .mr-xl-nn3,
    .mx-xl-nn3 {
        margin-right: --1rem !important;
    }

    .mb-xl-nn3,
    .my-xl-nn3 {
        margin-bottom: --1rem !important;
    }

    .ml-xl-nn3,
    .mx-xl-nn3 {
        margin-left: --1rem !important;
    }

    .m-xl-nn4 {
        margin: --1.5rem !important;
    }

    .mt-xl-nn4,
    .my-xl-nn4 {
        margin-top: --1.5rem !important;
    }

    .mr-xl-nn4,
    .mx-xl-nn4 {
        margin-right: --1.5rem !important;
    }

    .mb-xl-nn4,
    .my-xl-nn4 {
        margin-bottom: --1.5rem !important;
    }

    .ml-xl-nn4,
    .mx-xl-nn4 {
        margin-left: --1.5rem !important;
    }

    .m-xl-nn5 {
        margin: --3rem !important;
    }

    .mt-xl-nn5,
    .my-xl-nn5 {
        margin-top: --3rem !important;
    }

    .mr-xl-nn5,
    .mx-xl-nn5 {
        margin-right: --3rem !important;
    }

    .mb-xl-nn5,
    .my-xl-nn5 {
        margin-bottom: --3rem !important;
    }

    .ml-xl-nn5,
    .mx-xl-nn5 {
        margin-left: --3rem !important;
    }

    .m-xl-nn10 {
        margin: --6rem !important;
    }

    .mt-xl-nn10,
    .my-xl-nn10 {
        margin-top: --6rem !important;
    }

    .mr-xl-nn10,
    .mx-xl-nn10 {
        margin-right: --6rem !important;
    }

    .mb-xl-nn10,
    .my-xl-nn10 {
        margin-bottom: --6rem !important;
    }

    .ml-xl-nn10,
    .mx-xl-nn10 {
        margin-left: --6rem !important;
    }

    .m-xl-nn15 {
        margin: 9rem !important;
    }

    .mt-xl-nn15,
    .my-xl-nn15 {
        margin-top: 9rem !important;
    }

    .mr-xl-nn15,
    .mx-xl-nn15 {
        margin-right: 9rem !important;
    }

    .mb-xl-nn15,
    .my-xl-nn15 {
        margin-bottom: 9rem !important;
    }

    .ml-xl-nn15,
    .mx-xl-nn15 {
        margin-left: 9rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 400 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 600 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #17449e !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #141e2c !important;
}

.text-secondary {
    color: #5e5e5e !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #141e2c !important;
}

.text-success {
    color: #00ac69 !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #141e2d !important;
}

.text-info {
    color: #00cfd5 !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #151f30 !important;
}

.text-warning {
    color: #f4a100 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #151f2f !important;
}

.text-danger {
    color: #e81500 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #141f2e !important;
}

.text-light,
.page-header-dark .btn-link {
    color: #eff3f9 !important;
}

a.text-light:hover,
.page-header-dark a.btn-link:hover,
a.text-light:focus,
.page-header-dark a.btn-link:focus {
    color: #b7c9e4 !important;
}

.text-dark,
.page-header-light .btn-link {
    color: #1f2d41 !important;
}

a.text-dark:hover,
.page-header-light a.btn-link:hover,
a.text-dark:focus,
.page-header-light a.btn-link:focus {
    color: #06090d !important;
}

.text-black {
    color: #000 !important;
}

a.text-black:hover,
a.text-black:focus {
    color: black !important;
}

.text-white {
    color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
    color: #d9d9d9 !important;
}

.text-red {
    color: #e81500 !important;
}

a.text-red:hover,
a.text-red:focus {
    color: #9c0e00 !important;
}

.text-orange {
    color: #f76400 !important;
}

a.text-orange:hover,
a.text-orange:focus {
    color: #ab4500 !important;
}

.text-yellow {
    color: #f4a100 !important;
}

a.text-yellow:hover,
a.text-yellow:focus {
    color: #a86f00 !important;
}

.text-green {
    color: #00ac69 !important;
}

a.text-green:hover,
a.text-green:focus {
    color: #00603a !important;
}

.text-teal {
    color: #00ba94 !important;
}

a.text-teal:hover,
a.text-teal:focus {
    color: #006e57 !important;
}

.text-cyan {
    color: #00cfd5 !important;
}

a.text-cyan:hover,
a.text-cyan:focus {
    color: #008589 !important;
}

.text-blue {
    color: #0061f2 !important;
}

a.text-blue:hover,
a.text-blue:focus {
    color: #0042a6 !important;
}

.text-indigo {
    color: #5800e8 !important;
}

a.text-indigo:hover,
a.text-indigo:focus {
    color: #3b009c !important;
}

.text-purple {
    color: #6900c7 !important;
}

a.text-purple:hover,
a.text-purple:focus {
    color: #41007b !important;
}

.text-pink {
    color: #e30059 !important;
}

a.text-pink:hover,
a.text-pink:focus {
    color: #97003b !important;
}

.text-red-soft {
    color: #eec7c7 !important;
}

a.text-red-soft:hover,
a.text-red-soft:focus {
    color: #dc8c8c !important;
}

.text-orange-soft {
    color: #f1d6c7 !important;
}

a.text-orange-soft:hover,
a.text-orange-soft:focus {
    color: #e2a98a !important;
}

.text-yellow-soft {
    color: #f0e3c7 !important;
}

a.text-yellow-soft:hover,
a.text-yellow-soft:focus {
    color: #e0c58b !important;
}

.text-green-soft {
    color: #bfe5dc !important;
}

a.text-green-soft:hover,
a.text-green-soft:focus {
    color: #89cfbe !important;
}

.text-teal-soft {
    color: #bfe8e5 !important;
}

a.text-teal-soft:hover,
a.text-teal-soft:focus {
    color: #87d4ce !important;
}

.text-cyan-soft {
    color: #bfecf2 !important;
}

a.text-cyan-soft:hover,
a.text-cyan-soft:focus {
    color: #7fd9e5 !important;
}

.text-blue-soft {
    color: #4d93e9 !important;
}

a.text-blue-soft:hover,
a.text-blue-soft:focus {
    color: #7aaaf0 !important;
}

.text-indigo-soft {
    color: #d1c2f6 !important;
}

a.text-indigo-soft:hover,
a.text-indigo-soft:focus {
    color: #9f7fec !important;
}

.text-purple-soft {
    color: #d4c2ef !important;
}

a.text-purple-soft:hover,
a.text-purple-soft:focus {
    color: #a985df !important;
}

.text-pink-soft {
    color: #edc2d9 !important;
}

a.text-pink-soft:hover,
a.text-pink-soft:focus {
    color: #dc87b4 !important;
}

.text-primary-soft {
    color: #4d93e9 !important;
}

a.text-primary-soft:hover,
a.text-primary-soft:focus {
    color: #7aaaf0 !important;
}

.text-secondary-soft {
    color: #d4c2ef !important;
}

a.text-secondary-soft:hover,
a.text-secondary-soft:focus {
    color: #a985df !important;
}

.text-success-soft {
    color: #bfe5dc !important;
}

a.text-success-soft:hover,
a.text-success-soft:focus {
    color: #89cfbe !important;
}

.text-info-soft {
    color: #bfecf2 !important;
}

a.text-info-soft:hover,
a.text-info-soft:focus {
    color: #7fd9e5 !important;
}

.text-warning-soft {
    color: #f0e3c7 !important;
}

a.text-warning-soft:hover,
a.text-warning-soft:focus {
    color: #e0c58b !important;
}

.text-danger-soft {
    color: #eec7c7 !important;
}

a.text-danger-soft:hover,
a.text-danger-soft:focus {
    color: #dc8c8c !important;
}

.text-body {
    color: #687281 !important;
}

.text-muted {
    color: #a2acba !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50,
.page-header-dark .page-header-text {
    color: rgba(255, 255, 255, 0.7) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.text-reset,
.footer a {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {

    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: " (" attr(title) ")";
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #a2acba;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body {
        min-width: 992px !important;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: #fff !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #d7dce3 !important;
    }

    .table-dark {
        color: inherit;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody+tbody {
        border-color: #e3e6ec;
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #e3e6ec;
    }
}

html,
body {
    height: 100%;
}

body {
    overflow-x: hidden;
}

/* .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 3rem;
    padding-right: 3rem; } */


#layoutDefault {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#layoutDefault #layoutDefault_content {
    min-width: 0;
    flex-grow: 1;
}

#layoutDefault #layoutDefault_footer {
    min-width: 0;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        margin-top: 0.75rem;
    }

    100% {
        opacity: 1;
        margin-top: 0;
    }
}

.animated--fade-in-up {
    animation-name: fadeInUp;
    animation-duration: 200ms;
    animation-timing-function: margin cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}

.animated--fade-in-up.dropdown-menu {
    margin-top: 0 !important;
    top: 0.125rem !important;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animated--fade-in {
    animation-name: fadeIn;
    animation-duration: 200ms;
    animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}

.bg-gradient-primary-to-secondary {
    background-color: #17449e;
    background-image: linear-gradient(135deg, #17449e 0%, #5e5e5e 100%);
}

.bg-gray-100 {
    background-color: #eff3f9 !important;
}

.bg-gray-200 {
    background-color: #e3e6ec !important;
}

.bg-gray-300 {
    background-color: #d7dce3 !important;
}

.bg-gray-400 {
    background-color: #c5ccd6 !important;
}

.bg-gray-500 {
    background-color: #a2acba !important;
}

.bg-gray-600 {
    background-color: #687281 !important;
}

.bg-gray-700 {
    background-color: #485260 !important;
}

.bg-gray-800 {
    background-color: #323f52 !important;
}

.bg-gray-900 {
    background-color: #1f2d41 !important;
}

.bg-img-cover {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-img-repeat {
    background-position: top left;
    background-repeat: repeat;
}

.overlay {
    position: relative;
}

.overlay:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(23, 68, 158);
    background: -moz-linear-gradient(90deg, rgba(23, 68, 158, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(23, 68, 158, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(90deg, rgba(23, 68, 158, 1) 0%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#17449e", endColorstr="#ffffff", GradientType=1);
}

.overlay-primary:before {
    background-color: #17449e !important;
}

.overlay-secondary:before {
    background-color: #5e5e5e !important;
}

.overlay-success:before {
    background-color: #00ac69 !important;
}

.overlay-info:before {
    background-color: #00cfd5 !important;
}

.overlay-warning:before {
    background-color: #f4a100 !important;
}

.overlay-danger:before {
    background-color: #e81500 !important;
}

.overlay-light:before {
    background-color: #eff3f9 !important;
}

.overlay-dark:before {
    background-color: #1f2d41 !important;
}

.overlay-black:before {
    background-color: #000 !important;
}

.overlay-white:before {
    background-color: #fff !important;
}

.overlay-red:before {
    background-color: #e81500 !important;
}

.overlay-orange:before {
    background-color: #f76400 !important;
}

.overlay-yellow:before {
    background-color: #f4a100 !important;
}

.overlay-green:before {
    background-color: #00ac69 !important;
}

.overlay-teal:before {
    background-color: #00ba94 !important;
}

.overlay-cyan:before {
    background-color: #00cfd5 !important;
}

.overlay-blue:before {
    background-color: #0061f2 !important;
}

.overlay-indigo:before {
    background-color: #5800e8 !important;
}

.overlay-purple:before {
    background-color: #6900c7 !important;
}

.overlay-pink:before {
    background-color: #e30059 !important;
}

.overlay-red-soft:before {
    background-color: #eec7c7 !important;
}

.overlay-orange-soft:before {
    background-color: #f1d6c7 !important;
}

.overlay-yellow-soft:before {
    background-color: #f0e3c7 !important;
}

.overlay-green-soft:before {
    background-color: #bfe5dc !important;
}

.overlay-teal-soft:before {
    background-color: #bfe8e5 !important;
}

.overlay-cyan-soft:before {
    background-color: #bfecf2 !important;
}

.overlay-blue-soft:before {
    background-color: #4d93e9 !important;
}

.overlay-indigo-soft:before {
    background-color: #d1c2f6 !important;
}

.overlay-purple-soft:before {
    background-color: #d4c2ef !important;
}

.overlay-pink-soft:before {
    background-color: #edc2d9 !important;
}

.overlay-primary-soft:before {
    background-color: #4d93e9 !important;
}

.overlay-secondary-soft:before {
    background-color: #d4c2ef !important;
}

.overlay-success-soft:before {
    background-color: #bfe5dc !important;
}

.overlay-info-soft:before {
    background-color: #bfecf2 !important;
}

.overlay-warning-soft:before {
    background-color: #f0e3c7 !important;
}

.overlay-danger-soft:before {
    background-color: #eec7c7 !important;
}

.overlay-10:before {
    opacity: 0.1;
}

.overlay-20:before {
    opacity: 0.2;
}

.overlay-30:before {
    opacity: 0.3;
}

.overlay-40:before {
    opacity: 0.4;
}

.overlay-50:before {
    opacity: 0.5;
}

.overlay-60:before {
    opacity: 0.6;
}

.overlay-70:before {
    opacity: 0.7;
}

.overlay-80:before {
    opacity: 0.8;
}

.overlay-90:before {
    opacity: 0.9;
}

.border-lg {
    border-width: 0.25rem !important;
}

.border-top-lg {
    border-top-width: 0.25rem !important;
}

.border-right-lg {
    border-right-width: 0.25rem !important;
}

.border-bottom-lg {
    border-bottom-width: 0.25rem !important;
}

.border-left-lg {
    border-left-width: 0.25rem !important;
}

.border-primary {
    border-color: #17449e !important;
}

.border-secondary {
    border-color: #5e5e5e !important;
}

.border-success {
    border-color: #00ac69 !important;
}

.border-info {
    border-color: #00cfd5 !important;
}

.border-warning {
    border-color: #f4a100 !important;
}

.border-danger {
    border-color: #e81500 !important;
}

.border-light {
    border-color: #eff3f9 !important;
}

.border-dark {
    border-color: #1f2d41 !important;
}

.border-black {
    border-color: #000 !important;
}

.border-white {
    border-color: #fff !important;
}

.border-red {
    border-color: #e81500 !important;
}

.border-orange {
    border-color: #f76400 !important;
}

.border-yellow {
    border-color: #f4a100 !important;
}

.border-green {
    border-color: #00ac69 !important;
}

.border-teal {
    border-color: #00ba94 !important;
}

.border-cyan {
    border-color: #00cfd5 !important;
}

.border-blue {
    border-color: #0061f2 !important;
}

.border-indigo {
    border-color: #5800e8 !important;
}

.border-purple {
    border-color: #6900c7 !important;
}

.border-pink {
    border-color: #e30059 !important;
}

.border-red-soft {
    border-color: #eec7c7 !important;
}

.border-orange-soft {
    border-color: #f1d6c7 !important;
}

.border-yellow-soft {
    border-color: #f0e3c7 !important;
}

.border-green-soft {
    border-color: #bfe5dc !important;
}

.border-teal-soft {
    border-color: #bfe8e5 !important;
}

.border-cyan-soft {
    border-color: #bfecf2 !important;
}

.border-blue-soft {
    border-color: #4d93e9 !important;
}

.border-indigo-soft {
    border-color: #d1c2f6 !important;
}

.border-purple-soft {
    border-color: #d4c2ef !important;
}

.border-pink-soft {
    border-color: #edc2d9 !important;
}

.border-primary-soft {
    border-color: #4d93e9 !important;
}

.border-secondary-soft {
    border-color: #d4c2ef !important;
}

.border-success-soft {
    border-color: #bfe5dc !important;
}

.border-info-soft {
    border-color: #bfecf2 !important;
}

.border-warning-soft {
    border-color: #f0e3c7 !important;
}

.border-danger-soft {
    border-color: #eec7c7 !important;
}

.rounded-xl {
    border-radius: 1rem !important;
}

.border-gray-100 {
    border-color: #eff3f9 !important;
}

.border-gray-200 {
    border-color: #e3e6ec !important;
}

.border-gray-300 {
    border-color: #d7dce3 !important;
}

.border-gray-400 {
    border-color: #c5ccd6 !important;
}

.border-gray-500 {
    border-color: #a2acba !important;
}

.border-gray-600 {
    border-color: #687281 !important;
}

.border-gray-700 {
    border-color: #485260 !important;
}

.border-gray-800 {
    border-color: #323f52 !important;
}

.border-gray-900 {
    border-color: #1f2d41 !important;
}

.no-caret .dropdown-toggle::after {
    display: none;
}

.no-highlight {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.text-gray-100 {
    color: #eff3f9 !important;
}

.text-gray-200 {
    color: #e3e6ec !important;
}

.text-gray-300 {
    color: #d7dce3 !important;
}

.text-gray-400 {
    color: #c5ccd6 !important;
}

.text-gray-500 {
    color: #a2acba !important;
}

.text-gray-600 {
    color: #687281 !important;
}

.text-gray-700 {
    color: #485260 !important;
}

.text-gray-800 {
    color: #323f52 !important;
}

.text-gray-900 {
    color: #1f2d41 !important;
}

.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-200 {
    font-weight: 200 !important;
}

.font-weight-300 {
    font-weight: 400 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

.text-white-75,
.page-header-dark .page-header-text a {
    color: rgba(255, 255, 255, 0.75);
}

.text-xs {
    font-size: 0.75rem !important;
}

.text-uppercase-expanded,
.navbar-marketing .btn,
.navbar .dropdown-menu .dropdown-header,
.btn-marketing {
    font-weight: 600;
    text-transform: uppercase;
}

.text-arrow-icon {
    line-height: 1;
    display: inline-flex;
    align-items: center;
}

.text-arrow-icon svg {
    margin-left: 0.25rem;
}

.text-arrow-icon.small svg {
    height: 0.875rem;
    width: 0.875rem;
}

.shadow-right-sm {
    box-shadow: 0.125rem 0 0.25rem 0 rgba(31, 45, 65, 0.15);
}

.shadow-right {
    box-shadow: 0.15rem 0 1.75rem 0 rgba(31, 45, 65, 0.15);
}

.shadow-right-lg {
    box-shadow: 1rem 0 3rem 0 rgba(31, 45, 65, 0.15);
}

.shadow-left-sm {
    box-shadow: -0.125rem 0 0.25rem 0 rgba(31, 45, 65, 0.15);
}

.shadow-left {
    box-shadow: -0.15rem 0 1.75rem 0 rgba(31, 45, 65, 0.15);
}

.shadow-left-lg {
    box-shadow: -1rem 0 3rem 0 rgba(31, 45, 65, 0.15);
}

.translate-y-n10 {
    transform: translateY(-6rem);
    margin-bottom: -6rem;
}

.translate-y-n5 {
    transform: translateY(-3rem);
    margin-bottom: -3rem;
}

.z-1 {
    z-index: 1 !important;
    position: relative !important;
}

.z-2 {
    z-index: 2 !important;
    position: relative !important;
}

.o-visible {
    overflow: visible !important;
}

.o-hidden {
    overflow: hidden !important;
}

.content-skewed {
    perspective: 1500px;
    transform-style: preserve-3d;
}

.content-skewed-right {
    perspective-origin: right center;
}

.content-skewed-right .content-skewed-item {
    transform: rotateY(30deg) rotateX(15deg);
    backface-visibility: hidden;
}

.content-skewed-left {
    perspective-origin: left center;
}

.content-skewed-left .content-skewed-item {
    transform: rotateY(-30deg) rotateX(15deg);
    backface-visibility: hidden;
}

.lift {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.lift:hover {
    transform: translateY(-0.3333333333rem);
    box-shadow: 0 0.5rem 2rem 0 rgba(31, 45, 65, 0.25);
}

.lift:active {
    transform: none;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
}

.lift-sm {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(31, 45, 65, 0.2);
}

.lift-sm:hover {
    transform: translateY(-0.1666666667rem);
    box-shadow: 0 0.25rem 1rem 0 rgba(31, 45, 65, 0.25);
}

.lift-sm:active {
    transform: none;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(31, 45, 65, 0.2);
}

.card.lift {
    text-decoration: none;
    color: inherit;
}

.alert-icon {
    position: relative;
    display: flex;
    padding: 0;
}

.alert-icon button.close {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
}

.alert-icon .alert-icon-aside {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 1.25rem;
    font-size: 1.5rem;
}

.alert-icon .alert-icon-aside svg.feather,
.alert-icon .alert-icon-aside svg,
.alert-icon .alert-icon-aside i {
    height: 1.5rem;
    width: 1.5rem;
}

.alert-icon .alert-icon-content {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.alert-primary.alert-solid {
    color: #fff;
    background-color: #17449e;
    border-color: #17449e;
}

.alert-primary.alert-solid hr {
    border-top-color: #24364f;
}

.alert-primary.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-primary.alert-solid .close {
    color: #fff;
}

.alert-secondary.alert-solid {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #5e5e5e;
}

.alert-secondary.alert-solid hr {
    border-top-color: #24364f;
}

.alert-secondary.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-secondary.alert-solid .close {
    color: #fff;
}

.alert-success.alert-solid {
    color: #fff;
    background-color: #00ac69;
    border-color: #00ac69;
}

.alert-success.alert-solid hr {
    border-top-color: #243650;
}

.alert-success.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-success.alert-solid .close {
    color: #fff;
}

.alert-info.alert-solid {
    color: #fff;
    background-color: #00cfd5;
    border-color: #00cfd5;
}

.alert-info.alert-solid hr {
    border-top-color: #243653;
}

.alert-info.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-info.alert-solid .close {
    color: #fff;
}

.alert-warning.alert-solid {
    color: #fff;
    background-color: #f4a100;
    border-color: #f4a100;
}

.alert-warning.alert-solid hr {
    border-top-color: #243652;
}

.alert-warning.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-warning.alert-solid .close {
    color: #fff;
}

.alert-danger.alert-solid {
    color: #fff;
    background-color: #e81500;
    border-color: #e81500;
}

.alert-danger.alert-solid hr {
    border-top-color: #243651;
}

.alert-danger.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-danger.alert-solid .close {
    color: #fff;
}

.alert-light.alert-solid {
    color: #1f2d41;
    background-color: #eff3f9;
    border-color: #eff3f9;
}

.alert-light.alert-solid hr {
    border-top-color: #dce5f2;
}

.alert-light.alert-solid .alert-link {
    color: #0f151e;
}

.alert-light.alert-solid .close {
    color: #1f2d41;
}

.alert-dark.alert-solid {
    color: #fff;
    background-color: #1f2d41;
    border-color: #1f2d41;
}

.alert-dark.alert-solid hr {
    border-top-color: #172130;
}

.alert-dark.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-dark.alert-solid .close {
    color: #fff;
}

.alert-black.alert-solid {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

.alert-black.alert-solid hr {
    border-top-color: black;
}

.alert-black.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-black.alert-solid .close {
    color: #fff;
}

.alert-white.alert-solid {
    color: #1f2d41;
    background-color: #fff;
    border-color: #fff;
}

.alert-white.alert-solid hr {
    border-top-color: #f2f2f2;
}

.alert-white.alert-solid .alert-link {
    color: #0f151e;
}

.alert-white.alert-solid .close {
    color: #1f2d41;
}

.alert-red.alert-solid {
    color: #fff;
    background-color: #e81500;
    border-color: #e81500;
}

.alert-red.alert-solid hr {
    border-top-color: #cf1300;
}

.alert-red.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-red.alert-solid .close {
    color: #fff;
}

.alert-orange.alert-solid {
    color: #fff;
    background-color: #f76400;
    border-color: #f76400;
}

.alert-orange.alert-solid hr {
    border-top-color: #de5a00;
}

.alert-orange.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-orange.alert-solid .close {
    color: #fff;
}

.alert-yellow.alert-solid {
    color: #fff;
    background-color: #f4a100;
    border-color: #f4a100;
}

.alert-yellow.alert-solid hr {
    border-top-color: #db9000;
}

.alert-yellow.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-yellow.alert-solid .close {
    color: #fff;
}

.alert-green.alert-solid {
    color: #fff;
    background-color: #00ac69;
    border-color: #00ac69;
}

.alert-green.alert-solid hr {
    border-top-color: #009359;
}

.alert-green.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-green.alert-solid .close {
    color: #fff;
}

.alert-teal.alert-solid {
    color: #fff;
    background-color: #00ba94;
    border-color: #00ba94;
}

.alert-teal.alert-solid hr {
    border-top-color: #00a180;
}

.alert-teal.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-teal.alert-solid .close {
    color: #fff;
}

.alert-cyan.alert-solid {
    color: #fff;
    background-color: #00cfd5;
    border-color: #00cfd5;
}

.alert-cyan.alert-solid hr {
    border-top-color: #00b6bc;
}

.alert-cyan.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-cyan.alert-solid .close {
    color: #fff;
}

.alert-blue.alert-solid {
    color: #fff;
    background-color: #0061f2;
    border-color: #0061f2;
}

.alert-blue.alert-solid hr {
    border-top-color: #0057d9;
}

.alert-blue.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-blue.alert-solid .close {
    color: #fff;
}

.alert-indigo.alert-solid {
    color: #fff;
    background-color: #5800e8;
    border-color: #5800e8;
}

.alert-indigo.alert-solid hr {
    border-top-color: #4e00cf;
}

.alert-indigo.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-indigo.alert-solid .close {
    color: #fff;
}

.alert-purple.alert-solid {
    color: #fff;
    background-color: #6900c7;
    border-color: #6900c7;
}

.alert-purple.alert-solid hr {
    border-top-color: #5c00ae;
}

.alert-purple.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-purple.alert-solid .close {
    color: #fff;
}

.alert-pink.alert-solid {
    color: #fff;
    background-color: #e30059;
    border-color: #e30059;
}

.alert-pink.alert-solid hr {
    border-top-color: #ca004f;
}

.alert-pink.alert-solid .alert-link {
    color: #e6e6e6;
}

.alert-pink.alert-solid .close {
    color: #fff;
}

.alert-red-soft.alert-solid {
    color: #1f2d41;
    background-color: #eec7c7;
    border-color: #eec7c7;
}

.alert-red-soft.alert-solid hr {
    border-top-color: #e8b3b3;
}

.alert-red-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-red-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-orange-soft.alert-solid {
    color: #1f2d41;
    background-color: #f1d6c7;
    border-color: #f1d6c7;
}

.alert-orange-soft.alert-solid hr {
    border-top-color: #ecc7b3;
}

.alert-orange-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-orange-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-yellow-soft.alert-solid {
    color: #1f2d41;
    background-color: #f0e3c7;
    border-color: #f0e3c7;
}

.alert-yellow-soft.alert-solid hr {
    border-top-color: #ebd9b3;
}

.alert-yellow-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-yellow-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-green-soft.alert-solid {
    color: #1f2d41;
    background-color: #bfe5dc;
    border-color: #bfe5dc;
}

.alert-green-soft.alert-solid hr {
    border-top-color: #added2;
}

.alert-green-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-green-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-teal-soft.alert-solid {
    color: #1f2d41;
    background-color: #bfe8e5;
    border-color: #bfe8e5;
}

.alert-teal-soft.alert-solid hr {
    border-top-color: #ace1dd;
}

.alert-teal-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-teal-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-cyan-soft.alert-solid {
    color: #1f2d41;
    background-color: #bfecf2;
    border-color: #bfecf2;
}

.alert-cyan-soft.alert-solid hr {
    border-top-color: #aae6ee;
}

.alert-cyan-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-cyan-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-blue-soft.alert-solid {
    color: #1f2d41;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.alert-blue-soft.alert-solid hr {
    border-top-color: #a8c7f5;
}

.alert-blue-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-blue-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-indigo-soft.alert-solid {
    color: #1f2d41;
    background-color: #d1c2f6;
    border-color: #d1c2f6;
}

.alert-indigo-soft.alert-solid hr {
    border-top-color: #c0acf3;
}

.alert-indigo-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-indigo-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-purple-soft.alert-solid {
    color: #1f2d41;
    background-color: #d4c2ef;
    border-color: #d4c2ef;
}

.alert-purple-soft.alert-solid hr {
    border-top-color: #c6aeea;
}

.alert-purple-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-purple-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-pink-soft.alert-solid {
    color: #1f2d41;
    background-color: #edc2d9;
    border-color: #edc2d9;
}

.alert-pink-soft.alert-solid hr {
    border-top-color: #e7aecd;
}

.alert-pink-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-pink-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-primary-soft.alert-solid {
    color: #1f2d41;
    background-color: #4d93e9;
    border-color: #4d93e9;
}

.alert-primary-soft.alert-solid hr {
    border-top-color: #a8c7f5;
}

.alert-primary-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-primary-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-secondary-soft.alert-solid {
    color: #1f2d41;
    background-color: #d4c2ef;
    border-color: #d4c2ef;
}

.alert-secondary-soft.alert-solid hr {
    border-top-color: #c6aeea;
}

.alert-secondary-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-secondary-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-success-soft.alert-solid {
    color: #1f2d41;
    background-color: #bfe5dc;
    border-color: #bfe5dc;
}

.alert-success-soft.alert-solid hr {
    border-top-color: #added2;
}

.alert-success-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-success-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-info-soft.alert-solid {
    color: #1f2d41;
    background-color: #bfecf2;
    border-color: #bfecf2;
}

.alert-info-soft.alert-solid hr {
    border-top-color: #aae6ee;
}

.alert-info-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-info-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-warning-soft.alert-solid {
    color: #1f2d41;
    background-color: #f0e3c7;
    border-color: #f0e3c7;
}

.alert-warning-soft.alert-solid hr {
    border-top-color: #ebd9b3;
}

.alert-warning-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-warning-soft.alert-solid .close {
    color: #1f2d41;
}

.alert-danger-soft.alert-solid {
    color: #1f2d41;
    background-color: #eec7c7;
    border-color: #eec7c7;
}

.alert-danger-soft.alert-solid hr {
    border-top-color: #e8b3b3;
}

.alert-danger-soft.alert-solid .alert-link {
    color: #0f151e;
}

.alert-danger-soft.alert-solid .close {
    color: #1f2d41;
}

.avatar {
    display: inline-flex;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    position: relative;
    align-items: center;
    justify-content: center;
}

.avatar .avatar-img {
    border-radius: 100%;
    object-fit: cover;
    height: 2rem;
    width: auto;
}

.avatar-xs {
    height: 1.25rem;
    width: 1.25rem;
}

.avatar-xs .avatar-img {
    height: 1rem;
}

.avatar-sm {
    height: 1.75rem;
    width: 1.75rem;
}

.avatar-sm .avatar-img {
    height: 1.5rem;
}

.avatar-lg {
    height: 2.5rem;
    width: 2.5rem;
}

.avatar-lg .avatar-img {
    height: 2.5rem;
}

.avatar-xl {
    height: 3rem;
    width: 3rem;
}

.avatar-xl .avatar-img {
    height: 3rem;
}

.avatar-xxl {
    height: 3.75rem;
    width: 3.75rem;
}

.avatar-xxl .avatar-img {
    height: 3.75rem;
}

.avatar-busy::before,
.avatar-idle::before,
.avatar-offline::before,
.avatar-online::before {
    content: '';
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 20%;
    border-radius: 50%;
    background-color: #d7dce3;
    border: 0.0625rem solid #fff;
}

.avatar-busy::before {
    background-color: #e81500;
}

.avatar-idle::before {
    background-color: #f4a100;
}

.avatar-offline::before {
    background-color: #d7dce3;
}

.avatar-online::before {
    background-color: #00ac69;
}

.btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998;
}

.btn-facebook:hover {
    color: #fff;
    background-color: #30497c;
    border-color: #2d4373;
}

.btn-facebook:focus,
.btn-facebook.focus {
    color: #fff;
    background-color: #30497c;
    border-color: #2d4373;
    box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998;
}

.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show>.btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373;
    border-color: #293e6a;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show>.btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-github {
    color: #fff;
    background-color: #333333;
    border-color: #333333;
}

.btn-github:hover {
    color: #fff;
    background-color: #202020;
    border-color: #1a1a1a;
}

.btn-github:focus,
.btn-github.focus {
    color: #fff;
    background-color: #202020;
    border-color: #1a1a1a;
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.btn-github.disabled,
.btn-github:disabled {
    color: #fff;
    background-color: #333333;
    border-color: #333333;
}

.btn-github:not(:disabled):not(.disabled):active,
.btn-github:not(:disabled):not(.disabled).active,
.show>.btn-github.dropdown-toggle {
    color: #fff;
    background-color: #1a1a1a;
    border-color: #131313;
}

.btn-github:not(:disabled):not(.disabled):active:focus,
.btn-github:not(:disabled):not(.disabled).active:focus,
.show>.btn-github.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.btn-google {
    color: #fff;
    background-color: #ea4335;
    border-color: #ea4335;
}

.btn-google:hover {
    color: #fff;
    background-color: #e12717;
    border-color: #d62516;
}

.btn-google:focus,
.btn-google.focus {
    color: #fff;
    background-color: #e12717;
    border-color: #d62516;
    box-shadow: 0 0 0 0.2rem rgba(237, 95, 83, 0.5);
}

.btn-google.disabled,
.btn-google:disabled {
    color: #fff;
    background-color: #ea4335;
    border-color: #ea4335;
}

.btn-google:not(:disabled):not(.disabled):active,
.btn-google:not(:disabled):not(.disabled).active,
.show>.btn-google.dropdown-toggle {
    color: #fff;
    background-color: #d62516;
    border-color: #ca2315;
}

.btn-google:not(:disabled):not(.disabled):active:focus,
.btn-google:not(:disabled):not(.disabled).active:focus,
.show>.btn-google.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 95, 83, 0.5);
}

.btn-twitter {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.btn-twitter:hover {
    color: #fff;
    background-color: #0d8ddc;
    border-color: #0c85d0;
}

.btn-twitter:focus,
.btn-twitter.focus {
    color: #fff;
    background-color: #0d8ddc;
    border-color: #0c85d0;
    box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}

.btn-twitter.disabled,
.btn-twitter:disabled {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show>.btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0c85d0;
    border-color: #0b7ec4;
}

.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show>.btn-twitter.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}

.btn-transparent-dark {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    color: rgba(31, 45, 65, 0.5) !important;
}

.btn-transparent-dark:hover {
    color: #fff;
    background-color: rgba(31, 45, 65, 0.1);
    border-color: transparent;
}

.btn-transparent-dark:focus,
.btn-transparent-dark.focus {
    color: #fff;
    background-color: rgba(31, 45, 65, 0.1);
    border-color: transparent;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-transparent-dark.disabled,
.btn-transparent-dark:disabled {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
}

.btn-transparent-dark:not(:disabled):not(.disabled):active,
.btn-transparent-dark:not(:disabled):not(.disabled).active,
.show>.btn-transparent-dark.dropdown-toggle {
    color: #fff;
    background-color: rgba(31, 45, 65, 0.125);
    border-color: transparent;
}

.btn-transparent-dark:not(:disabled):not(.disabled):active:focus,
.btn-transparent-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-transparent-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-transparent-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(31, 45, 65, 0.25) !important;
}

.btn-transparent-light {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    color: rgba(255, 255, 255, 0.5) !important;
}

.btn-transparent-light:hover {
    color: #1f2d41;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
}

.btn-transparent-light:focus,
.btn-transparent-light.focus {
    color: #1f2d41;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-transparent-light.disabled,
.btn-transparent-light:disabled {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
}

.btn-transparent-light:not(:disabled):not(.disabled):active,
.btn-transparent-light:not(:disabled):not(.disabled).active,
.show>.btn-transparent-light.dropdown-toggle {
    color: #1f2d41;
    background-color: rgba(255, 255, 255, 0.125);
    border-color: transparent;
}

.btn-transparent-light:not(:disabled):not(.disabled):active:focus,
.btn-transparent-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-transparent-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-transparent-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25) !important;
}

.btn-icon {
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 100%;
    flex-shrink: 0;
    height: calc((1rem * 1.5) + (0.5rem * 2) + (2px)) !important;
    width: calc((1rem * 1.5) + (0.5rem * 2) + (2px)) !important;
}

.btn-icon.btn-xl {
    height: calc((1.25rem * 1.5) + (1.25rem * 2) + (2px)) !important;
    width: calc((1.25rem * 1.5) + (1.25rem * 2) + (2px)) !important;
    border-radius: 100%;
}

.btn-icon.btn-lg,
.btn-group-lg>.btn-icon.btn {
    height: calc((1.25rem * 1.5) + (0.5rem * 2) + (2px)) !important;
    width: calc((1.25rem * 1.5) + (0.5rem * 2) + (2px)) !important;
}

.btn-icon.btn-sm,
.btn-group-sm>.btn-icon.btn {
    height: calc((0.875rem * 1.5) + (0.25rem * 2) + (2px)) !important;
    width: calc((0.875rem * 1.5) + (0.25rem * 2) + (2px)) !important;
}

.btn-icon.btn-xs {
    height: calc((0.7rem * 1.5) + (0.15rem * 2) + (2px)) !important;
    width: calc((0.7rem * 1.5) + (0.15rem * 2) + (2px)) !important;
    border-radius: 100%;
}

.btn-icon.btn-link {
    text-decoration: none;
}

.btn-icon .ng-fa-icon {
    display: inline-flex;
}

.btn i-feather,
.btn .feather {
    height: 1rem;
    width: 1rem;
}

.btn-lg i-feather,
.btn-lg .feather,
.btn-group-lg>.btn i-feather,
.btn-group-lg>.btn .feather {
    height: 1.25rem;
    width: 1.25rem;
}

.btn-sm i-feather,
.btn-sm .feather,
.btn-group-sm>.btn i-feather,
.btn-group-sm>.btn .feather {
    height: 0.875rem;
    width: 0.875rem;
}

.btn-xs i-feather,
.btn-xs .feather {
    height: 0.7rem;
    width: 0.7rem;
}

.btn-xl i-feather,
.btn-xl .feather {
    height: 1.25rem;
    width: 1.25rem;
}

.btn-xs {
    padding: 0.15rem 0.5rem;
    font-size: 0.7rem;
    border-radius: 3px;
}

.btn-xl {
    padding: 1.25rem 1.5rem;
    font-size: 1.25rem;
    border-radius: 0.5rem;
}

.btn-marketing {
    font-size: 0.7rem;
    padding: 0.8rem 1.5rem;
}

.card {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
    overflow: hidden;
    position: relative;
}

.card .card-header {
    display: flex;
    align-items: center;
    font-weight: 600;
    border-bottom: none;
}

.card:not([class*='bg-']) .card-header {
    color: #17449e;
    background-color: #f1f9ff;
    border-bottom: 1px solid rgba(38, 127, 196, 0.18);
}

.card-tabbed .card-header {
    padding-top: 0;
    padding-bottom: 0;
    justify-content: space-between;
}

.card-tabbed .nav-item {
    margin-right: 1rem;
}

.card-tabbed .nav-item:last-child {
    margin-right: 0;
}

.card-tabbed .nav-item .nav-link {
    padding: calc(1rem - 0.0625rem) 0;
    color: #a2acba;
}

.card-tabbed .nav-item .nav-link.active {
    color: #17449e;
    border-bottom: 0.125rem solid #17449e;
}

.card-header-actions .card-header {
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem;
}

.card-header-actions .card-header .dropdown-menu {
    margin-top: 0;
    top: 0.5625rem !important;
}

.card-collapsable .card-header[data-toggle='collapse'] {
    display: flex;
    text-decoration: none;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.card-collapsable .card-header[data-toggle='collapse'] .card-collapsable-arrow {
    height: 0.9rem;
    width: 0.9rem;
    display: inline-flex;
    font-size: 0.9rem;
    transition: transform 0.1s ease-in-out;
}

.card-collapsable .card-header[data-toggle='collapse'] .card-collapsable-arrow svg,
.card-collapsable .card-header[data-toggle='collapse'] .card-collapsable-arrow i,
.card-collapsable .card-header[data-toggle='collapse'] .card-collapsable-arrow .feather {
    height: 0.9rem;
    width: 0.9rem;
}

.card-collapsable .card-header[data-toggle='collapse'].collapsed {
    border-radius: 3px;
}

.card-collapsable .card-header[data-toggle='collapse'].collapsed .card-collapsable-arrow {
    transform: rotate(-90deg);
}

.card-icon .card-icon-aside {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    padding: 2rem;
}

.card-icon .card-icon-aside i,
.card-icon .card-icon-aside svg,
.card-icon .card-icon-aside .feather {
    height: 3rem;
    width: 3rem;
}

.card-scrollable .card-body {
    max-height: 15rem;
    overflow-y: auto;
}

.card-progress .progress {
    height: 0.5rem;
    background-color: rgba(0, 0, 0, 0.03);
}

.card-link {
    text-decoration: none !important;
    color: inherit !important;
}

.card-link:hover {
    color: inherit !important;
}

.card-portfolio {
    position: relative;
}

.card-portfolio .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    height: 3.5rem;
    width: 100%;
    background-color: #fff;
    text-align: center;
}

.card-portfolio .card-body .card-title {
    font-size: 0.75rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.1em;
    color: #323f52;
}

@media (min-width: 992px) {
    .card-portfolio .card-body {
        height: 100%;
        bottom: -100%;
        transition: bottom 0.15s ease-in-out;
    }

    .card-portfolio .card-img-top {
        transition: transform 0.45s ease-in-out;
    }

    .card-portfolio:hover .card-body {
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.9);
    }

    .card-portfolio:hover .card-img-top {
        transform: scale(1.2);
    }
}

.card-columns-portfolio .card {
    box-shadow: none;
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .card-columns-portfolio {
        column-count: 1;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .card-columns-portfolio {
        column-count: 2;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .card-columns-portfolio {
        column-count: 2;
    }
}

@media (min-width: 1200px) {
    .card-columns-portfolio {
        column-count: 3;
    }
}

.card-columns-case-study .card {
    box-shadow: none;
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .card-columns-case-study {
        column-count: 1;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .card-columns-case-study {
        column-count: 2;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .card-columns-case-study {
        column-count: 2;
    }
}

@media (min-width: 1200px) {
    .card-columns-case-study {
        column-count: 2;
    }
}

.card-flag {
    position: absolute;
    font-size: 0.7rem;
    padding: 0.3rem 0.5rem;
    line-height: 1;
}

.card-flag-dark {
    background-color: rgba(31, 45, 65, 0.7);
    color: #fff;
}

.card-flag-light {
    background-color: rgba(255, 255, 255, 0.7);
    color: #687281;
}

.card-flag-lg {
    font-size: 0.9rem;
    padding: 0.5rem 0.65rem;
}

.card-flag-top-right {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    top: 0.5rem;
    right: 0;
}

.card-flag-top-left {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    top: 0.5rem;
    left: 0;
}

.card-flag-bottom-right {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    bottom: 0.5rem;
    right: 0;
}

.card-flag-bottom-left {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    bottom: 0.5rem;
    left: 0;
}

.custom-control label {
    cursor: pointer;
    margin: 0.25rem 0;
}

.custom-control label:hover:before {
    background-color: #e3e6ec;
}

.custom-control .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: #e3e6ec;
    border-color: #d7dce3;
}

.custom-control .custom-control-input:disabled~.custom-control-label {
    color: #a2acba;
    cursor: not-allowed;
}

.custom-control-solid label:before {
    background-color: #ecf0f6;
    border-color: #ecf0f6;
}

.custom-control-solid label:hover:before {
    background-color: #c9d5e6;
    border-color: #c9d5e6;
}

.custom-control-solid .custom-control-input:disabled~.custom-control-label::before {
    background-color: #f6f8fb;
    border-color: #f6f8fb;
}

.custom-control-solid .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: #f6f8fb;
    border-color: #f6f8fb;
}

.form-control-solid {
    background-color: #ecf0f6;
    border-color: #ecf0f6;
}

.form-control-solid:focus {
    background-color: #ecf0f6;
}

.navbar .dropdown-menu {
    /* top: calc(100% + 0.5rem + 0.5rem) !important; */
    top: 100% !important;
    font-size: 0.9rem;
    margin-top: 20px;
    border-radius: 0;
    background: #eee;
}

.navbar .dropdown-menu .dropdown-header {
    font-size: 0.7rem;
    color: #a2acba;
}

.navbar-marketing {
    transition: background-color 0.15s ease-in-out;
    padding: 0;
}

.navbar-marketing .navbar-toggler {
    padding: 0.5rem;
    border: 0;
}

.navbar-marketing .navbar-toggler svg {
    vertical-align: middle;
    height: 1.5rem;
    width: 1.5rem;
}

.navbar-marketing .navbar-brand {
    font-size: 1.2rem;
    font-weight: bold;
}

.navbar-marketing .navbar-brand img {
    height: 1rem;
}

.navbar-marketing .navbar-brand svg {
    height: 1rem;
    fill: currentColor;
}

.navbar-marketing .navbar-nav {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.navbar-marketing .navbar-nav .nav-item .nav-link {
    font-weight: 600;
    font-size: 14px;
    color: #17449e;
    opacity: 0.8;
    position: relative;

}

.navbar-marketing .navbar-nav .nav-item .nav-link.active {
    opacity: 1;
}

.navbar-marketing .btn {
    font-size: 0.7rem;
    padding: 0.75rem 2rem;
}

.navbar-marketing.fixed-top,
.page-header.navbar-fixed .navbar-marketing.navbar {
    max-height: 100vh;
    overflow-y: auto;
}

@media (min-width: 992px) {
    .navbar-marketing {
        padding: 0;
    }

    .navbar-marketing .navbar-nav .nav-item:last-child {
        margin-right: 0;
    }

    .navbar-marketing.fixed-top,
    .page-header.navbar-fixed .navbar-marketing.navbar {
        max-height: none;
        overflow-y: visible;
    }
}

.navbar-marketing.navbar-dark.navbar-scrolled {
    background-color: #1f2d41 !important;
}

.navbar-marketing.navbar-light.navbar-scrolled {
    background-color: #eff3f9 !important;
}

@media (max-width: 991.98px) {
    .navbar-marketing.bg-transparent.navbar-dark {
        background-color: #1f2d41 !important;
    }

    .navbar-marketing.bg-transparent.navbar-light {
        background-color: #eff3f9 !important;
    }

    .animated--fade-in,
    .animated--fade-in-up {
        animation: none;
    }
}

.topnav .navbar-brand {
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.topnav .navbar-brand img {
    height: 1rem;
}

.nav-sticky {
    top: 6.625rem;
    font-size: 0.9rem;
}

.nav-sticky .nav-link {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.nav-sticky .nav-link.active {
    font-weight: 600;
}

.nav-sticky ul.nav>li.nav-item>.nav {
    display: none;
}

.nav-sticky ul.nav>li.nav-item>a.nav-link.active~.nav {
    display: flex;
}

.sidenav {
    font-size: 0.9rem;
}

.sidenav .sidenav-menu .nav .sidenav-menu-heading {
    font-size: 0.7rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.sidenav .sidenav-menu .nav .nav-link {
    display: flex;
    align-items: center;
    line-height: normal;
}

.sidenav .sidenav-menu .nav .nav-link .nav-link-icon {
    padding-right: 0.5rem;
    display: inline-flex;
}

.sidenav .sidenav-menu .nav .nav-link .nav-link-icon .feather,
.sidenav .sidenav-menu .nav .nav-link .nav-link-icon i-feather {
    width: 1rem;
    height: 1rem;
}

.sidenav .sidenav-menu .nav .nav-link.active {
    font-weight: 600;
}

.sidenav .sidenav-menu .nav .nav-link.active .nav-icon {
    color: #17449e;
}

.sidenav .sidenav-menu .nav .sidenav-menu-nested {
    margin-left: 1.4375rem;
    border-left-style: solid;
    border-left-width: thin;
    padding-left: 0.5625rem;
}

.sidenav .sidenav-footer {
    height: 5rem;
    display: flex;
    align-items: center;
}

.sidenav.sidenav-dark .sidenav-menu-nested {
    border-left-color: rgba(255, 255, 255, 0.15);
}

.sidenav.sidenav-light .sidenav-menu-nested {
    border-left-color: #d7dce3;
}

.dropdown-menu {
    border: none;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
}

.dropdown-menu .dropdown-header {
    display: flex;
    align-items: center;
}

.dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
}

.dropdown-menu .dropdown-item .dropdown-item-icon {
    height: 1em;
    width: 1em;
    margin-right: 0.5rem;
    color: #a2acba;
}

.dropdown-menu .dropdown-item.active .dropdown-item-icon,
.dropdown-menu .dropdown-item:active .dropdown-item-icon {
    color: #fff;
}

.dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
}

.dropdown .dropdown-toggle .dropdown-arrow {
    margin-left: auto;
    margin-right: 1rem;
    transition: transform 0.1s ease-in-out;
    font-size: 0.6rem;
}

.dropdown.show .dropdown-toggle .dropdown-arrow {
    transform: rotate(90deg);
}

@media (min-width: 992px) {
    .dropdown .dropdown-toggle {
        display: inline-flex;
    }

    .dropdown .dropdown-toggle .dropdown-arrow {
        margin-left: 0.4rem;
        margin-right: 0;
    }
}

.dropdown-user .dropdown-user-img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
}

.dropdown-user .dropdown-menu {
    min-width: 15rem;
}

.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-img {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1rem;
}

.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details {
    font-weight: 400;
}

.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-name {
    color: #1f2d41;
    font-weight: 600;
    font-size: 0.9rem;
    max-width: 20rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-email {
    color: #687281;
    font-size: 0.75rem;
    max-width: 20rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-notifications {
    position: static;
}

.dropdown-notifications .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
    width: calc(100% - 3rem);
    right: 1.5rem;
    max-height: 19rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.dropdown-notifications .dropdown-menu::-webkit-scrollbar {
    width: 0.5rem;
}

.dropdown-notifications .dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 10rem;
    height: 3em;
    border-width: 0.1rem;
    border-style: solid;
    background-clip: padding-box;
    background-color: #c5ccd6;
    border-color: rgba(0, 0, 0, 0);
}

.dropdown-notifications .dropdown-menu::-webkit-scrollbar-track {
    background-color: rgba(31, 45, 65, 0.05);
}

.dropdown-notifications .dropdown-menu::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.dropdown-notifications .dropdown-menu::-webkit-scrollbar-corner {
    background-color: transparent;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-header {
    background-color: #17449e;
    color: #fff;
    font-size: 0.65rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 800;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-header .feather,
.dropdown-notifications .dropdown-menu .dropdown-notifications-header svg {
    height: 0.85rem;
    width: 0.85rem;
    opacity: 0.7;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e3e6ec;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-item:active {
    color: inherit;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-icon,
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-img {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    margin-right: 1rem;
    flex-shrink: 0;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-icon {
    background-color: #17449e;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-icon .feather,
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-icon svg {
    text-align: center;
    font-size: 0.85rem;
    color: #fff;
    height: 0.85rem;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-details {
    color: #a2acba;
    font-size: 0.7rem;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-text {
    font-size: 0.9rem;
    max-width: calc(100vw - 8.5rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.5rem;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-actions .btn-sm,
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-actions .btn-group-sm>.btn {
    font-size: 0.7rem;
    padding: 0.15rem 3px;
    cursor: pointer;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-footer {
    justify-content: center;
    font-size: 0.8rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #a2acba;
    cursor: pointer;
}

.dropdown-notifications .dropdown-menu .dropdown-notifications-footer .dropdown-notifications-footer-icon {
    height: 1em;
    width: 1em;
    margin-left: 0.25rem;
}

@media (min-width: 576px) {
    .dropdown-notifications {
        position: relative;
    }

    .dropdown-notifications .dropdown-menu {
        width: auto;
        min-width: 18.75rem;
        right: 0;
    }

    .dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-text {
        max-width: 13rem;
    }
}

@media (min-width: 992px) {

    .dropdown-lg,
    .dropdown-xl {
        position: relative;
    }

    .dropdown-lg .dropdown-menu,
    .dropdown-xl .dropdown-menu {
        position: absolute;
        padding: 0;
        overflow: hidden;
    }

    .dropdown-lg .dropdown-menu .dropdown-item,
    .dropdown-lg .dropdown-menu .dropdown-header,
    .dropdown-xl .dropdown-menu .dropdown-item,
    .dropdown-xl .dropdown-menu .dropdown-header {
        padding-left: 0;
        padding-right: 0;
    }

    .dropdown-lg .dropdown-menu .dropdown-item:hover,
    .dropdown-lg .dropdown-menu .dropdown-item:active,
    .dropdown-lg .dropdown-menu .dropdown-item:focus,
    .dropdown-xl .dropdown-menu .dropdown-item:hover,
    .dropdown-xl .dropdown-menu .dropdown-item:active,
    .dropdown-xl .dropdown-menu .dropdown-item:focus {
        background-color: inherit;
        color: #687281 !important;
    }

    .dropdown-lg .dropdown-menu {
        min-width: 30rem;
        overflow: hidden;
    }

    .dropdown-xl .dropdown-menu {
        min-width: 45rem;
    }
}

i-feather {
    display: inline-flex !important;
}

i-feather svg {
    height: 100%;
    width: 100%;
    vertical-align: top;
}

.feather {
    height: 1rem;
    width: 1rem;
    vertical-align: top;
}

.icon-stack {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    font-size: 1rem;
    background-color: #eff3f9;
}

.icon-stack svg {
    height: 1rem;
    width: 1rem;
}

.icon-stack-lg {
    height: 4rem;
    width: 4rem;
    font-size: 1.5rem;
}

.icon-stack-lg svg {
    height: 1.5rem;
    width: 1.5rem;
}

.icon-stack-xl {
    height: 5rem;
    width: 5rem;
    font-size: 1.75rem;
}

.icon-stack-xl svg {
    height: 1.75rem;
    width: 1.75rem;
}

.icon-list-social {
    display: flex;
}

.icon-list-social .icon-list-social-link {
    font-size: 1.25rem;
    margin-right: 0.5rem;
}

.video {
    overflow: hidden;
}

.video .video-link {
    position: relative;
    display: flex;
    width: 100%;
    padding: 10rem 1rem;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    transition: color 0.2s ease-in-out;
}

.video .video-link svg {
    height: 3rem;
    width: 3rem;
}

.video .video-link:hover {
    color: #fff;
}

.media-img {
    height: 5rem;
    width: 5rem;
}

.accordion-faq {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(31, 45, 65, 0.15);
    overflow: hidden;
}

.accordion-faq .card {
    box-shadow: none;
}

.accordion-faq .card .card-header {
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #e3e6ec;
}

.accordion-faq .accordion-faq-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.accordion-faq .accordion-faq-item .card-header {
    text-decoration: none;
}

.accordion-faq .accordion-faq-item .card-header .accordion-faq-item-heading {
    width: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #485260;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.accordion-faq .accordion-faq-item .card-header .accordion-faq-item-heading .accordion-faq-item-heading-arrow {
    color: #c5ccd6;
    transition: transform 0.1s ease-in-out;
}

.accordion-faq .accordion-faq-item .card-header.collapsed .accordion-faq-item-heading .accordion-faq-item-heading-arrow {
    transform: rotate(-90deg);
}

.badge-transparent-light {
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.1);
}

.badge-transparent-dark {
    color: rgba(31, 45, 65, 0.5);
    background-color: rgba(31, 45, 65, 0.8);
}

.badge-marketing {
    padding: 0.5em 1em;
}

.badge-sm {
    font-size: 0.75rem;
}

.badge-md {
    font-size: 1rem;
}

.badge-lg {
    font-size: 1.25rem;
}

.pagination-blog .page-item {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.pagination-blog .page-item:first-child {
    margin-left: 0;
}

.pagination-blog .page-item:last-child {
    margin-right: 0;
}

.pagination-blog .page-item .page-link {
    border-radius: 3px;
    border: none;
    padding: 0.75rem 1rem;
    font-weight: 600;
    font-size: 0.9rem;
}

.list-group-careers {
    margin-bottom: 3rem;
}

.list-group-careers .list-group-item {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section {
    position: relative;
}

.svg-border-angled {
    padding-top: 5rem;
}

.svg-border-angled svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5rem;
    width: 100%;
}

.svg-border-rounded svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
}

.svg-border-waves {
    padding-top: 2rem;
}

.svg-border-waves svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
}

.page-header {
    position: relative;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.page-header .page-header-content .page-header-title {
    font-size: 3.5rem;
    line-height: 1.2;
}

.page-header .page-header-content .page-header-text {
    font-size: 1.15rem;
}

.page-header .page-header-content .page-header-text.small {
    font-size: 0.9rem;
}

.page-header .page-header-content .page-header-signup {
    justify-content: center;
}

.page-header .page-header-content .page-header-signup .form-control {
    font-size: 0.875em;
    padding: 0 1.5rem;
    height: 2.725rem;
    line-height: 1.5;
}

.page-header-dark {
    color: #fff;
    background-color: #1f2d41;
}

.page-header-dark .page-header-title {
    color: #fff;
}

.page-header-light {
    background-color: #eff3f9;
}

.page-header-light .page-header-text {
    color: #687281;
}

.page-header-light .page-header-text a {
    color: #485260;
}

.page-header.navbar-fixed .page-header-content {
    padding-top: 12.5rem;
}

.footer .footer-brand {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.footer .footer-brand svg {
    height: 1rem;
}

.footer.footer-dark {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
}

.footer.footer-dark hr {
    border-color: rgba(0, 0, 0, 0.1);
}

.footer.footer-light {
    color: #a2acba;
}

.device-laptop {
    position: relative;
}

.device-laptop .device-container {
    display: block;
    height: auto;
    width: 100%;
    position: relative;
    z-index: 1;
}

.device-laptop .device-screenshot {
    display: block;
    position: absolute;
    top: 6%;
    left: 11.8%;
    height: auto;
    width: 76.57%;
    z-index: 0;
}

/*!
   * html5-device-mockups (https://github.com/pixelsign/html5-device-mockups)
   * Copyright 2013 - 2018 pixelsign
   * Licensed under MIT (https://github.com/pixelsign/html5-device-mockups/blob/master/LICENSE.txt)
   * Last Build: Thu Dec 20 2018 14:05:50
   */
.device-wrapper {
    max-width: 300px;
    width: 100%;
}

.device {
    position: relative;
    background-size: cover;
}

.device::after {
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.device .screen {
    position: absolute;
    background-size: cover;
    pointer-events: auto;
}

.device .button {
    position: absolute;
    cursor: pointer;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
    padding-bottom: 198.89807163%;
    background-image: url(https://cdn-zartan.s3.us-east-2.amazonaws.com/static/img/portrait.png);
    z-index: initial;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] .screen {
    top: 3.254847645%;
    left: 7.162534435%;
    width: 85.67493113%;
    height: 93.49030471%;
    border: 0px;
    z-index: 0;
}

.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] {
    padding-bottom: 50.27700831%;
    background-image: url(https://cdn-zartan.s3.us-east-2.amazonaws.com/static/img/landscape.png);
    z-index: initial;
}

.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] .screen {
    top: 7.162534435%;
    left: 3.254847645%;
    width: 93.49030471%;
    height: 85.67493113%;
    border: 0px;
    z-index: 0;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
    padding-bottom: 198.898071625%;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] .screen {
    top: 3.1855955679%;
    left: 6.8870523416%;
    width: 86.2258953168%;
    height: 93.6288088643%;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]::after {
    content: '';
    background-image: url(https://cdn-zartan.s3.us-east-2.amazonaws.com/static/img/portrait_black.png);
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
}

.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] {
    padding-bottom: 50.2770083102%;
}

.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] .screen {
    top: 6.3360881543%;
    left: 3.4626038781%;
    width: 93.0747922438%;
    height: 87.3278236915%;
}

.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]::after {
    content: '';
    background-image: url(https://cdn-zartan.s3.us-east-2.amazonaws.com/static/img/landscape_black.png);
}

.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
}

.brands svg {
    width: 100%;
    fill: currentColor;
}

.testimonial .testimonial-brand {
    fill: currentColor;
    margin-bottom: 1.35rem;
}

.testimonial .testimonial-brand svg {
    height: 3rem;
}

.testimonial .testimonial-quote {
    font-size: 1.4rem;
    font-weight: 400;
}

.testimonial .testimonial-name {
    font-weight: bold;
}

.testimonial .testimonial-position {
    font-weight: lighter;
}

@media (min-width: 992px) {
    .divider-right {
        border-right: 0.0625rem solid #e3e6ec;
    }
}

.pricing {
    font-size: 0.9rem;
}

.pricing .pricing-price {
    font-size: 3rem;
    color: #323f52;
    margin-bottom: 1rem;
}

.pricing .pricing-price sup {
    font-size: 1.5rem;
}

.pricing .pricing-price .pricing-price-period {
    font-size: 1.25rem;
}

.pricing .pricing-list {
    margin-bottom: 0;
    margin-left: 1.6em;
}

.pricing .pricing-list .pricing-list-item {
    margin-bottom: 0.5rem;
}

@media (min-width: 992px) {
    .pricing-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .pricing-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.post-preview h5.card-title {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.post-preview .post-preview-meta {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
}

.post-preview .post-preview-meta .post-preview-meta-img {
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 100%;
    margin-right: 0.5rem;
    flex-shrink: 0;
}

.post-preview .post-preview-meta .post-preview-meta-details .post-preview-meta-details-name {
    font-size: 0.9rem;
    line-height: 1;
    margin-bottom: 0.25rem;
}

.post-preview .post-preview-meta .post-preview-meta-details .post-preview-meta-details-date {
    font-size: 0.75rem;
    line-height: 1;
}

.post-preview-featured .post-preview-featured-img {
    height: 20rem;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.post-preview-featured h5.card-title {
    font-size: 1.75rem;
}

.post-preview-featured p.card-text {
    font-size: 1.2rem;
    font-weight: 400;
}

@media (min-width: 992px) {
    .post-preview-featured .post-preview-featured-img {
        height: 100%;
        width: 100%;
    }
}

.post-archive-tag {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #e3e6ec;
    margin-bottom: 3rem;
    padding-bottom: 0.5rem;
}

.post-archive-item {
    color: inherit !important;
    text-decoration: none !important;
}

.post-archive-meta {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
}

.post-archive-meta .post-archive-meta-img {
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 100%;
    margin-right: 0.5rem;
    flex-shrink: 0;
}

.post-archive-meta .post-archive-meta-details .post-archive-meta-details-name {
    font-size: 0.9rem;
    line-height: 1;
    margin-bottom: 0.25rem;
}

.post-archive-meta .post-archive-meta-details .post-archive-meta-details-date {
    font-size: 0.75rem;
    line-height: 1;
}

.single-post .single-post-meta {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
}

.single-post .single-post-meta .single-post-meta-img {
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 100%;
    margin-right: 0.5rem;
    flex-shrink: 0;
}

.single-post .single-post-meta .single-post-meta-details .single-post-meta-details-name {
    font-size: 0.9rem;
    line-height: 1;
    margin-bottom: 0.25rem;
}

.single-post .single-post-meta .single-post-meta-details .single-post-meta-details-date {
    font-size: 0.75rem;
    line-height: 1;
}

.single-post .single-post-meta-links {
    font-size: 1.25rem;
}

.single-post .single-post-meta-links a {
    color: #c5ccd6;
    margin-left: 0.5rem;
}

.single-post .single-post-meta-links a:hover {
    color: #687281;
}

.single-post .single-post-text {
    font-size: 1.1rem;
}

.single-post .single-post-text p {
    margin-bottom: 1.5rem;
}

.single-post .single-post-text h2,
.single-post .single-post-text h3,
.single-post .single-post-text h4,
.single-post .single-post-text h5,
.single-post .single-post-text h6 {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
}

.error-page {
    background-color: #fff;
}

.error {
    color: #323f52;
    font-size: 7rem;
    position: relative;
    line-height: 1;
    width: 12.5rem;
}

@keyframes noise-anim {
    0% {
        clip: rect(71px, 9999px, 95px, 0);
    }

    5% {
        clip: rect(44px, 9999px, 93px, 0);
    }

    10% {
        clip: rect(90px, 9999px, 49px, 0);
    }

    15% {
        clip: rect(7px, 9999px, 65px, 0);
    }

    20% {
        clip: rect(59px, 9999px, 8px, 0);
    }

    25% {
        clip: rect(48px, 9999px, 21px, 0);
    }

    30% {
        clip: rect(2px, 9999px, 6px, 0);
    }

    35% {
        clip: rect(23px, 9999px, 22px, 0);
    }

    40% {
        clip: rect(16px, 9999px, 8px, 0);
    }

    45% {
        clip: rect(21px, 9999px, 63px, 0);
    }

    50% {
        clip: rect(9px, 9999px, 3px, 0);
    }

    55% {
        clip: rect(37px, 9999px, 28px, 0);
    }

    60% {
        clip: rect(90px, 9999px, 2px, 0);
    }

    65% {
        clip: rect(60px, 9999px, 25px, 0);
    }

    70% {
        clip: rect(38px, 9999px, 62px, 0);
    }

    75% {
        clip: rect(77px, 9999px, 28px, 0);
    }

    80% {
        clip: rect(77px, 9999px, 87px, 0);
    }

    85% {
        clip: rect(93px, 9999px, 21px, 0);
    }

    90% {
        clip: rect(64px, 9999px, 69px, 0);
    }

    95% {
        clip: rect(29px, 9999px, 73px, 0);
    }

    100% {
        clip: rect(54px, 9999px, 91px, 0);
    }
}

.error:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 #e81500;
    top: 0;
    color: #323f52;
    background: #fff;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
    0% {
        clip: rect(55px, 9999px, 71px, 0);
    }

    5% {
        clip: rect(89px, 9999px, 1px, 0);
    }

    10% {
        clip: rect(69px, 9999px, 53px, 0);
    }

    15% {
        clip: rect(34px, 9999px, 96px, 0);
    }

    20% {
        clip: rect(23px, 9999px, 92px, 0);
    }

    25% {
        clip: rect(11px, 9999px, 11px, 0);
    }

    30% {
        clip: rect(85px, 9999px, 23px, 0);
    }

    35% {
        clip: rect(67px, 9999px, 37px, 0);
    }

    40% {
        clip: rect(86px, 9999px, 19px, 0);
    }

    45% {
        clip: rect(26px, 9999px, 51px, 0);
    }

    50% {
        clip: rect(88px, 9999px, 98px, 0);
    }

    55% {
        clip: rect(91px, 9999px, 82px, 0);
    }

    60% {
        clip: rect(44px, 9999px, 56px, 0);
    }

    65% {
        clip: rect(30px, 9999px, 39px, 0);
    }

    70% {
        clip: rect(93px, 9999px, 60px, 0);
    }

    75% {
        clip: rect(79px, 9999px, 6px, 0);
    }

    80% {
        clip: rect(63px, 9999px, 78px, 0);
    }

    85% {
        clip: rect(91px, 9999px, 55px, 0);
    }

    90% {
        clip: rect(57px, 9999px, 83px, 0);
    }

    95% {
        clip: rect(25px, 9999px, 66px, 0);
    }

    100% {
        clip: rect(77px, 9999px, 21px, 0);
    }
}

.error:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 #0061f2;
    top: 0;
    color: #323f52;
    background: #fff;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
}

.pointer {
    cursor: pointer;
}


/* CUSTOMIZAION */
.okta-sign-in-header.auth-header {
    display: none;
}

.signin-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e0f1ff;
}


.navbar-nav .nav-item {
    margin-right: 0.75rem;
}

.video-appointment:after {
    content: '';
    max-width: calc(100% - 50px);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 0;
    bottom: 25px;
    z-index: -1;
    border-radius: 6px;
}

#okta-sign-in.auth-container {
    border: 0 !important;
}


#okta-sign-in.auth-container.main-container {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2) !important;
}

#okta-sign-in .auth-divider {
    background-color: #d0ebff !important;
    height: 2px !important;
}

#okta-sign-in.auth-container.main-container .margin-btm-30 {
    margin-bottom: 15px !important;
}

#okta-sign-in .auth-divider .auth-divider-text {
    top: -9px !important;
    font-weight: bold;
    color: #267fc4;
}

.focused-input {
    box-shadow: none !important;
}

.card-registration {
    max-width: 600px;
    margin: auto;
}

.card-registration .card-body {
    padding: 40px;
}

.card-registration h2 {
    font-size: 28px;
    margin-bottom: 30px;
}

@media (max-width: 575.98px) {
    .top-navbar .input-group.w-25 {
        width: 100% !important;
        margin-bottom: 10px !important;
    }
}

.card-img-top {
    max-height: 180px;
}

footer .list-group-item {
    background-color: transparent;
}

#okta-sign-in.auth-container.main-container {
    margin: 40px auto;
}

.sticky {
    position: fixed;
    top: 0;
    z-index: 9;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
    left: 0;
    right: 0;
    margin: 0;
}

.sticky:hover {
    background: #fff;
}

.app-logo img {
    max-height: 60px;
}


@media (min-width:992px) {
    .navbar-toggler+.btn {
        order: 1;
    }

}

@media (max-width:991px) {
    .navbar #sticky_nav {
        padding: 10px 0;
    }

    .navbar #sticky_nav.mb-2 {
        margin-bottom: 0 !important;
    }

    .navbar .py-2.border-bottom {
        padding-top: 0 !important;
    }

    .list-inline.small.text-right {
        text-align: left !important;
    }

    .input-group.input-group-sm {
        max-width: 100% !important;
        width: 100% !important;
    }

    .app-logo img {
        max-height: 40px;
    }

    h2.display-2 {
        font-size: 2.5rem;
    }
}


.navbar .dropdown .dropdown-toggle {
    justify-content: center;
}

.username {
    line-height: 1;
    display: inline-block;
}

.header-info {
    padding: 0 30px;
    position: relative;
}

.header-info i {
    position: absolute;
    left: 0;
    top: 4px;
    font-size: 20px;
    opacity: 0.5;
    color: #17449e;
}

.header-info p {
    color: #9f9f9f;
    font-size: 14px;
}

.navbar-collapse.show,
.navbar-collapse.collapsing {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    background: rgba(0, 0, 0, 0.85);
}

.navbar-collapse.show .fa-times {
    color: #fff;
    font-size: 20px;
}

@media (max-width: 991px) {
    .navbar-collapse .nav-link {
        color: #fff !important;
    }
}

.modal-search .modal-dialog {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    min-width: 100%;
    margin: 0;
}

.modal-search .modal-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-width: 100% !important;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    flex-direction: row;
    border-radius: 0;
}

.modal-search .modal-body {
    margin: auto;
    height: initial;
    min-height: initial;
    max-width: 70%;
}

.modal-search .close {
    text-shadow: none;
    font-size: 24px;
}

.nav-top {
    font-size: 14px;
}

.modal-search .btn-group .btn {
    border-radius: 0;
}

.modal-search .btn-group .btn:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
}

.services-hours {
    background-color: #17449e;
    background: linear-gradient(to right, rgba(23, 68, 158, 1), rgba(46, 103, 189, 1));
    -moz-background: linear-gradient(to right, rgba(23, 68, 158, 1), rgba(46, 103, 189, 1));
    -o-background: linear-gradient(to right, rgba(23, 68, 158, 1), rgba(46, 103, 189, 1));
    -webkit-background: linear-gradient(to right, rgba(23, 68, 158, 1), rgba(46, 103, 189, 1));
}



.appointment-container:before {
    position: absolute;
    background-color: #01102f;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    opacity: 0.7;
}

.appointment-block {
    position: relative;
    z-index: 1;
}