body {
  background: #bdc3c7;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f0f8ff, #bdc3c7);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #fefeff, #bdc3c7);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.form {
  background-color: white;
  border-radius: 5px;
  width: 550px;
  margin: 20px auto;
  padding: 20px;
  /* height: 600px; */
}

.form-body {
  text-align: left;
  padding: 20px 10px;
}

.form-body > * {
  padding: 5px;
}

.form__label {
  width: 40%;
}

.form_input {
  width: 60%;
}

.footer {
  text-align: left;
}
.error-message {
  color: hsl(0, 85%, 50%);
}

/* .doc-upload-parent {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f7f9;
} */

.document-upload-form {
  /* max-width: 500px; */
  padding-top: 48px;
  /* margin: 0 auto; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.07); */
}

/* #face-buttons{
    display: flex;
    gap: 4px;
} */

#face-buttons label,
#selfie-button label {
  font-size: 16px;
  text-align: left;
  font-weight: 500;
}

#desc {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.identity-button {
  /* width: 100%; */
  margin-top: 20px;
  padding: 16px 4px;
  border: none;
  color: #fff;
  font-size: 16px;
  border-radius: 8px;
}
#camera-btn {
  display: flex;
  gap: 16px;
}
#back-button-1 {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#face-button-1 {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-upload-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 16px;
}
#selfie-button {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#selfie-button-1 {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-form {
  display: flex;
  width: 450px;
  height: 200px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 12px;
}
.success-form-parent {
  height: 50vh;
}

.register-success-msg {
  font-size: 16px;
  font-weight: 400;
  color: rgb(26, 26, 26);
  margin: 0;
}

.document-type {
  gap: 12px;
}

.form-main-body {
  background: #fff;
  padding-bottom: 16px;
}

.registration-form {
  background-color: #fff;
}

.multi-step-parent-wrapper {
  margin: 16px auto;
  padding: 16px;
  width: 700px;
  border-radius: 8px !important;
  border: 1px solid #242424 !important;
}

.registration-form-title,
.verify-identity-title,
.select-document-title,
.upload-document-title,
.face-recognition-title,
.completed-title {
  color: #004570;
  font-weight: 600;
}

.upload-face-image-btn {
  width: 100%;
}

.selfie-thumbnail {
  border: 2px solid #aaaaaa;
}

.completed-wrapper {
  padding: 24px 0;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.custom-disabled-btns {
  cursor: not-allowed !important;
}

.document-btns-icons {
  width: 36px;
  text-align: center;
}

.upload-document-buttons {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.identity-button {
  width: 100%;
  padding: 12px 0 !important;
}

.continue-phone {
  width: 100%;
  cursor: not-allowed !important;
  padding: 12px 0 !important;
}

.back-button:hover:after {
  position: absolute;
  content: "Back";
  left: 76px;
  font-weight: 500;
  font-size: 16px;
  transition: 0.3s ease-in-out all;
}
.consent-class {
  white-space: nowrap;
  margin-bottom: 0;
  margin-left: 8px;
}

.custom-consent-label {
  cursor: pointer;
}

.custom-consent-check {
  width: 18px;
  height: 18px;
}
